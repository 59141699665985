import React, { useEffect, useState } from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import { FaChevronLeft } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import Input from '../FormInputs/Input'
import CustomModal from '../CustomModal/CustomModal'
import { onLogin } from '../../../Actions/homeActions'
import { useDispatch } from 'react-redux'
import { MdEmail } from 'react-icons/md'
import { BsFillKeyFill } from 'react-icons/bs'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

const SignInNew = () => {
  const [showModal, setShowModal] = useState(false)
  const [userData, setUserData] = useState({})
  const [currentURLLocation, setCurrentURLLocation] = useState('')
  const [lang] = useState(localStorage.getItem("langauge_id"))
  
  const [showPassword, setShowPassword] = useState(false)

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value })
  }

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const onContinueClickHandler = (e) => {
    e.preventDefault()
    if (userData.email !== '' && userData.password !== '') {
      var formData = new FormData
      formData.append('email', userData.email)
      formData.append('password', userData.password)
      formData.append('device_name', 'android')
      formData.append('device_token', 'frwNMKysQEm')
      formData.append('device_id', 352631555654243)

      dispatch(onLogin(formData))
    } else {
      alert("Fields Required")
    }
  }

  useEffect(() => {
    setCurrentURLLocation(window.location.href.split('/')?.[4])
  }, [])

  // const onContinueClickHandler = (e) => {
  //   e.preventDefault()
  //   if(userData.email === "sample@sample.com" && userData.password === "sample"){
  //     navigate("/checkout")
  //   } else {
  //     alert("Wrong Password")
  //   }
  // }
  return (
    <div>
      <div className="innerpages">
        <div className="container">
          <div className='account-container'>
            <nav>
              {currentURLLocation !== "sign-in" && <Link to='/account'>
                <FaChevronLeft style={{ fontSize: '23px' }} />
              </Link>}
              <h4>Sign in</h4>
            </nav>
            <p style={{ fontSize: '1rem' }}>Welcome back: Sign in your account</p>
            <div className='Form_input'>
              <div className="inputField">
                <MdEmail />
                <input type="email" name="email" onChange={onChangeHandler} placeholder="Email" />
              </div>
              <div className="inputField">
                <BsFillKeyFill />
                <input name="password" type={showPassword?"text":"password"} onChange={onChangeHandler} placeholder="Password" />
                {showPassword?<AiOutlineEye onClick={()=>setShowPassword(!showPassword)} />:<AiOutlineEyeInvisible onClick={()=>setShowPassword(!showPassword)}/>}
              </div>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'right' }}>
                <p className="forget-link" onClick={() => setShowModal(true)}>Forgot password?</p>
              </div>
              <button className='btn-main' onClick={onContinueClickHandler}>{lang != 1 ? "يكمل" : "Continue"}</button>
            </div>
          </div>
          {showModal &&
            <CustomModal heading="Find your Account" status={showModal} onClose={setShowModal}>
              <span>Please enter your email address or mobile number to search for your account.</span>
              <label htmlFor="email">Email address</label>
              <Input showIcon={true} placeholder="Email address" type="email" />
            </CustomModal>}
        </div>
      </div>
    </div>
  )
}

export default SignInNew