import React, { useEffect, useState } from 'react'
import PackageCard from '../PackageCard/PackageCard'
import { BsFillCalendar2WeekFill, BsFillInfoCircleFill } from 'react-icons/bs'
import { AiFillCheckCircle, AiFillPlusCircle, AiOutlineClose } from 'react-icons/ai'
import OrderDetail from '../OrderDetail/OrderDetail'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Accordian from '../Accordian/Accordian'
import { useDispatch, useSelector } from 'react-redux'
import { getPackageList, getDeliveryMethod, getDeliveryTime, getPackageDetails } from '../../../Actions/homeActions'
import InfoModal from './InfoModal'
import { toast } from 'react-toastify'

const PackagesNew = () => {
    const [menuItemsSize, setMenuItemsSize] = useState([
        // {mealID:1,size:0},
        { mealID: 2, size: 0 },
        { mealID: 3, size: 0 },
        { mealID: 11, size: 0 },
    ])
    const [selectedIND, setSelectedIND] = useState({})
    const [selectedINDDays, setSelectedINDDays] = useState({})
    const [selectedINDDelivery, setSelectedINDDelivery] = useState({})
    const [selectedDeliveryTimeFrame, setSelectedDeliveryTimeFrame] = useState({})
    const [showOrderDetail, setShowOrderDetail] = useState(false)
    const [orderDetailData, setOrderDetailData] = useState({})
    const [packageID, setpackageID] = useState(JSON.parse(localStorage.getItem('orderDetail'))?.packageID||24)
    const [subscriptionDate, setSubscriptionDate] = useState('')

    const PackageList = useSelector(state => state.home.PackageList);
    const PackageDetails = useSelector(state => state.home.PackageDetails);
    const DeliveryMethod = useSelector(state => state.home.DeliveryMethod);
    const DeliveryTime = useSelector(state => state.home.DeliveryTime);

    console.log('PackageDetails',PackageDetails);

    useEffect(() => {
        if (packageID !== null) {
            var formData = new FormData
            formData.append('lang_id', lang)
            formData.append('package_id', packageID)
            if (packageID !== undefined) {
                dispatch(getPackageDetails(formData))
            }
        }
    }, [packageID])

    console.log('packageID', packageID);


    useEffect(() => {
        if (subscriptionDate !== '' || selectedDeliveryTimeFrame?.name !== null || selectedIND.name !== undefined || selectedINDDays.name !== undefined || selectedINDDelivery?.name !== undefined) {

            const startDate = new Date(subscriptionDate.toString());

            startDate.setDate(startDate.getDate() + Number(selectedINDDays?.name?.split(' ')?.[0]));

            const endDateString = startDate.toString();

            const result = {
                startDate: subscriptionDate,
                endDate: endDateString
            };

            // const calculateCaloriesData = JSON.parse(localStorage.getItem('caloriesResult'))

            // if(calculateCaloriesData & Object.keys(calculateCaloriesData).length > 0){

            setOrderDetailData({
                plan: selectedIND.name,
                packageID: selectedIND?.uniq||PackageDetails?.package_id,
                subscriptionDays: selectedINDDays.name,
                deliveryCommunication: selectedINDDelivery.name,
                deliveryMethodID: selectedINDDelivery?.uniq,
                deliveryTimeFrame: selectedDeliveryTimeFrame.name,
                deliveryID: selectedDeliveryTimeFrame?.uniq,
                menuItemsSize,
                subscriptionDate: result
            })

            localStorage.setItem("orderDetail", JSON.stringify({
                plan: selectedIND.name,
                packageID: selectedIND?.uniq||PackageDetails?.package_id,
                subscriptionDays: selectedINDDays.name,
                deliveryCommunication: selectedINDDelivery.name,
                deliveryMethodID: selectedINDDelivery?.uniq,
                deliveryTimeFrame: selectedDeliveryTimeFrame.name,
                deliveryID: selectedDeliveryTimeFrame?.uniq,
                // menuItemsSize,
                subscriptionDate: result
            }))
            setpackageID(selectedIND.uniq)
        }
    }, [selectedIND, subscriptionDate, selectedINDDays, selectedINDDelivery, selectedDeliveryTimeFrame, menuItemsSize])

    const [lang] = useState(localStorage.getItem("langauge_id"))

    const naviagte = useNavigate()
    const dispatch = useDispatch()

    

    // const parent = React.createElement("div", { id: "parent"}, [
    //     React.createElement("div", {id: "child"}, [
    //         React.createElement("h1", {}, "I am h")
    //     ])
    // ])

    // console.log("parent", parent);

    useEffect(() => {   
        if (PackageList !== null) {
            var formData = new FormData
            formData.append('lang_id', lang)
            formData.append('show_festival_package', 0)

            dispatch(getPackageList(formData))

            // var formData = new FormData
            // formData.append('lang_id',lang)
            // formData.append('package_id',24)

            // dispatch(getPackageList(formData))

            var formData2 = new FormData
            formData2.append('language_id', lang)
            dispatch(getDeliveryMethod(formData2));
            dispatch(getDeliveryTime(formData2));
        }
        localStorage.removeItem('orderDetail')

        const prePackage = JSON.parse(localStorage.getItem('pre-selected-package'))
        const caloriesResult = JSON.parse(localStorage.getItem('caloriesResult'))

        console.log('caloriesResult', caloriesResult);
        console.log('prePackage', prePackage);

        if (caloriesResult == null && caloriesResult == undefined) {
            if (prePackage && Object.keys(prePackage)?.length > 0) {
                setSelectedIND({ name: prePackage?.package?.name, uniq: prePackage?.package?.uniq })
                setSelectedINDDays({ name: prePackage?.days?.name, uniq: prePackage?.days?.id })
                setMenuItemsSize(prePackage?.menuItemSize)
                setSubscriptionDate(prePackage?.subscriptionDate)
                setSelectedINDDelivery({ name: prePackage?.selectedDelivery?.name, uniq: prePackage?.selectedDelivery?.uniq })
                setSelectedDeliveryTimeFrame({ name: prePackage?.timeFrame?.name, uniq: prePackage?.timeFrame?.uniq })
            } else {
                setSelectedIND({ name: "sumbatik ", uniq: 24 })
                setSelectedINDDays({ name: "26 Days", uniq: undefined })
                setMenuItemsSize([
                    { mealID: 2, size: 2 },
                    { mealID: 3, size: 3 },
                    { mealID: 11, size: 0 },
                ])
                setSelectedINDDelivery({ name: "leave the box at the door", uniq: 1 })
                setSelectedDeliveryTimeFrame({ name: "8 AM to 6 PM", uniq: 1 })
                setShowOrderDetail(true)
            }
        }
    }, [])



    const isUserLoggedIn = localStorage.getItem("isLoggedIn")

    const [finalePrice, setFinalePrice] = useState(0)
    const [actualPrice, setActualPrice] = useState(0)
    const [combinationFound, setCombinationFound] = useState([])

    useEffect(() => {
        if (selectedINDDays?.name !== '' && selectedINDDays?.name !== undefined && menuItemsSize?.length > 0) {
            var meal = parseInt(menuItemsSize?.filter(d => d.mealID == 2)?.[0]?.size)
            var snack = parseInt(menuItemsSize?.filter(d => d.mealID == 3)?.[0]?.size)
            var soup = parseInt(menuItemsSize?.filter(d => d.mealID == 11)?.[0]?.size)

            const filteredData = PackageDetails?.sub_package_list?.filter(data => data?.days == selectedINDDays?.name?.split(' ')?.[0])

            console.log('filteredData', filteredData);
            console.log('selectedINDDays', selectedINDDays);
            console.log('PackageDetails', PackageDetails);
            console.log('menuItemsSize', menuItemsSize);

            const combo = filteredData?.[0]?.sub_packages?.filter(pack => pack?.total_combo_count?.meals == meal && pack?.total_combo_count?.snacks == snack && pack?.total_combo_count?.soup == soup)
            const price = combo?.length > 0 ? combo?.[0]?.price : '0.000'
            localStorage.setItem("FinalePaymentPrice", price)
            localStorage.setItem("subPackageID", combo?.[0]?.sub_package_id)
            if (combo?.length > 0) {
                setShowOrderDetail(true)
            }
            setCombinationFound(combo);
            setFinalePrice(price)
            setActualPrice(combo?.length > 0 ? combo?.[0]?.actual_package_price : '0.000')
        }
    }, [selectedINDDays, menuItemsSize, PackageDetails])

    const calculatedCaloriesData = JSON.parse(localStorage.getItem('caloriesResult'))

    useEffect(() => {
        if (calculatedCaloriesData && Object.keys(calculatedCaloriesData)?.length > 0) {
            if (PackageList?.length > 0) {
                setSelectedIND({ name: calculatedCaloriesData?.recomendedPackage })
            }
            // setMenuItemsSize([{mealID:2, size:calculatedCaloriesData?.selectedPackage?.total_combo_count?.meals}, {mealID:3, size:calculatedCaloriesData?.selectedPackage?.total_combo_count?.snacks}, {mealID:11, size:calculatedCaloriesData?.selectedPackage?.total_combo_count?.soup} ])
        }
    }, [PackageList, PackageDetails])

    const onContinueClickHandler = () => {
        // if(combinationFound?.length > 0 && Object.keys(selectedINDDelivery).length>0 &&  Object.keys(selectedDeliveryTimeFrame).length>0 ){
        // }

        if (Object.keys(selectedIND)?.length < 1) {
            toast.warning("Please Select a Package", {
                position: toast.POSITION.TOP_RIGHT
            })
        } else if (Object.keys(selectedINDDays).length < 1) {
            toast.warning("Please Select Subscription Period", {
                position: toast.POSITION.TOP_RIGHT
            })
        } else if (menuItemsSize?.filter(dt => dt?.size !== 0)?.length < 1) {
            toast.warning("Please Select Menu Items", {
                position: toast.POSITION.TOP_RIGHT
            })
        } else if (subscriptionDate === '') {
            toast.warning("Please Select Subscription Start Date", {
                position: toast.POSITION.TOP_RIGHT
            })
        } else if (Object.keys(selectedINDDelivery).length < 1) {
            toast.warning("Please Select Delivery Method", {
                position: toast.POSITION.TOP_RIGHT
            })
        } else if (Object.keys(selectedDeliveryTimeFrame).length < 1) {
            toast.warning("Please Select Delivery Timeframe", {
                position: toast.POSITION.TOP_RIGHT
            })
        } else if (combinationFound?.length < 1) {
            toast.warning("No Combination Found", {
                position: toast.POSITION.TOP_RIGHT
            })
        } else {
            const savePreSelectedData = {
                package: selectedIND,
                days: selectedINDDays,
                menuItemSize: menuItemsSize,
                subscriptionDate: subscriptionDate,
                selectedDelivery: selectedINDDelivery,
                timeFrame: selectedDeliveryTimeFrame
            }
            localStorage.setItem('pre-selected-package', JSON.stringify(savePreSelectedData))
            if (isUserLoggedIn !== null && isUserLoggedIn !== undefined && Boolean(isUserLoggedIn) == true) {
                naviagte('/checkout ')
            } else {
                naviagte('/account')
            }
        }
    }

    const getMenuItems = (item) => {
        if (item?.meal_type_id === 2) {
            return lang == '1' ? 'Meals' : 'الوجبات'
        }
        else if (item?.meal_type_id === 3) {
            return lang == '1' ? 'Snacks' : 'السناكات'
        }
        else if (item?.meal_type_id === 11) {
            return lang == '1' ? 'Soups' : 'الشوربة'
        }
    }

    const onItemsClickHandler = (mealID, type) => {
        if (type === 'decrease') {
            setMenuItemsSize((prevSize) =>
                prevSize.map((item) =>
                    item.mealID === mealID && item.size > 0
                        ? { ...item, size: item.size + -1 }
                        : item
                )
            );
        } else {
            setMenuItemsSize((prevSize) =>
                prevSize.map((item) =>
                    item.mealID === mealID && item.size < PackageDetails?.max_values_as_per_meal_type?.filter(dt => dt?.meal_type_id === mealID)?.[0]?.max_value
                        ? { ...item, size: item.size + 1 }
                        : item
                )
            );
        }
    }

    console.log('DeliveryMethod',DeliveryMethod);

    const packageInfo = {
        sumbatik: [
            { heading: "Who's for", detail: "weight loss" },
            { heading: "Weight of protein source per main meal", detail: "50-100 g" },
            { heading: "Actual fat value per main meal", detail: "0-10 g" },
            { heading: "Actual protein value", detail: "0-37 g" },
            { heading: "Main meal calorie adjustment by customer", detail: "10 Options" },
            { heading: "Main meal calorie adjustment without customer", detail: "6 Options" },
            { heading: "Number of menu items", detail: "300" },
            { heading: "Breakfast meals number per day", detail: "11 meals " },
            { heading: "Main meals number per day", detail: "17 meals" },
            { heading: "Snacks number per day", detail: "22 Snacks" },
            { heading: "Soups number per day", detail: "5 Soups" },
            { heading: "Freedom of choosing meals number", detail: <AiFillCheckCircle id='check-icon' /> },
            { heading: "Free Calorie Calculation Service", detail: <AiFillCheckCircle id='check-icon' /> },
            { heading: "Subscription Freeze", detail: <AiFillCheckCircle id='check-icon' /> },
            { heading: "Daily Meat and seafood options available", detail: <AiFillCheckCircle id='check-icon' /> },
            { heading: "10% cashpack", detail: <AiFillCheckCircle id='check-icon' /> },
            { heading: "Calculate daily calorie intake", detail: <AiFillCheckCircle id='check-icon' /> },
            { heading: "3 meals price", detail: "170 Kd" },
            { heading: "The weight of Carb sources per main meal", detail: "0-50-100-150-200g" },
            { heading: "Actual Carb value in main meals	", detail: "0-70 g" },
        ],
        fit: [
            { heading: "Who's for", detail: "Muscle Building" },
            { heading: "Weight of protein source per main meal", detail: "50-100-150g" },
            { heading: "Actual fat value per main meal", detail: "0-15 g" },
            { heading: "Actual protein value", detail: "0-56 g" },
            { heading: "Main meal calorie adjustment by customer", detail: "15 Options" },
            { heading: "Main meal calorie adjustment without customer", detail: "7 Options" },
            { heading: "Number of menu items", detail: "300" },
            { heading: "Breakfast meals number per day", detail: "11 meals " },
            { heading: "Main meals number per day", detail: "17 meals" },
            { heading: "Snacks number per day", detail: "22 Snacks" },
            { heading: "Soups number per day", detail: "5 Soups" },
            { heading: "Freedom of choosing meals number", detail: <AiFillCheckCircle id='check-icon' /> },
            { heading: "Free Calorie Calculation Service", detail: <AiFillCheckCircle id='check-icon' /> },
            { heading: "Subscription Freeze", detail: <AiFillCheckCircle id='check-icon' /> },
            { heading: "Daily Meat and seafood options available", detail: <AiFillCheckCircle id='check-icon' /> },
            { heading: "10% cashpack", detail: <AiFillCheckCircle id='check-icon' /> },
            { heading: "Calculate daily calorie intake", detail: <AiFillCheckCircle id='check-icon' /> },
            { heading: "3 meals price", detail: "185 Kd" },
            { heading: "The weight of Carb sources per main meal", detail: "0-50-100-150-200g" },
            { heading: "Actual Carb value in main meals	", detail: "0-70 g" },
        ],
        bulk: [
            { heading: "Who's for", detail: "Muscle Mass" },
            { heading: "Weight of protein source per main meal", detail: "50-100-150-200g" },
            { heading: "Actual fat value per main meal", detail: "0-20 g" },
            { heading: "Actual protein value", detail: "0-74 g" },
            { heading: "Main meal calorie adjustment by customer", detail: "20 Options" },
            { heading: "Main meal calorie adjustment without customer", detail: "8 Options" },
            { heading: "Number of menu items", detail: "300" },
            { heading: "Breakfast meals number per day", detail: "11 meals " },
            { heading: "Main meals number per day", detail: "17 meals" },
            { heading: "Snacks number per day", detail: "22 Snacks" },
            { heading: "Soups number per day", detail: "5 Soups" },
            { heading: "Freedom of choosing meals number", detail: <AiFillCheckCircle id='check-icon' /> },
            { heading: "Free Calorie Calculation Service", detail: <AiFillCheckCircle id='check-icon' /> },
            { heading: "Subscription Freeze", detail: <AiFillCheckCircle id='check-icon' /> },
            { heading: "Daily Meat and seafood options available", detail: <AiFillCheckCircle id='check-icon' /> },
            { heading: "10% cashpack", detail: <AiFillCheckCircle id='check-icon' /> },
            { heading: "Calculate daily calorie intake", detail: <AiFillCheckCircle id='check-icon' /> },
            { heading: "3 meals price", detail: "185 Kd" },
            { heading: "The weight of Carb sources per main meal", detail: "0-50-100-150-200g" },
            { heading: "Actual Carb value in main meals	", detail: "0-70 g" },
        ]
    }

    const [formInfo, setFormInfo] = useState('')
    const [menuItemInfo, setMenuItemInfo] = useState('')

    const navigateToCalories = () => {
        naviagte('/calculate-calorie/know-gender')
    }

    console.log('combinationFound', combinationFound);


    return (
        <div className='innerpages new-packages'>
            <div className="container" style={{ display: 'flex', flexDirection: "column", gap: "1rem" }}>
                <section className="section-cont">
                    <h1 className="heading-mn" id={lang != 1 ? 'arabic' : ''}>{lang == 1 ? "Subscription Packages" : "باقات الاشتراك"}</h1>
                    <h2 className='sec-heading' id={lang != 1 ? 'arabic' : ''}>{lang == 1 ? "Which subscription plan aligns best with your goal?" : "ما هو الاشتراك الذي يناسب هدفك ؟"}</h2>
                    <div className={`flex-row ${lang != 1 ? 'arabic' : ''}`}>

                        {
                            PackageList !== undefined && PackageList !== false && PackageList?.map((pack, ind) =>
                                <PackageCard setFormInfo={setFormInfo} description={pack?.description} uniq={pack?.package_master_id} selected={selectedIND.name} setIndex={setSelectedIND} key={ind} infoRed={true} heading={pack?.package_name} subHeading={pack?.gram_label}>
                                    <div className="content-container">
                                        <span></span>
                                    </div>
                                </PackageCard>
                            )
                        }

                        <InfoModal heading="Sumbatik Package" show={formInfo?.split(' ')?.[0] === 'sumbatik'} close={setFormInfo}>
                            {
                                packageInfo?.sumbatik?.map((dt, ind) =>
                                    <div className="info-modal-row" key={ind}>
                                        <h6>{dt?.heading}</h6>
                                        <p>{dt?.detail}</p>
                                    </div>)
                            }
                            <button className="btn-main">Select a Package</button>
                        </InfoModal>

                        <InfoModal heading="Fit Package" show={formInfo?.split(' ')?.[0] === 'Fit'} close={setFormInfo}>
                            {
                                packageInfo?.fit?.map((dt, ind) =>
                                    <div className="info-modal-row" key={ind}>
                                        <h6>{dt?.heading}</h6>
                                        <p>{dt?.detail}</p>
                                    </div>)
                            }
                            <button className="btn-main">Select a Package</button>
                        </InfoModal>

                        <InfoModal heading="Bulk Package" show={formInfo?.split(' ')?.[0] === 'bulk'} close={setFormInfo}>
                            {
                                packageInfo?.bulk?.map((dt, ind) =>
                                    <div className="info-modal-row" key={ind}>
                                        <h6>{dt?.heading}</h6>
                                        <p>{dt?.detail}</p>
                                    </div>)
                            }
                            <button className="btn-main">Select a Package</button>
                        </InfoModal>

                        {/* SAMPLE PACJAGE CARD NEEED TO BE REMOVED AFTER ALL API INTERGATION */}

                        {/* <PackageCard infoRed={true} heading="Sumbatik" subHeading="100gm Protein">
                        <div className="content-container">
                            <span>3 meals, 2 snacks for 170 kD</span>
                            <span>1200-1500 Calories</span>
                        </div>
                    </PackageCard> */}
                    </div>
                    <div className={`bar-new ${lang != 1 ? 'arabic' : ''}`}>
                        {/* <Accordian /> */}
                        <span >{lang == 1 ? "In case you do not know what is the appropriate subscription for you, click here and enter your correct information, and we will nominate the appropriate one for your goal." : "في حال كنت لا تعرف ما هو الاشتراك المناسب اضغط هنا وادخل معلوماتك الصحيحة وسنقوم بترشيح المناسب لهدفك."}</span>
                        <AiFillPlusCircle id='bar-icon' onClick={navigateToCalories} />
                    </div>
                    {/* 
                <InfoModal show={goalInfo} close={setGoalInfo}>
                    <p><b>Which subscription plan would be the most effective in helping you accomplish your goal?</b></p>
                    <div>
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/gYi51iEyf8A" title="Kuwait City, Kuwait 🇰🇼 - by drone [4K] | مدينة الكويت من فوق" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <section>
                        <p>Get a personalized nutrition plan based on your body condition, weight, height, age, and fitness goal. Our nutritionists will calculate your optimal calorie intake and set realistic goals.</p>
                        <p>Calorie counting is free, and we offer different plans for weight loss, muscle building, or healthy weight maintenance.</p>
                        <p>Click here to fill out your information, and our system will provide you with personalized meal plan.</p>
                    </section>
                    <div className="info-cont">
                        <p>Are you still in need of further information or have any inquiries? Please feel free to contact us?</p>
                        <Link to='/'>Book free Consultation</Link>
                    </div>
                </InfoModal> */}

                </section>
                {PackageDetails && Object.keys(PackageDetails).length > 0 && <section className="section-cont">
                    <h2 className="sec-heading" id={lang != 1 ? 'arabic' : ''}>{lang == 1 ? "Please specify the number of days you would like to subscribe for?" : "يرجى تحديد عدد أيام الاشتراك ؟"}</h2>
                    <div className={`flex-row ${lang != 1 ? 'arabic' : ''}`}>
                        {
                            PackageDetails?.sub_package_list?.map((pack, ind) => <PackageCard uniq={pack?.meal_type_id} selected={selectedINDDays.name} setIndex={setSelectedINDDays} key={ind} showInfo={true} heading={pack?.name} subHeading={lang == 1 ? "Friday is off" : "الجمعة يوم عطلة                        "} />)
                        }


                        {/* SAMPLE PACJAGE CARD NEEED TO BE REMOVED AFTER ALL API INTERGATION */}

                        {/* <PackageCard showInfo={true} heading="1 Day" subHeading="Friday is off" />
                    <PackageCard showInfo={true} heading="6 Days" subHeading="Friday is off" />
                    <PackageCard showInfo={true} heading="26 Days" subHeading="Friday is off" /> */}
                    </div>
                </section>}
                <section className="section-cont">
                    <h2 className="sec-heading" id={lang != 1 ? 'arabic' : ''}>{lang == 1 ? "Select number of menu items" : "اختر عدد أصناف المنيو ؟"}</h2>
                    <div className={`flex-row ${lang != 1 ? 'arabic' : ''}`}>
                        {
                            PackageDetails && PackageDetails.max_values_as_per_meal_type?.filter(data => data.meal_type_id !== 1)?.map((dt, ind) =>
                                // <div className="info-parent-wrap"  key={ind} >
                                <PackageCard infoRed={true} setFormInfo={setMenuItemInfo} menuItemsSize={menuItemsSize} onIncreaseClick={onItemsClickHandler} menuState={menuItemsSize?.filter(d => d.mealID === dt?.meal_type_id)} heading={getMenuItems(dt)} showControls={true} />


                                // </div>
                            )
                        }
                        {
                            menuItemInfo === "Meals" &&
                            <div className="float-info-modal" >
                                <div className="container">
                                    <nav>
                                        <h5>Meals</h5>
                                        <AiOutlineClose id='close-icon' onClick={() => setMenuItemInfo("")} />
                                    </nav>
                                    <p>There are 35 meal options available per day from which you can choose according to your preference. Please note that breakfast meals are optional, and you have the freedom to select the number and type of meals based on your desire. Whether you want to choose only main meals, main meals with breakfast, or breakfast meals only, the choice is entirely up to you.</p>
                                </div>
                            </div>
                        }
                        {
                            menuItemInfo === "Snacks" &&
                            <div className="float-info-modal st-2">
                                <div className="container">
                                    <nav>
                                        <h5>Snacks</h5>
                                        <AiOutlineClose id='close-icon' onClick={() => setMenuItemInfo("")} />
                                    </nav>
                                    <p>There are 20 snacks available per day, including salads, sweets, nuts, and fruits.</p>
                                </div>
                            </div>
                        }
                        {
                            menuItemInfo === "Soups" &&
                            <div className="float-info-modal st-3" >
                                <div className="container">
                                    <nav>
                                        <h5>Soups</h5>
                                        <AiOutlineClose id='close-icon' onClick={() => setMenuItemInfo("")} />
                                    </nav>
                                    <p>There are 2 soups available per day</p>
                                </div>
                            </div>
                        }
                        {/* SAMPLE PACJAGE CARD NEEED TO BE REMOVED AFTER ALL API INTERGATION */}

                        {/* <PackageCard menuState={menuItemState} heading="Meals" showControls={true}/>
                    <PackageCard menuState={menuItemState} heading="Snacks" showControls={true}/>
                    <PackageCard menuState={menuItemState} heading="Soups" showControls={true}/> */}
                    </div>
                </section>
                <section className="section-cont">
                    <h2 className="sec-heading" id={lang != 1 ? 'arabic' : ''}>{lang == 1 ? "Choose the starting date for your subscription" : "اختر تاريخ بداية اشتراكك ؟"}</h2>
                    <Accordian type="package" heading={lang == 1 ? "Choose Subscription Date" : "اختر تاريخ بداية اشتراكك ؟"} value={subscriptionDate} onChange={setSubscriptionDate} />

                </section>
                <section className="section-cont">
                    <h2 className="sec-heading" id={lang != 1 ? 'arabic' : ''}>{lang == 1 ? "Please select your preferred method of communication for delivery" : "اختر طريقة التوصيل المفضلة"}</h2>
                    <div className={`flex-row ${lang != 1 ? 'arabic' : ''}`}>
                        {
                            DeliveryMethod && DeliveryMethod?.map((delivery, ind) => <PackageCard uniq={delivery?.methodid} selected={selectedINDDelivery.name} setIndex={setSelectedINDDelivery} key={ind} simpleHeading={true} heading={delivery?.methodname} />)
                        }
                    </div>
                </section>
                <section className="section-cont">
                    <h2 className="sec-heading" id={lang != 1 ? 'arabic' : ''}>{lang == 1 ? "Choose a delivery timeframe" : "اختر أوقات التوصيل"}</h2>
                    <div className={`flex-row ${lang != 1 ? 'arabic' : ''}`}>
                        {
                            DeliveryTime && DeliveryTime?.map((deliveryTime, ind) => <PackageCard uniq={deliveryTime?.timeid} selected={selectedDeliveryTimeFrame.name} setIndex={setSelectedDeliveryTimeFrame} key={ind} simpleHeading={true} heading={lang == 1 ? deliveryTime?.timename : "8 صباحاً - 6 مساءً                        "} />)
                        }


                        {/* SAMPLE PACKAGE CARD NEEED TO BE REMOVED AFTER ALL API INTERGATION */}

                        {/* <PackageCard simpleHeading={true} heading="08:00 AM to 06:00 AM"/> */}
                    </div>
                </section>
                {showOrderDetail && <section className="section-cont">
                    <OrderDetail orderDetail={orderDetailData} finalePrice={finalePrice} actualPrice={actualPrice} combination={combinationFound} />
                </section>}
                {showOrderDetail && <div className="alert-bar-green">
                    <BsFillInfoCircleFill id='bar-icon' style={{ fontSize: '25px', color:'#fff', background:'green',borderRadius:'50%', boxShadow:'1px 1px 5px #363636b4' }} />
                    <span>You’re almost there! Just a few more steps and you can begin the healthy lifestyle you’re always</span>
                </div>}
                {combinationFound?.length > 0 ? null : <h5 className='combo-found-txt'>{lang != 1 ? "لم يتم العثور على مجموعة" : "No Combination Found"}</h5>}
                <button className='btn-main' onClick={onContinueClickHandler}>{lang != 1 ? "يكمل" : "Continue"}</button>
            </div>
        </div>
    )
}

export default PackagesNew