import React, { useState, useEffect } from 'react'
import { BsFillCalendar2WeekFill } from 'react-icons/bs'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment/moment';

const Accordian = ({ heading, value, onChange, type }) => {
  const [toogle, setToogle] = useState(false)

  const today = new Date();
  const disabledDates = [
    today, // Disable the current day
    new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1), // Disable tomorrow
    new Date(today.getFullYear(), today.getMonth(), today.getDate() + 2), // Disable the day after tomorrow
    new Date(today.getFullYear(), today.getMonth(), today.getDate() + 3), // Disable the day after tomorrow
  ];

  useEffect(() => {
    if (value !== '') {
      setToogle(true)
    }
  }, [value])


  console.log('value', value);


  return (
    <div className='accordian'>
      <nav onClick={() => setToogle(!toogle)}>
        <span>{heading}</span>

        <div>
          <BsFillCalendar2WeekFill id='bar-icon' />
          
          {toogle?<FaChevronUp />:<FaChevronDown />}
        </div>
      </nav>

      <div className={`content ${toogle ? 'show' : ''}`}>
        <Calendar onChange={onChange} value={value} minDate={type === "package" && new Date(today.getFullYear(), today.getMonth(), today.getDate() + 3)} // Set the minimum selectable date
          maxDate={type === "package" && new Date(today.getFullYear(), today.getMonth(), today.getDate() + 365)} // Set the maximum selectable date (e.g., 1 year from today)
          tileDisabled={type === "package" && (({ date }) =>
            disabledDates.some(disabledDate =>
              date.getFullYear() === disabledDate.getFullYear() &&
              date.getMonth() === disabledDate.getMonth() &&
              date.getDate() === disabledDate.getDate()
            ))
          }
        />
        {value !== '' && <h4>{moment(value).format('LL')}</h4>}
      </div>
    </div>
  )
}

export default Accordian