import React, { useEffect, useState } from 'react'
import { BsCheck2Circle } from 'react-icons/bs'
import { BiErrorCircle } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { getPackageDetails } from '../../../Actions/homeActions'
import { useNavigate } from 'react-router-dom'

const CaloriesResult = () => {
    
    const [protein, setProtein] = useState(null)
    const [recomendedPackage, setPackage] = useState(null)
    const [packId, setPackId] = useState(null)
    const [fats, setFats] = useState(null)
    const [carbs, setCarbs] = useState(null)
    const [allPacks, setAllPacks] = useState(null)
    const [selectedPackage, setSelectedPackage] = useState(null)
    const [pricing, setPricing] = useState(null)
    const [proceedWithPackage, setProceedWithPackage] = useState(true)

    const userData = JSON.parse(localStorage.getItem('calculate-calories'))
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const PackageDetails = useSelector(state => state.home.PackageDetails);
    const [lang] = useState(localStorage.getItem("langauge_id"))

    useEffect(() => {
        if(recomendedPackage==='sumbatik'){
            getPackage(24)
        }else if(recomendedPackage==='fit'){
            getPackage(26)
        }else if(recomendedPackage==='bulk'){
            getPackage(28)
        }
    }, [recomendedPackage])

    const getPackage = (packageID) => {
        var formData = new FormData
        formData.append('lang_id',1)
        formData.append('package_id',packageID)

        dispatch(getPackageDetails(formData))
    }

    const getProtien = () =>{
        if(userData?.userGoal==='looseWeight'){
            setProtein(userData?.userWeight*1.25)
        }else if(userData?.userGoal==='maintainWeight'){
            setProtein(userData?.userWeight*1)
        }else if(userData?.userGoal==='gainWeight'){
            setProtein(userData?.userWeight*1)
        }else if(userData?.userGoal==='gainMuscle'){
            setProtein(userData?.userWeight*2)
        }else if(userData?.userGoal==='gainMuscleMax'){
            setProtein(userData?.userWeight*1.75)
        }
    }

    const getFats = () => {
        if(userData?.userGoal==='looseWeight'){
            setFats(userData?.weight*0.8)
        }else if(userData?.userGoal==='maintainWeight'){
            setFats(userData?.weight*0.8)
        }else if(userData?.userGoal==='gainWeight'){
            setFats(userData?.weight*0.8)
        }else if(userData?.userGoal==='gainMuscle'){
            setFats(userData?.weight*0.8)
        }else if(userData?.userGoal==='gainMuscleMax'){
            setFats(userData?.weight*0.8)
        }
    }

    const getCarbs = () => {
        var carb = userData?.totalCalories - protein*4 - fats*9
        setCarbs(carb/4)
    }

    useEffect(() => {
        if(PackageDetails){
            var listOne = PackageDetails.sub_package_list[0].sub_packages.map((subPack,index)=>{
                return {
                   price:subPack.price,
                   sub_sub_package_id:subPack.sub_package_id,
                   sub_package_id:PackageDetails.sub_package_list[0].package_for_id,
                   total_combo_count:subPack.total_combo_count,
                }
            })
   
            var listTwo = PackageDetails.sub_package_list[1].sub_packages.map((subPack,index)=>{
               return {
                  price:subPack.price,
                  sub_sub_package_id:subPack.sub_package_id,
                  sub_package_id:PackageDetails.sub_package_list[1].package_for_id,
                  total_combo_count:subPack.total_combo_count,
               }
           })
   
           var listThree = PackageDetails.sub_package_list[2].sub_packages.map((subPack,index)=>{
               return {
                  price:subPack.price,
                  sub_sub_package_id:subPack.sub_package_id,
                  sub_package_id:PackageDetails.sub_package_list[2].package_for_id,
                  total_combo_count:subPack.total_combo_count,
               }
           })

           var listFour = PackageDetails.sub_package_list[3].sub_packages.map((subPack,index)=>{
            return {
               price:subPack.price,
               sub_sub_package_id:subPack.sub_package_id,
               sub_package_id:PackageDetails.sub_package_list[3].package_for_id,
               total_combo_count:subPack.total_combo_count,
            }
        })
   
            var packList = [...listOne,...listTwo,...listThree,...listFour]
           console.log('packList',packList);
           setAllPacks(packList)
       }
       }, [PackageDetails])

      
   
       useEffect(() => {
         if(allPacks !== undefined && allPacks?.length>0){
           var filteredPack =  allPacks?.filter(pack=>parseInt(pack.price)===pricing)
           setSelectedPackage(filteredPack[0])
        }
       }, [pricing, allPacks])

       console.log('PackageDetails',PackageDetails);
       console.log('pricing',pricing);
       console.log('selectedPackage',selectedPackage);
       console.log('packId',packId);
       
    const getRecommendedPackage = () => {
        // Lose Weight
        if(protein>=40 && protein<=110 && userData?.userGoal==='looseWeight'){
            setPricing(160)
            setPackage('sumbatik')
             setPackId(24)
        }else if(protein>=110 && protein<=140 && userData?.userGoal==='looseWeight'){
            setPricing(190)
            setPackage('sumbatik')
             setPackId(24)
            
        }else if(protein>=140 && protein<=210 && userData?.userGoal==='looseWeight'){
            setPricing(205)
            setPackage('fit')
             setPackId(26)
        }else if(protein>=210 && protein<=260 && userData?.userGoal==='looseWeight'){
            setPricing(235)
            setPackage('fit')
             setPackId(26)
        }else if(protein>=260 && protein<=320 && userData?.userGoal==='looseWeight'){
            setPricing(265)
            setPackage('fit')
             setPackId(26)
        }

        // Lose Weight
        if(protein>=40 && protein<=110 && userData?.userGoal==='maintainWeight'){
            setPricing(160)
            setPackage('sumbatik')
             setPackId(24)
        }else if(protein>=110 && protein<=140 && userData?.userGoal==='maintainWeight'){
            setPricing(190)
            setPackage('sumbatik')
             setPackId(24)
        }else if(protein>=140 && protein<=210 && userData?.userGoal==='maintainWeight'){
            setPricing(205)
            setPackage('fit')
             setPackId(26)
        }else if(protein>=210 && protein<=260 && userData?.userGoal==='maintainWeight'){
            setPricing(235)
            setPackage('fit')
             setPackId(26)
        }else if(protein>=260 && protein<=320 && userData?.userGoal==='maintainWeight'){
            setPricing(255)
            setPackage('fit')
             setPackId(26)
        }

         // Muscle Gain
         if(protein>=40 && protein<=110 && userData?.userGoal==='gainMuscle'){
            setPricing(160)
            setPackage('sumbatik')
             setPackId(24)
        }else if(protein>=110 && protein<=140 && userData?.userGoal==='gainMuscle'){
            setPricing(190)
            setPackage('sumbatik')
             setPackId(24)
        }else if(protein>=140 && protein<=210 && userData?.userGoal==='gainMuscle'){
            setPricing(205)
            setPackage('fit')
             setPackId(26)
        }else if(protein>=210 && protein<=260 && userData?.userGoal==='gainMuscle'){
            setPricing(235)
            setPackage('fit')
             setPackId(26)
        }else if(protein>=260 && protein<=320 && userData?.userGoal==='gainMuscle'){
            setPricing(255)
            setPackage('bulk')
            setPackId(28)
        }

        // Weight Gain
        if(protein>=40 && protein<=110 && userData?.userGoal==='gainWeight'){
            setPricing(160)
            setPackage('sumbatik')
             setPackId(24)
        }else if(protein>=110 && protein<=140 && userData?.userGoal==='gainWeight'){
            setPricing(190)
            setPackage('sumbatik')
             setPackId(24)
        }else if(protein>=140 && protein<=210 && userData?.userGoal==='gainWeight'){
            setPricing(205)
            setPackage('fit')
             setPackId(26)
        }else if(protein>=210 && protein<=260 && userData?.userGoal==='gainWeight'){
            setPricing(235)
            setPackage('fit')
             setPackId(26)
        }else if(protein>=260 && protein<=320 && userData?.userGoal==='gainWeight'){
            setPricing(255)
            setPackage('bulk')
            setPackId(28)
        }

         // Muscle Gain Max
         if(protein>=40 && protein<=110 && userData?.userGoal==='gainMuscleMax'){
            setPricing(160)
            setPackage('sumbatik')
             setPackId(24)
        }else if(protein>=110 && protein<=140 && userData?.userGoal==='gainMuscleMax'){
            setPricing(190)
            setPackage('sumbatik')
             setPackId(24)
        }else if(protein>=140 && protein<=210 && userData?.userGoal==='gainMuscleMax'){
            setPricing(205)
            setPackage('fit')
             setPackId(26)
        }else if(protein>=210 && protein<=260 && userData?.userGoal==='gainMuscleMax'){
            setPricing(235)
            setPackage('fit')
             setPackId(26)
        }else if(protein>=260 && protein<=320 && userData?.userGoal==='gainMuscleMax'){
            setPricing(255)
            setPackage('bulk')
            setPackId(28)
        }
    }

    useEffect(() => {
        getProtien()
        getFats()
        getCarbs()
    },[])

    useEffect(() => {
        getRecommendedPackage()
    },[protein, userData?.userGoal])

    const onContinueClickHandler = () => {
        if(proceedWithPackage){
            localStorage.setItem('caloriesResult', JSON.stringify({selectedPackage, recomendedPackage, protein, userData}))
        }
        localStorage.removeItem('calculate-calories')
        localStorage.removeItem('orderDetail')
        navigate('/package')
    }

    const preSelect = JSON.parse(localStorage.getItem("calculate-calories"))

    return (
    <div className='calories-result'>
        <section>
            {/* {selectedPackage !== undefined ? */}
            <BsCheck2Circle id='check-icon' />
             {/* : <BiErrorCircle id='check-icon' /> }
            {selectedPackage !== undefined ?  */}
            <div>
                <p>{lang !=1 ? "الحزمة الموصى بها" : "Your recommended package"} - <b style={{textTransform:'capitalize', color:'#e52b14'}}>{recomendedPackage}</b></p>
                <p>{selectedPackage?.total_combo_count?.meals} {lang !=1 ?  "وجبات" : "Meals"} - {selectedPackage?.total_combo_count?.snacks} {lang !=1 ? "وجبات خفيفة" :  "Snacks"} - {selectedPackage?.total_combo_count?.soup} {lang !=1 ? "الحساء" : "Soups"}</p>
                <p>{userData?.totalCalories?.toFixed(2)} {lang !=1 ? "سعرات حرارية" : "Calories"}</p>
                {/* <p style={{textTransform: 'capitalize'}}>Gender : {preSelect?.userGender} &nbsp;| &nbsp;Weight : {preSelect?.userWeight} KG &nbsp;|&nbsp; Height : {preSelect?.userHeight} CM</p> */}
            </div>
            {/* <div>
                <p>Didn't Find any Package, based on your selection</p>
            </div> } */}
            {/* {selectedPackage !== undefined &&  */}
            <div className="wraper-outline" id='Spec_Radio'>
                <div className="flex-row" style={{gap:'0.5rem'}}>
                    <input  name="radio-group" onClick={() => setProceedWithPackage(true)} checked={proceedWithPackage} type="radio"  id="subscription" />
                    <label htmlFor="subscription">{lang !=1 ? "المضي قدما في هذا الاشتراك" : "Proceed with this subscription"}</label>
                </div>
                <div className="flex-row" style={{gap:'0.5rem'}}>
                    <input onClick={() => setProceedWithPackage(false)} checked={!proceedWithPackage} type="radio"  name="radio-group" id="custom" />
                    <label htmlFor="custom">{lang !=1 ? "أفضّل أن أقوم باختياراتي بنفسي" : "I prefer making my own choices"}</label>
                </div>
            </div>
            {/* } */}
            <button className="btn-main" onClick={onContinueClickHandler}>{lang !=1 ? "يكمل" : "Continue"}</button>
        </section>
    </div>
  )
}

export default CaloriesResult