import { useNavigate } from "react-router-dom"

const AuthWraper = props => {
    const isUserLoggedIn = localStorage.getItem("isLoggedIn")

    const navigate = useNavigate()
    const redirectToLogin = () => {
        navigate('/sign-in')
    }

    return (
    <>{isUserLoggedIn !== null && Boolean(isUserLoggedIn) == true ? props?.children : redirectToLogin() }</>
  )
}

export default AuthWraper