import {React, useState, useEffect} from "react";
import NavbarSecondary from '../common/NavbarSecondary'
import { FetchTermsAndConditions } from "../Actions/homeActions";
import { useSelector, useDispatch } from "react-redux";
import Footer from '../common/Footer'

const TermsAndConditions = () => {
    const [lang] = useState(localStorage.getItem("langauge_id"));

    const dispatch = useDispatch();
    const TermsAndConditions = useSelector((state) => state.home.termsAndConditions);

    useEffect(() => {
        var data = {
        land_Id: lang,
        };
        dispatch(FetchTermsAndConditions(data));
    }, []);

    console.log("FetchTermsAndConditions", FetchTermsAndConditions);
  return (
    <>
    {/* <NavbarSecondary/> */}
        <section className="innerpages">
      {FetchTermsAndConditions?  <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1 class="text-center">{lang=='1'?'Terms and Condition':'Terms and Condition'}</h1>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div dangerouslySetInnerHTML={{__html: FetchTermsAndConditions.data}}/>
                </div>
            </div>
        </div>:null}
        </section>
        
    
    <Footer/>	
    </>
  );
};

export default TermsAndConditions;
