import { useEffect, useState } from 'react'
import { AiOutlineClose, AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'
import { BsInfoLg } from 'react-icons/bs'
import DishInfoModal from '../PackagesNew/DishInfoModal'

const PackageCard = props => {
  
  const onClickHandler = () => {
    if(props.onClickHandler !== undefined){
      props.onClickHandler()
    }
    
    props.setIndex({name:props.heading,uniq: props.uniq})
  }
  const [lang] = useState(localStorage.getItem("langauge_id"))

  return (
    <div onClick={onClickHandler} id='arabic-card' className={`package-card-new ${props?.selected?.split?.(' ')?.[0] == props.heading?.split?.(' ')?.[0] ? 'active' : ''}`} style={{flexDirection: props.showControls ? 'row':'', justifyContent: 'space-between', width: props?.forAccountPage ? '48%' : ''}}>
        <div className={`top-head ${lang != 1 ? 'arabic' : ''}`}>
            <h2 id={lang != 1 ? 'arabic' : ''} className={`${props?.simpleHeading ? 'simple' : ''}`}>{props.heading} {!props?.simpleHeading && !props.showInfo && <BsInfoLg className={`${props.infoRed ? 'red-info' :''}`} onClick={() => props.setFormInfo(props?.heading)}/>}</h2>
            <span id={lang != 1 ? 'arabic' : ''}>{props.subHeading}</span>             
            {/* <div dangerouslySetInnerHTML={{__html: props.description}}></div> */}
        </div>
        {props.showControls && <div className="cntrl" >
          <AiOutlineMinus id='cntrl-icon' onClick={() => props.onIncreaseClick(props?.menuState?.[0]?.mealID, 'decrease' )}/>
            <span>{props?.menuState?.[0]?.size}</span>
          <AiOutlinePlus id='cntrl-icon' onClick={() => props.onIncreaseClick(props?.menuState?.[0]?.mealID, 'increase')}/>
        </div>}
        {props.children}
    </div>
  )
}

export default PackageCard