import React, { useState } from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import { FaChevronLeft } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import Accordian from '../../Accordian/Accordian'

const KnowBirthday = () => {

    const navigate = useNavigate()
    const [birthday, setBirthday] = useState('')
    const [lang] = useState(localStorage.getItem("langauge_id"))

    const userData = JSON.parse(localStorage.getItem('calculate-calories'))

    console.log("userData", userData);
    const onContinueClickHandler = () => {
        if(birthday !== ''){
            navigate('/calculate-calorie/know-height')
            const payload = {...userData, userBirthday: birthday}
            localStorage.setItem('calculate-calories', JSON.stringify(payload))
        }
    }

    
  return (
    <section className="innerpages">
        <div className="container">
            <div className='account-container'>
                <nav className={`${lang != 1 ? 'arabic': ''}`}>
                    <Link to='/calculate-calorie/know-gender'>
                    <FaChevronLeft style={{fontSize:'23px'}} />
                    </Link>
                    <h4 >{lang !=1 ? "ما هو عيد ميلادك ؟" : "What is your Birthday ?"}</h4>
                </nav>
            </div>
            <div className='form-justify'>
                
                <section className="section-cont">
                    <Accordian heading={lang !=1 ? "تاريخ" : "Date"} value={birthday} onChange={setBirthday}/>
                </section>
                <button className="btn-main" onClick={onContinueClickHandler} >{lang !=1 ? "يكمل" : "Continue"}</button>
                
            </div>
        </div>
    </section>
  )
}

export default KnowBirthday