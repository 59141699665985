import React, { useEffect, useState } from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import { FaChevronLeft } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import HouseAddress from './AddressType/HouseAddress'
import ApartmentAddress from './AddressType/ApartmentAddress'
import OfficeAddress from './AddressType/OfficeAddress'
import { addAddress, getAreaList, getUserAddress } from '../../../Actions/homeActions'
import { useDispatch, useSelector } from 'react-redux'

const AddAddress = () => {
    const [activeToogle, setActiveToogle] = useState(1)
    const [userAddress, setUserAddress] = useState({})
    const [cityData, setCityData] = useState('')
    const [areasList, setAreasList] = useState([])
    const [lang] = useState(localStorage.getItem("langauge_id"))

    useEffect(() => {
        dispatch(getUserAddress())
        dispatch(getAreaList())
    }, [])

    const AreaList = useSelector(state => state.home.AreaList);
    const UserAddres = useSelector(state => state.home.UserAddress);

    useEffect(() => {
        if (UserAddres !== null && UserAddres !== false && UserAddres?.address_type === 'house') {
            setActiveToogle(1)
        }else if(UserAddres !== null && UserAddres !== false && UserAddres?.address_type === 'building'){
            setActiveToogle(2)
        }else if(UserAddres !== null && UserAddres !== false && UserAddres?.address_type === 'office'){
            setActiveToogle(3)
        }
    }, [UserAddres])

    useEffect(() => {
        if (AreaList.length > 0) {
            const newList = AreaList?.map(area => {
                return {
                    value: area?.main_area_id,
                    label: area?.area_name
                }
            })
            setAreasList(newList)
        }
    }, [AreaList])

    // useEffect(() => {
    //     if(UserAddres && Object.keys(UserAddres).length > 0){
    //         setUserAddress({
    //             address_type:UserAddres?.address_type,
    //             area:UserAddres?.area,
    //             avenue:UserAddres?.avenue,
    //             block:UserAddres?.block,
    //             directions:UserAddres?.directions,
    //             flat_no:UserAddres?.flat_no,
    //             floor_no:UserAddres?.floor_no,
    //             house_number:UserAddres?.house_number,
    //             lang:UserAddres?.lang,
    //             lat:UserAddres?.lat,
    //             street:UserAddres?.street,
    //         })
    //     }
    // },[UserAddres])

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const getAddresType = () => {
        if (activeToogle === 1) {
            return "house"
        }
        else if (activeToogle === 2) {
            return "building"
        }
        else if (activeToogle === 3) {
            return "office"
        }
    }

    const AddressSubmit = (e) => {
        e.preventDefault()

        var formData = new FormData
        formData.append('user_id', localStorage.getItem('user_id'))
        formData.append('lang_id', lang)
        formData.append('address_type', getAddresType())
        formData.append('directions', userAddress?.directions)
        formData.append('street', userAddress?.street)
        formData.append('block', userAddress?.block)
        formData.append('floor_no', userAddress?.floor_no)
        formData.append('flat_no', userAddress?.flat_no)
        formData.append('avenue', userAddress?.avenue)
        formData.append('house_number', userAddress?.house_number)
        formData.append('office_number', userAddress?.office_number)
        formData.append('company_name', userAddress?.company_name)
        formData.append('appartment_number', userAddress?.appartment_number)
        formData.append('mobile_number', userAddress?.mobile_number)
        formData.append('area_id', Number(cityData?.value))

        dispatch(addAddress('main_address',formData))
        navigate('/checkout')
    }

    // useEffect(() => {
    //     setUserAddress({})
    // }, [activeToogle])

    //   const UserAddres = useSelector(state => state.home.UserAddress);

    //   useEffect(() => {
    //     if(UserAddres && Object.keys(UserAddres)?.length > 0)
    //     setUserAddress(UserAddres)
    //   },[UserAddres])


    console.log("UserAddres", UserAddres);
    console.log("userAddress", userAddress);


    return (
        <div className="innerpages">
            <div className="container">
                <div className='account-container'>
                    <nav className={`${lang != 1 ? 'arabic' : ''}`}>
                        <Link to='/checkout'>
                            <FaChevronLeft style={{fontSize:'23px'}} />
                        </Link>
                        <h4>Delivery Address</h4>
                    </nav>
                    <section className="section-cont">
                        <h2 className="sec-heading">Choose your payment method</h2>
                        <div className="toogle-row">
                            <div className={`toogle-opt ${activeToogle === 1 ? 'active' : ''}`} onClick={() => setActiveToogle(1)}>House</div>
                            <div className={`toogle-opt ${activeToogle === 2 ? 'active' : ''}`} onClick={() => setActiveToogle(2)}>Apartment</div>
                            <div className={`toogle-opt ${activeToogle === 3 ? 'active' : ''}`} onClick={() => setActiveToogle(3)}>Office</div>
                        </div>
                        <div className="body-address-form">
                            {activeToogle === 1 && <HouseAddress AreaList={areasList} setCityData={setCityData} cityData={cityData} onClick={AddressSubmit} userAddress={userAddress} setAddress={setUserAddress} activeToogle={activeToogle} />}
                            {activeToogle === 2 && <ApartmentAddress AreaList={areasList} setCityData={setCityData} cityData={cityData} onClick={AddressSubmit} userAddress={userAddress} setAddress={setUserAddress} activeToogle={activeToogle} />}
                            {activeToogle === 3 && <OfficeAddress AreaList={areasList} setCityData={setCityData} cityData={cityData} onClick={AddressSubmit} userAddress={userAddress} setAddress={setUserAddress} activeToogle={activeToogle} />}
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default AddAddress