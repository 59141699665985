import React, { useEffect, useState } from 'react'
import Input from '../../FormInputs/Input'
import SelectInput from '../../FormInputs/Select'
import { useDispatch, useSelector } from 'react-redux'
import { getUserAddress } from '../../../../Actions/homeActions'

const HouseAddress = ({ onClick , userAddress, setAddress, setCityData, AreaList, cityData,activeToogle }) => {

  const dispatch = useDispatch()

  // useEffect(() => {
  //   dispatch(getUserAddress())
  // },[])

  const UserAddres = useSelector(state => state.home.UserAddress);
  const [lang] = useState(localStorage.getItem("langauge_id"))

  useEffect(() => {
    if(UserAddres!==null && UserAddres!==false && UserAddres?.address_type === 'house'){
      setAddress(UserAddres)
      setCityData({value:UserAddres?.area?.area_id,label:UserAddres?.area?.area_name})
    }else{
      setAddress({
        block:'', 
        street:'', 
        avenue:'', 
        house_number:null, 
        mobile_number:null, 
        directions:'', 
      })
      setCityData('')
    }
  },[UserAddres])

  const onChangeHandler = (option) => {
    console.log("option", option);
    setCityData(option)
  }

  console.log('HouseAddress',userAddress);


  return (
    <form action="post">
      <SelectInput placeholder="Select Area" options={AreaList} handleChange={onChangeHandler} value={cityData}/>
      <Input value={userAddress?.block} onChange={e => setAddress({...userAddress, [e.target.name]:e.target.value})} name="block" placeholder="* Block"/>
      <Input value={userAddress?.street} onChange={e => setAddress({...userAddress, [e.target.name]:e.target.value})} name="street" placeholder="Street"/>
      <Input value={userAddress?.avenue} onChange={e => setAddress({...userAddress, [e.target.name]:e.target.value})} name="avenue" placeholder="Avenue"/>
      <Input type="number" showIcon={false} value={userAddress?.house_number} onChange={e => setAddress({...userAddress, [e.target.name]:e.target.value})} name="house_number" placeholder="* House Number"/>
      {/* <Input type="number" showIcon={false} value={userAddress?.mobile_number} onChange={e => setAddress({...userAddress, [e.target.name]:e.target.value})} name="mobile_number" placeholder="* Mobile Number"/> */}
      <Input value={userAddress?.directions} onChange={e => setAddress({...userAddress, [e.target.name]:e.target.value})} name="directions" placeholder="Add a note"/>
      <button className="btn-main" onClick={onClick}>{lang !=1 ? "يكمل" : "Continue"}</button>
    </form>
  )
}

export default HouseAddress