import React, { useEffect, useState } from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import { FaChevronLeft } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import PackageCard from '../../PackageCard/PackageCard'

const KnowGender = () => {

    const navigate = useNavigate()
    const [gender, setGender] = useState(null)
    const [lang] = useState(localStorage.getItem("langauge_id"))

    useEffect(() => {
        localStorage.removeItem('calculate-calories')
        localStorage.removeItem('orderDetail')
    },[])

    const onContinueClickHandler = () => {
        if(gender !=null){
            navigate('/calculate-calorie/know-birthday')
        }
        const payload = {
            userGender: gender
        }
        localStorage.setItem('calculate-calories', JSON.stringify(payload))
    }

    console.log("genderlang", typeof(lang));
    
  return (
    <section className="innerpages">
        <div className="container">
            <div className='account-container'>
                <nav className={`${lang != 1 ? 'arabic': ''}`}>
                    <Link to='/package'>
                        <FaChevronLeft style={{fontSize:'23px'}} />
                    </Link>
                    <h4 >{lang !=1 ? "ما هو جنسك؟" : "What is your Gender ?"}</h4>
                </nav>
            </div>
            <div className='form-justify'>
                
                <section className="section-cont">
                    <div className={`flex-row ${lang != 1 ? 'arabic': ''}`}>

                        <div className={`package-card-new ${gender === 'male' ? 'active' : ''}`} onClick={() => setGender('male')}>
                            <div className={`top-head ${lang != 1 ? 'arabic' : ''}`}>
                                <h2 className='simple'>{lang != 1 ? "ذكر" : "Male"}</h2>
                            </div>
                        </div>

                        <div className={`package-card-new ${gender === 'female' ? 'active' : ''}`} onClick={() => setGender('female')}>
                            <div className={`top-head ${lang != 1 ? 'arabic' : ''}`}>
                                <h2 className='simple'>{lang != 1 ? "أنثى" : "Female"}</h2>
                            </div>
                        </div>

                    </div>
                </section>
                <button className="btn-main" onClick={onContinueClickHandler} >{lang !=1 ? "يكمل" : "Continue"}</button>
                
            </div>
        </div>
    </section>
  )
}

export default KnowGender