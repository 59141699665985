import { useEffect, useState } from 'react'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { BsFillKeyFill } from 'react-icons/bs'
import { FaUserAlt } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'
import { ImMobile2 } from 'react-icons/im'

const Input = ({ placeholder, type, onChange, name, showIcon, value }) => {
  const [passwordShow, setPasswordShow] = useState(false)
  const [inputType, setInputType] = useState(type)

  useEffect(() => {
    if(!passwordShow){
      setInputType(type)
    } else {
      setInputType('text')
    }
  },[passwordShow])
  
  return (
    <div className='form-input'>
        {type === 'email' && showIcon && <MdEmail  id='input-icon'/>}
        {type === 'text' && showIcon && <FaUserAlt id='input-icon'/>}
        {type === 'password' && showIcon && <BsFillKeyFill id='input-icon'/>}
        {type === 'number' && showIcon && <ImMobile2 id='input-icon'/>}
        <input value={value} name={name ? name : null} style={{paddingLeft: !showIcon ? '0.9rem' : ''}} onChange={onChange} type={inputType ? inputType : 'text'} placeholder={placeholder ? placeholder : null}/>
        {type === 'password' && showIcon && (!passwordShow ? <AiOutlineEye id='input-icon-eye' onClick={() => setPasswordShow(e => !e)}/> : <AiOutlineEyeInvisible  id='input-icon-eye' onClick={() => setPasswordShow(e => !e)}/>) }
    </div>
  )
}

export default Input