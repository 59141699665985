import React, { useEffect, useState } from 'react'
import { AiFillApple } from 'react-icons/ai'
import { BsFillTicketFill, BsArrowLeft, BsCreditCard2BackFill, BsCreditCardFill, BsWalletFill, BsFillSendFill } from 'react-icons/bs'
import { Link, useNavigate } from 'react-router-dom'
import GoogleMap from '../GoogleMap/GoogleMap'
import { FaChevronLeft } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { getCoupon, getUserAddress, getUserWallet, purchasePackage } from '../../../Actions/homeActions'
import { toast } from 'react-toastify'
import AuthWraper from '../../../common/AuthWraper'


const Checkout = () => {
  const navigate = useNavigate()
  const paymentPrice = localStorage.getItem('FinalePaymentPrice')

  const [USER_ID] = useState(localStorage.getItem('user_id'));
  const orderData = JSON.parse(localStorage.getItem('orderDetail'))
  const subPackageID = JSON.parse(localStorage.getItem('subPackageID'))
  const [paymentType, setPaymentType] = useState('')
  const [lang] = useState(localStorage.getItem("langauge_id"))

  const [WalletAmount, setWalletAmount] = useState()

  const [useWallet, setUseWallet] = useState(false)

  const [CouponCode, setCouponCode] = useState('')
  const [couponLabel, setCouponLabel] = useState("")
  const [Applied, setApplied] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUserWallet())
    dispatch(getUserAddress())
  }, [])

  const UserWallet = useSelector(state => state.home.UserWallet);
  const UserAddress = useSelector(state => state.home.UserAddress);
  const Coupon = useSelector(state => state.home.Coupon);




  useEffect(() => {
    if (UserWallet) {
      setWalletAmount(UserWallet?.wallet_amount)
    }
  }, [UserWallet])

  console.log('UserWallet', UserWallet);


  const ApplyCoupon = () => {
    setApplied(true)
    var formData = new FormData()
    formData.append('user_id', USER_ID)
    formData.append('coupon_code', CouponCode)

    dispatch(getCoupon(formData))
  }

  useEffect(() => {
    if (Coupon) {
      if (Coupon.discount_type == "percentage") {
        setCouponLabel(`Congrats!  You Will get ${Coupon.discount_value}% Discount`)
      }
    }
    else if (!Coupon && Applied) {
      setCouponLabel("Your Coupon Code is Wrong or Expired")
    }
  }, [Coupon, Applied])

  console.log('UserAddress', UserAddress);




  // const onPayNowHandler = () => {
  //   if (paymentPrice !== '' && paymentPrice !== undefined && UserAddress && Object.keys(UserAddress).length > 0) {
  //     if (useWallet) {
  //       if (WalletAmount == 0) {
  //         toast.warning(lang == '1' ? "Sorry! Your Wallet Balance is Zero" : 'آسف! رصيدك في المحفظة هو صفر', {
  //           position: toast.POSITION.TOP_RIGHT
  //         });
  //         return false
  //       }

  //       let totalAmount = Number(WalletAmount) > parseInt(paymentPrice).toFixed(2) ? 0 : parseInt(paymentPrice).toFixed(2) - Number(WalletAmount)

  //       if (totalAmount > 0) {
  //         if (paymentType === '') {
  //           toast.warning(lang == '1' ? "Please select payment method" : '', {
  //             position: toast.POSITION.TOP_RIGHT
  //           });
  //           return false
  //         }
  //       }

  //       var endDate = new Date(orderData?.subscriptionDate.startDate)

  //       var formData = new FormData
  //       formData.append('delivery_time_id', orderData?.deliveryID)
  //       formData.append('end_date', '')
  //       formData.append('package_for', 1)
  //       formData.append('package_amount', Number(paymentPrice).toFixed(0))
  //       formData.append('promo_code_discount', 0.0)
  //       formData.append('delivery_address_id', UserAddress?.address_id)
  //       formData.append('package_id', orderData?.packageID)
  //       formData.append('consultant_fees', 0)
  //       formData.append('sub_package_id', subPackageID)
  //       formData.append('payment_type', totalAmount > 0 ? paymentType : 'wallet')
  //       formData.append('delivery_method_id', orderData?.deliveryMethodID)
  //       formData.append('total_amount', totalAmount)
  //       formData.append('user_id', localStorage.getItem('user_id'))
  //       formData.append('app_mode', 'prod')

  //       formData.append('unique_code', endDate.getTime() * 32)
  //       formData.append('start_date', orderData?.subscriptionDate?.startDate)

  //       var DATA = {
  //         payment: parseInt(paymentPrice),
  //         totalAmount: totalAmount,
  //         walletAmount: Number(WalletAmount),
  //         paymentType: paymentType
  //       }

  //       dispatch(purchasePackage(formData, DATA))
  //     } else {
  //       var endDate = new Date(orderData?.subscriptionDate.startDate)

  //       var formData = new FormData
  //       formData.append('delivery_time_id', orderData?.deliveryID)
  //       formData.append('end_date', '')
  //       formData.append('package_for', 1)
  //       formData.append('package_amount', Number(paymentPrice).toFixed(0))
  //       formData.append('promo_code_discount', 0.0)
  //       formData.append('delivery_address_id', UserAddress?.address_id)
  //       formData.append('package_id', orderData?.packageID)
  //       formData.append('consultant_fees', 0)
  //       formData.append('sub_package_id', subPackageID)
  //       formData.append('payment_type', paymentType)
  //       formData.append('delivery_method_id', orderData?.deliveryMethodID)
  //       formData.append('total_amount', parseInt(paymentPrice).toFixed(2))
  //       formData.append('user_id', localStorage.getItem('user_id'))
  //       formData.append('app_mode', 'prod')

  //       formData.append('unique_code', endDate.getTime() * 32)
  //       formData.append('start_date', orderData?.subscriptionDate?.startDate)

  //       var DATA = {
  //         payment: parseInt(paymentPrice),
  //         totalAmount: parseInt(paymentPrice),
  //         walletAmount: null,
  //         paymentType: paymentType
  //       }

  //       dispatch(purchasePackage(formData, DATA))
  //     }

  //   } else {
  //     alert("**Address")
  //   }
  // }

  return (
    <AuthWraper>
      <div className="innerpages">
        <div className="container">
          <div className='account-container'>
            <nav>
              <Link to='/package'>
                <FaChevronLeft style={{ fontSize: '23px' }} />
              </Link>
              <h4>Checkout</h4>
            </nav>
            <div className="map-container">
              <GoogleMap />
            </div>
            <section>
              {UserAddress && Object.keys(UserAddress)?.length > 0 && <div className="address-user">

                <h3>Address</h3>
                <span><b>Type:</b> {UserAddress?.address_type}</span>
                <span><b>Area:</b> {UserAddress?.area?.area_name}</span>
                <span><b>Block:</b> {UserAddress?.block}</span>
                <span><b>Street:</b> {UserAddress?.street}</span>
                <span><b>Avenue:</b> {UserAddress?.avenue}</span>
                <span><b>Floor No:</b> {UserAddress?.floor_no !== "undefined" && UserAddress?.floor_no !== "null" && UserAddress?.floor_no}</span>
                <span><b>Appartment No:</b> {UserAddress?.flat_no !== "undefined" && UserAddress?.flat_no !== "null" && UserAddress?.flat_no}</span>
                <span><b>House No:</b> {UserAddress?.house_number !== "undefined" && UserAddress?.house_number !== "null" && UserAddress?.house_number}</span>
                <span><b>Directions:</b> {UserAddress?.directions}</span>

              </div>}
              <button onClick={()=>navigate('add-address')} className="btn-main" style={{ width: 'fit-content' }} >{UserAddress && Object.keys(UserAddress)?.length > 0 ? 'Update' : 'Add'} Address</button>

            </section>
            <section className="section-cont">
              <h2 className="sec-heading" id={lang != 1 ? 'arabic' : ''}>{lang == 1 ? "Choose your payment method" : "اختر طريقة الدفع"}</h2>

              <div className="flex-row">

                {/* <div style={{width: '31.5%'}} className={`payment-card ${lang !=1 ? 'arabic' : ''} ${paymentType=== 'apple' ? 'active' : ''}`} onClick={() =>setPaymentType('apple')}>
                    <AiFillApple id='card-icon-2'/>
                    <h5>{lang == 1 ? "Apple Pay":"أبل باي"}</h5>
                  </div> */}
                <div style={{ width: '31.5%' }} className={`payment-card ${lang != 1 ? 'arabic' : ''} ${paymentType === 'knet' ? 'active' : ''}`} onClick={() => setPaymentType('knet')}>
                  <BsCreditCard2BackFill id='card-icon-2' />
                  <h5>{lang == 1 ? "Knet" : "كي نت"}</h5>
                </div>
                <div style={{ width: '31.5%' }} className={`payment-card ${lang != 1 ? 'arabic' : ''} ${paymentType === 'visa' ? 'active' : ''}`} onClick={() => setPaymentType('visa')}>
                  <BsCreditCardFill id='card-icon-2' />
                  <h5>{lang == 1 ? "Visa - Master Card" : "فيزا - ماستر كارد"}</h5>
                </div>
                <div style={{ width: '31.5%' }} className={`payment-card ${lang != 1 ? 'arabic' : ''} ${useWallet ? 'active' : ''}`} onClick={() => setUseWallet(!useWallet)}>
                  <BsWalletFill id='card-icon-2' />
                  <h5>{lang == 1 ? "Wallet" : "المحفظة"}</h5>
                  <h6>{WalletAmount} KD</h6>
                </div>
              </div>

              {/* <div className="flex-row" style={{marginTop:'0.4rem'}}>
                  <div style={{width:'31.5%', flexDirection:'column', alignItems:'flex-start'}} className="payment-card">
                    <div style={{display:'flex', alignItems:'center', gap: '1.2rem'}}>
                      <h5>Wallet</h5>
                      <BsWalletFill  id='card-icon-2'/>
                    </div>
                    <div>
                      <h6>balance : {WalletAmount} KWD</h6>
                      <div style={{display:'flex', justifyContent:'space-between'}}>
                        <input type="number" min={0} placeholder='Enter Amount' style={{width:'70%'}}/>
                        <button className='btn-main' style={{fontSize: '0.8rem', width:'fit-content'}} onClick={onWalletSpendClick}>Spend</button>
                      </div>
                    </div>
                  </div>
                </div> */}

              <div className={`flex-row ${lang != 1 ? 'arabic' : ''}`}>
                <div className="coupon-bar">
                  <BsFillTicketFill id='card-icon-2' />
                  <input type="text" placeholder={lang == 1 ? 'Enter discount code' : "أدخل رمز الخصم"} onChange={e => setCouponCode(e.target.value)} />
                  <BsFillSendFill id='card-icon' onClick={ApplyCoupon} />
                  {/* <div>
                        <label htmlFor="card-icon">Enter discount code</label>
                      </div> */}
                </div>
                {couponLabel !== "" && Applied && <span style={{ color: 'var(--color-primary-red)', margin: '0', padding: '0' }}>{couponLabel}</span>}
              </div>
            </section>
            <section className="section-cont">
              <h2 className="sec-heading" id={lang != 1 ? 'arabic' : ''}>{lang == 1 ? "Payment Summary" : "ملخص الدفع"}</h2>
              <div className="summery">
                <div className={`row ${lang != 1 ? 'arabic' : ''}`}>
                  <div>
                    {lang == 1 ? "Sub Total" : "المجموع	"}
                  </div>
                  <div>
                    {/* {paymentType=== 'wallet' ? parseInt(paymentPrice).toFixed(2) - Number(WalletAmount) : parseInt(paymentPrice).toFixed(2)} KD */}
                    {parseInt(paymentPrice).toFixed(2)} KD
                  </div>
                </div>
                {useWallet && <div className={`row ${lang != 1 ? 'arabic' : ''}`}>
                  <div>{lang == 1 ? "Wallet Amount" : "المحفظة	"}</div>
                  <div>{useWallet && WalletAmount + " KD"}</div>
                </div>}
                {/* <div className="row">
                    <div>Discount Amount</div>
                    <div>0</div>
                  </div> */}
                <div className={`row ${lang != 1 ? 'arabic' : ''}`}>
                  <div><b>{lang == 1 ? "Total Amount" : "المبلغ الإجمالي	"}</b></div>
                  <div><b>{useWallet ? Number(WalletAmount) > parseInt(paymentPrice).toFixed(2) ? 0 : parseInt(paymentPrice).toFixed(2) - Number(WalletAmount) : parseInt(paymentPrice).toFixed(2)} KD</b></div>
                  {/* <div><b>{parseInt(paymentPrice).toFixed(2)} KD</b></div> */}
                </div>
              </div>
            </section>
            {/* <button disabled className="btn-main" onClick={onPayNowHandler}>Pay now</button> */}
            <button disabled className="btn-main">Pay now</button>
          </div>
        </div>
      </div>
    </AuthWraper>
  )
}

export default Checkout