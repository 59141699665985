import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

const CalculateCalories = () => {
  const naviagte = useNavigate()

  useEffect(() => {
    naviagte('/calculate-calorie/know-gender')
  },[])
  return (
    <>
        <Outlet />
    </>
  )
}

export default CalculateCalories