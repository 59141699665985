import React, { useState, useEffect } from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import { FaChevronLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import CaloriesResult from '../CaloriesResult'
import CountingLoader from './CountingLoader'

const CalculateResult = () => {
    const [lang] = useState(localStorage.getItem("langauge_id"))
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true)
    }, [])
    
    return (
        <section className="innerpages">
            <div className="container">
                <div className='account-container'>
                    {!isLoading&&<nav className={`${lang != 1 ? 'arabic' : ''}`}>
                        <Link to='/calculate-calorie/know-goal'>
                            <FaChevronLeft style={{ fontSize: '23px' }} />
                        </Link>
                        {/* <h4>{lang !=1 ? "حساب السعرات الحرارية الخاصة بك" : "Calculating your Calories"}</h4> */}
                    </nav>}
                </div>
                {isLoading ? <CountingLoader setIsLoading={setIsLoading} /> : <CaloriesResult />}


            </div>
        </section>
    )
}

export default CalculateResult