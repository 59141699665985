import React, { useEffect, useRef, useState } from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import { FaChevronLeft } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import RangeSlider from '../../../../common/RangeSlider/RangeSlider'

const KnowHeight = () => {

    const navigate = useNavigate()
    const [rangeValue, setRangeValue] = useState(145)
    const [lang] = useState(localStorage.getItem("langauge_id"))

    const userData = JSON.parse(localStorage.getItem('calculate-calories'))

    console.log("userData", userData);
    const onContinueClickHandler = () => {
        if (rangeValue >= 1) {
            navigate('/calculate-calorie/know-weight')
            const payload = { ...userData, userHeight: Math.floor(rangeValue) }
            localStorage.setItem('calculate-calories', JSON.stringify(payload))
        }
    }

    return (
        <section className="innerpages">
            <div className="container">
                <div className='account-container'>
                    <nav className={`${lang != 1 ? 'arabic' : ''}`}>
                        <Link to='/calculate-calorie/know-birthday'>
                            <FaChevronLeft style={{ fontSize: '23px' }} />
                        </Link>
                        <h4>{lang != 1 ? "كم طولك ؟" : "How tall are you ?"}</h4>
                    </nav>
                </div>
                <div className='form-justify'>
                    <section className="section-cont">
                        <div className="value-wraper">
                            <div className="hgt-value">
                                <p>{Math.floor(rangeValue)}</p>
                                <span>{lang != 1 ? "سم" : "CM"}</span>
                            </div>
                            <RangeSlider value={rangeValue} onChange={setRangeValue} min={0} max={250} />
                            {/* <div className="dragger-container">
                            <div className="bar-drag" onClick={(e) =>setMousePos(e.clientX)}>
                                <div className="dragger" style={{left: `${getDraggerPosition()}px`}}/>

                            </div>
                        </div> */}
                        </div>
                    </section>
                    <button className="btn-main" onClick={onContinueClickHandler} >{lang != 1 ? "يكمل" : "Continue"}</button>

                </div>
            </div>
        </section>
    )
}

export default KnowHeight