import React, { useEffect, useState } from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import { FaChevronLeft } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'

const KnowGoal = () => {
    
    const navigate = useNavigate()

    const [userGoal, setUserGoal] = useState('')
    const userData = JSON.parse(localStorage.getItem('calculate-calories'))
    const [totalCalories, setTotalCalories] = useState(null)
    const [lang] = useState(localStorage.getItem("langauge_id"))

    useEffect(() => {
        getTotalCalories()
    },[userGoal])

    const onGoalClickHandler = (goal) => {
        setUserGoal(goal)
        console.log("totalCaloriesasd", goal);
    }
    const getTotalCalories = () => {
        // Not Active
        if(userData?.userSteps==='notActive' && userGoal==='looseWeight'){
            setTotalCalories(Number(userData?.BMR)*1.2-500)
        }else if(userData?.userSteps==='notActive' && userGoal==='maintainWeight'){
            setTotalCalories(Number(userData?.BMR)*1.2)
        }else if(userData?.userSteps==='notActive' && userGoal==='gainWeight'){
            setTotalCalories(Number(userData?.BMR)*1.2+500)
        }else if(userData?.userSteps==='notActive' && userGoal==='gainMuscle'){
            setTotalCalories(Number(userData?.BMR)*1.2-500)
        }else if(userData?.userSteps==='notActive' && userGoal==='gainMuscleMax'){
            setTotalCalories(Number(userData?.BMR)*1.2+500)
        }

        // Lightly Active
        if(userData?.userSteps==='low' && userGoal==='looseWeight'){
            setTotalCalories(Number(userData?.BMR)*1.375-500)
        }else if(userData?.userSteps==='low' && userGoal==='maintainWeight'){
            setTotalCalories(Number(userData?.BMR)*1.375)
        }else if(userData?.userSteps==='low' && userGoal==='gainWeight'){
            setTotalCalories(Number(userData?.BMR)*1.375+500)
        }else if(userData?.userSteps==='low' && userGoal==='gainMuscle'){
            setTotalCalories(Number(userData?.BMR)*1.375-500)
        }else if(userData?.userSteps==='low' && userGoal==='gainMuscleMax'){
            setTotalCalories(Number(userData?.BMR)*1.375+500)
        }

        // moderate Active
        if(userData?.userSteps==='moderate' && userGoal==='looseWeight'){
            setTotalCalories(Number(userData?.BMR)*1.55-500)
        }else if(userData?.userSteps==='moderate' && userGoal==='maintainWeight'){
            setTotalCalories(Number(userData?.BMR)*1.55)
        }else if(userData?.userSteps==='moderate' && userGoal==='gainWeight'){
            setTotalCalories(Number(userData?.BMR)*1.55+500)
        }else if(userData?.userSteps==='moderate' && userGoal==='gainMuscle'){
            setTotalCalories(Number(userData?.BMR)*1.55-500)
        }else if(userData?.userSteps==='moderate' && userGoal==='gainMuscleMax'){
            setTotalCalories(Number(userData?.BMR)*1.55+500)
        }

         // Very Active
         if(userData?.userSteps==='veryActive' && userGoal==='looseWeight'){
            setTotalCalories(Number(userData?.BMR)*1.725-500)
        }else if(userData?.userSteps==='veryActive' && userGoal==='maintainWeight'){
            setTotalCalories(Number(userData?.BMR)*1.725)
        }else if(userData?.userSteps==='veryActive' && userGoal==='gainWeight'){
            setTotalCalories(Number(userData?.BMR)*1.725+500)
        }else if(userData?.userSteps==='veryActive' && userGoal==='gainMuscle'){
            setTotalCalories(Number(userData?.BMR)*1.725-500)
        }else if(userData?.userSteps==='veryActive' && userGoal==='gainMuscleMax'){
            setTotalCalories(Number(userData?.BMR)*1.725+500)
        }

         // Extra Active
         if(userData?.userSteps==='extraActive' && userGoal==='looseWeight'){
            setTotalCalories(Number(userData?.BMR)*1.9-500)
        }else if(userData?.userSteps==='extraActive' && userGoal==='maintainWeight'){
            setTotalCalories(Number(userData?.BMR)*1.9)
        }else if(userData?.userSteps==='extraActive' && userGoal==='gainWeight'){
            setTotalCalories(Number(userData?.BMR)*1.9+500)
        }else if(userData?.userSteps==='extraActive' && userGoal==='gainMuscle'){
            setTotalCalories(Number(userData?.BMR)*1.9-500)
        }else if(userData?.userSteps==='extraActive' && userGoal==='gainMuscleMax'){
            setTotalCalories(Number(userData?.BMR)*1.9+500)
        }

    }

    const onContinueClickHandler = () => {
        if(totalCalories !== null && userGoal !== ""){
            navigate('/calculate-calorie/calculate')
        }
        const payload = {...userData, userGoal, totalCalories}
        console.log("payload",totalCalories, payload);
        localStorage.setItem('calculate-calories', JSON.stringify(payload))
    }
    
  return (
    <section className="innerpages">
        <div className="container">
            <div className='account-container'>
                <nav className={`${lang != 1 ? 'arabic': ''}`}>
                    <Link to='/calculate-calorie/know-activity-level'>
                    <FaChevronLeft style={{ fontSize: '23px' }} />
                    </Link>
                    <h4 >{lang !=1 ? "ما هو هدفك؟" : "What is your goal?"}</h4>
                </nav>
            </div>
            <div className="form-justify">
                <section className="section-count">
                    <div className="flex-row">
                        
                        <div className={`package-card-new ${userGoal === 'looseWeight' ? 'active' : ''}`} onClick={() => onGoalClickHandler('looseWeight')}>
                            <div className={`top-head ${lang != 1 ? 'arabic' : ''}`}>
                                <h2 className='simple'>{lang !=1 ? "فقدان الوزن" : "Weight Loss"}</h2>
                            </div>
                            </div>

                        <div className={`package-card-new ${userGoal === 'maintainWeight' ? 'active' : ''}`} onClick={() => onGoalClickHandler('maintainWeight')}>
                            <div className={`top-head ${lang != 1 ? 'arabic' : ''}`}>
                                <h2 className='simple'>{lang !=1 ? "صيانة الوزن" : "Weight Maintenance"}</h2>
                            </div>
                        </div>

                        <div className={`package-card-new ${userGoal === 'gainWeight' ? 'active' : ''}`} onClick={() => onGoalClickHandler('gainWeight')}>
                            <div className={`top-head ${lang != 1 ? 'arabic' : ''}`}>
                                <h2 className='simple'>{lang !=1 ? "زيادة الوزن" : "Weight Gain"}</h2>
                            </div>
                        </div>

                        <div className={`package-card-new ${userGoal === 'gainMuscle' ? 'active' : ''}`} onClick={() => onGoalClickHandler('gainMuscle')}>
                            <div className={`top-head ${lang != 1 ? 'arabic' : ''}`}>
                                <h2 className='simple'>{lang !=1 ? "فقدان الدهون وبناء العضلات" : "Fat Loss & Muscle Building"}</h2>
                            </div>
                        </div>

                        <div className={`package-card-new ${userGoal === 'gainMuscleMax' ? 'active' : ''}`} onClick={() => onGoalClickHandler('gainMuscleMax')}>
                            <div className={`top-head ${lang != 1 ? 'arabic' : ''}`}>
                                <h2 className='simple'>{lang !=1 ? "حجم كبير" : "Bulk"}</h2>
                            </div>
                        </div>

                    </div>
                </section>
                <button className="btn-main" onClick={onContinueClickHandler} >{lang !=1 ? "يكمل" : "Continue"}</button>

            </div>
        </div>
    </section>
  )
}

export default KnowGoal