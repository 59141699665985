import React, { useEffect, useState } from 'react'
import SelectInput from '../../FormInputs/Select'
import Input from '../../FormInputs/Input'
import { useSelector } from 'react-redux';

const OfficeAddress = ({ onClick , userAddress, setAddress,setCityData, AreaList, cityData }) => {

  const UserAddres = useSelector(state => state.home.UserAddress);

  useEffect(() => {
    setAddress({})
  },[])
  const [lang] = useState(localStorage.getItem("langauge_id"))

  useEffect(() => {
    if(UserAddres!==null && UserAddres!==false && UserAddres?.address_type === 'office'){
      setAddress(UserAddres)
      setCityData({value:UserAddres?.area?.area_id,label:UserAddres?.area?.area_name})
    }else{
      setAddress({
        block:'', 
        street:'', 
        company_name:'', 
        floor_no:null, 
        mobile_number:null, 
        directions:'', 
      })
      setCityData('')
    }
  },[UserAddres])

  const onChangeHandler = (option) => {
    console.log("option", option);
    setCityData(option)
  }


  return (
    <form action="post">
      <SelectInput  placeholder="Select Area" options={AreaList} handleChange={onChangeHandler} value={cityData}/>
      <Input type="text" onChange={e => setAddress({...userAddress, [e.target.name]:e.target.value})} name="block" value={userAddress?.block}  placeholder="* Block"/>
      <Input type="text" onChange={e => setAddress({...userAddress, [e.target.name]:e.target.value})} name="street" value={userAddress?.street}  placeholder="Street"/>
      <Input type="number" onChange={e => setAddress({...userAddress, [e.target.name]:e.target.value})} name="office_number" value={userAddress?.office_number}  placeholder="Office Number (optional)"/>
      <Input type="text" showIcon={false} onChange={e => setAddress({...userAddress, [e.target.name]:e.target.value})} name="company_name" value={userAddress?.company_name}  placeholder="Company Name"/>
      <Input type="number" showIcon={false} onChange={e => setAddress({...userAddress, [e.target.name]:e.target.value})} name="floor_no" value={userAddress?.floor_no}  placeholder="Floor Number"/>
      {/* <Input type="number" showIcon={false} onChange={e => setAddress({...userAddress, [e.target.name]:e.target.value})} name="mobile_number" value={userAddress?.mobile_number}  placeholder="* Mobile Number"/> */}
      <Input onChange={e => setAddress({...userAddress, [e.target.name]:e.target.value})} name="directions" value={userAddress?.directions}  placeholder="Add a note"/>
      <button className="btn-main" onClick={onClick}>{lang !=1 ? "يكمل" : "Continue"}</button>
  </form>
  )
}

export default OfficeAddress