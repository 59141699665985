import {React, useState, useEffect} from "react";
import Navbar from '../common/Navbar'
import Footer from '../common/Footer'
import { Link, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import TagManager from 'react-gtm-module';
import { 
    getPackageList,
    getPackageDetails,
    getDeliveryTime,
    getDeliveryMethod,
    getUserAddress,
    getAreaList,
    addAddress,
    purchasePackage,
    getDislikeItems,
    getReadMore,
    getCoupon,
    getUserWallet,
} from "../Actions/homeActions";
import { toast, ToastContainer } from "react-toastify";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 5).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
};

const Package = () => {
    const [lang] = useState(localStorage.getItem("langauge_id"))
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        className:'HomeSlider',
        responsive:[
            {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  initialSlide: 0,
                  infinite: false,
                  dots: true
                }
            },
        ]
      };

    const PackageID = useParams()
    const Location = useLocation()

    const dispatch = useDispatch()

    console.log('Params',PackageID, PackageID.pricing?JSON.parse(PackageID.pricing):null);

    const PackageDetails = useSelector(state => state.home.PackageDetails);
    const PackageList = useSelector(state => state.home.PackageList);
    const DeliveryTime = useSelector(state => state.home.DeliveryTime);
    const DeliveryMethod = useSelector(state => state.home.DeliveryMethod);
    // const DislikeItems = useSelector(state => state.home.DislikeItems);
    const UserAddress = useSelector(state => state.home.UserAddress);
    const UserWallet = useSelector(state => state.home.UserWallet);
    const AreaList = useSelector(state => state.home.AreaList);
    // const Refresh = useSelector(state => state.home.Refresh);
    const ReadMore = useSelector(state => state.home.ReadMore);
    const Coupon = useSelector(state => state.home.Coupon);

    console.log("PackageDetails", UserWallet);

    const [modalReadMore, setModalReadMore] = useState(false)
    const [modalAddress, setModalAddress] = useState(false)
    // const [modalDislikes, setModalDislikes] = useState(false)



    const [packageID, setPackageID] = useState()
    const [subPackageID, setSubPackageID] = useState()
    const [sub_package_id, setSub_package_id] = useState()
    const [dishValue, setVishValue] = useState({Meals: '3', Snacks: '2', Soups: '1'})
    const [subPackage, setSubPackage] = useState()

    const [finalPrice, setFinalPrice] = useState(0)
    const [DISCOUNT, setDISCOUNT] = useState(0)
    const [SUBTOTAL, setSUBTOTAL] = useState(0)

    const [startDate, setStartDate] = useState()
    const [startDateS, setStartDateS] = useState()
    const [deliveryTime, setDeliveryTime] = useState()
    const [deliveryMethod, setDeliveryMethod] = useState()
    // const [isFriday, setIsFriday] = useState('no')
    const [paymentType, setPaymentType] = useState('')
    const [address, setAddress] = useState({})
    const [CouponCode, setCouponCode] = useState('')

    const [WalletSpend, setWalletSpend] = useState(null)
    // const [dislikeItems, setDislikeItems] = useState([])
  
    const [USER_ID] = useState(localStorage.getItem('user_id'));
    const [PackageLIST, setPackageLIST] = useState([])

//    console.log('WalletSpend',WalletSpend);

        useEffect(() => {
            if(lang=='1'&&PackageList){
            setPackageLIST(PackageList)
            }else if(lang=='2'&&PackageList){
            setPackageLIST(PackageList.reverse())
            }
        }, [lang, PackageList])

      useEffect(() => {
        if(PackageID.pricing){
            var pack = JSON.parse(PackageID.pricing).total_combo_count
            var packId = JSON.parse(PackageID.pricing).sub_package_id
            setVishValue({Meals: pack.meals, Snacks:  pack.snacks, Soups:  pack.soup})
            setSubPackageID(packId)
        }
      }, [PackageID.pricing])
      
    
    const AddressChange =(e)=>{
        const { name, value } = e.target;

        setAddress({
        ...address,
        [name]: value,
        });
    }

    useEffect(() => {
     var StartDate = new Date(startDate).getTime()
     var date = new Date(StartDate).getDate()
     var month = new Date(StartDate).getMonth()
     var year = new Date(StartDate).getFullYear()

    var Fmonth = month==0?'January':month==1?'February':month==2?'March':month==3?'April':month==4?'May':month==5?'June':month==6?'July':month==7?'August':month==8?'September':month==9?'October':month==10?'November':month==11?'December':''
    var final = `${date} ${Fmonth} ${year}`
    localStorage.setItem('startDate',final)
     setStartDateS(final)
    }, [startDate])


    useEffect(() => {
        var StartDate = new Date(startDate).getTime()
        var Today = new Date().getTime()+96*60*60*1000

        // console.log('Today',Today);
        if(StartDate<Today){
            setStartDateS('')
            setStartDate()
            toast.warning(lang=='1'?"Please Select A Date After 4 Days!":"يرجى تحديد تاريخ بعد 4 أيام!", {
            position: toast.POSITION.TOP_RIGHT
            });
            return false
        }
    }, [startDate])
    
    
    useEffect(() => {
        
        if(PackageID){
            setPackageID(PackageID.PackageID)
            // console.log('PARAM');
        }

    }, [PackageID])

    
    useEffect(() => {
        onPackages();
        onDeliveryTime();
        onDeliveryMethod();
        // onDislikeItem()
        dispatch(getUserWallet())
        onReadMore()
        localStorage.setItem('P_ID',24)
        
    }, [])
   
    // console.log('UserWallet',UserWallet);

    useEffect(() => {
        if(Location.pathname.includes('/package')){
            setPackageID(24)
        }
    }, [])
    
    useEffect(() => {
      
        if(PackageDetails&&packageID!==undefined){
          setSubPackageID('1')
        }

    }, [packageID,PackageDetails]) 

    // useEffect(() => {
      
    //     if(subPackageID){
    //         setVishValue({Meals: '3', Snacks: '2', Soups: '1'})
    //     }
       
    // }, [subPackageID])

    
    const onPackages = () =>{
        var formData = new FormData
        formData.append('lang_id',lang)
        formData.append('show_festival_package',0)

        dispatch(getPackageList(formData))
    }

    const onDeliveryTime =()=> {
        var formData = new FormData
        formData.append('language_id',lang)
        dispatch(getDeliveryTime(formData));
    }

    const onDeliveryMethod =()=> {
        var formData = new FormData
        formData.append('language_id',lang)
        dispatch(getDeliveryMethod(formData));
    }

    // const onDislikeItem =()=> {
    //     var formData = new FormData
    //     formData.append('language_id',1)
    //     dispatch(getDislikeItems(formData));
    // }

    const onReadMore =()=> {
        var formData = new FormData
        formData.append('text_for','general_note_in_profile_page')
        formData.append('lang_id',lang)
        dispatch(getReadMore(formData));
    }
    
    useEffect(() => {
        var formData = new FormData
        formData.append('lang_id',lang)
        formData.append('package_id',packageID)

        if(packageID!==undefined){
            dispatch(getPackageDetails(formData))
        }
    }, [packageID])
    
    const DishValueChange =(e)=>{
        const { name, value } = e.target;

        setVishValue({
        ...dishValue,
        [name]: value,
        });
    }
    // const DislikeChange = (dislike) => {
    //     setDislikeItems([...dislikeItems,dislike])
    // }
    useEffect(() => {
       if(PackageDetails){
        var subPKG = PackageDetails.sub_package_list
        var selectSP = subPKG.filter(data=>data.package_for_id==subPackageID)
        // console.log('selectSP',selectSP);
        setSubPackage(selectSP[0])
       }
    }, [subPackageID,packageID,PackageDetails])
    
    console.log("subPackage", subPackage);
    useEffect(() => {
       if(dishValue&&subPackage){
        var meal = parseInt(dishValue.Meals)
        var snack = parseInt(dishValue.Snacks)
        var soup = parseInt(dishValue.Soups)

        var Combo = subPackage.sub_packages.filter(pack=>pack.total_combo_count.meals==meal&&pack.total_combo_count.snacks==snack&&pack.total_combo_count.soup==soup)
        // console.log('Combo',Combo);
        var PRICE = Combo.length>0?Combo[0].price:'0.000'
        var id = Combo.length>0?Combo[0].sub_package_id:''
        setSub_package_id(id)
        setSUBTOTAL(parseInt(PRICE))
        setFinalPrice(parseInt(PRICE))
       }

    }, [dishValue,subPackage])

    const [Applied, setApplied] = useState(false)
    
    const [disCountTEXT, setdisCountTEXT] = useState('No Discount Yet!')

    const ApplyCoupon = ()=>{

        setApplied(true)
        var formData = new FormData()
        formData.append('user_id',USER_ID)
        formData.append('coupon_code',CouponCode)

        dispatch(getCoupon(formData))
    }
    
    useEffect(() => {
        if(UserWallet){
            var amount = parseInt(UserWallet.wallet_amount)-WalletAmount
            setFinalPrice(finalPrice-amount)
        }
    }, [SUBTOTAL])

    useEffect(() => {
        if(Coupon){
            if(Coupon.discount_type=="percentage"){
                setdisCountTEXT(`Congrats!  You Will get ${Coupon.discount_value}% Discount`)
                var discount = finalPrice*parseInt(Coupon.discount_value)/100
                var NewPrice = finalPrice - discount
                setDISCOUNT(discount)
                setFinalPrice(NewPrice)
            }
        }
    }, [Coupon,SUBTOTAL])

    useEffect(() => {
     if(Coupon==false&&Applied==true){
        setdisCountTEXT('Sorry! Your Coupon Code is Wrong or Expired')
     }
    }, [Applied])

    const [WalletAmount, setWalletAmount] = useState(null)

    useEffect(() => {
        if(UserWallet){
            setWalletAmount(parseInt(UserWallet.wallet_amount))
            // setWalletAmount(450)
        }
    }, [UserWallet])
    
    const OnSpend =()=>{

        // console.log('WalletSpend',WalletSpend>finalPrice?'All Okay':'Not Okay');

        if(WalletAmount==0){
            toast.warning(lang=='1'?"Sorry! Your Wallet Balance is Zero":'آسف! رصيدك في المحفظة هو صفر', {
                position: toast.POSITION.TOP_RIGHT
              });
              return false
        }

        if(WalletSpend>WalletAmount){
            toast.warning(lang=='1'?"Enter Amount Exceeding Wallet Limit!":"أدخل المبلغ الذي يتجاوز حد المحفظة!", {
                position: toast.POSITION.TOP_RIGHT
              });
              return false
        }else if(WalletSpend>finalPrice){
            toast.warning(lang=='1'?"Enter Amount Cannot be Greater than Package Price!":'أدخل المبلغ لا يمكن أن يكون أكبر من سعر الباقة!', {
                position: toast.POSITION.TOP_RIGHT
              });
              return false
        }else{
            var AfterSpend = finalPrice - WalletSpend
            var newWallet = WalletAmount - WalletSpend
            // setSUBTOTAL(AfterSpend)
            setFinalPrice(AfterSpend)
            setWalletAmount(newWallet)

            toast.warning(`You Avail ${WalletSpend} KWD From Wallet`, {
                position: toast.POSITION.TOP_RIGHT
              });
        }
        
    }

    const [POP_UP, setPOP_UP] = useState(false)

    const EditAddress =()=>{

        if(UserAddress){
            setAddress({
                address_type: UserAddress?.address_type,
                directions: UserAddress?.directions,
                street: UserAddress?.street,
                block: UserAddress?.block,
                floor_no: UserAddress?.floor_no,
                flat_no: UserAddress?.flat_no,
                avenue: UserAddress?.avenue,
                house_number: UserAddress?.house_number,
                area_id: UserAddress?.area?.area_id,
                lang: UserAddress?.lang,
                lat: UserAddress?.lat,
            })
            
        }else if(!USER_ID){
            // toast.warning("You Are Not Logged In, Do You Want to Sign Up!", {
            //     position: toast.POSITION.TOP_RIGHT
            //   });
            setPOP_UP(true)
            
        }
    }

    const AddressSubmit =(e)=>{
        e.preventDefault()

        setModalAddress(false)
        
        var formData = new FormData
        formData.append('user_id',localStorage.getItem('user_id'))
        formData.append('lang_id',lang)
        formData.append('address_type',address.address_type)
        formData.append('directions',address.directions)
        formData.append('street',address.street)
        formData.append('block',address.block)
        formData.append('floor_no',address.floor_no)
        formData.append('flat_no',address.flat_no)
        formData.append('avenue',address.avenue)
        formData.append('house_number',address.house_number)
        formData.append('area_id',address.area_id)

        dispatch(addAddress(formData))
    }

    useEffect(() => {
        dispatch(getUserAddress())
        dispatch(getAreaList())
    }, [])
    
    
    const PayNow = (e)=>{
        e.preventDefault()
        if(USER_ID){
            if(!packageID){
                toast.warning(lang=='1'?"Select A Package!":"اختر الباقة!", {
                    position: toast.POSITION.TOP_RIGHT
                  });
                  return false
            }else if(!deliveryTime){
                toast.warning(lang=='1'?"Select Delivery Time!":"حدد وقت التسليم!", {
                    position: toast.POSITION.TOP_RIGHT
                  });
                  return false
            }else if(!subPackageID){
                toast.warning(lang=='1'?"Select A Sub Package!":"حدد حزمة فرعية!", {
                    position: toast.POSITION.TOP_RIGHT
                  });
                  return false
            }else if(!deliveryMethod){
                toast.warning(lang=='1'?"Select Delivery Method!":"حدد طريقة التسليم!", {
                    position: toast.POSITION.TOP_RIGHT
                  });
                  return false
            }else if(!startDate){
                toast.warning(lang=='1'?"Select Package Start Date!":"حدد تاريخ بدء الحزمة!", {
                    position: toast.POSITION.TOP_RIGHT
                  });
                  return false
            }else if(!UserAddress){
                toast.warning(lang=='1'?"Add Delivery Address!":"أضف عنوان التسليم!", {
                    position: toast.POSITION.TOP_RIGHT
                  });
                  return false
            }else if(!paymentType){
                toast.warning(lang=='1'?"Select A Payment Method!":"اختر طريقة الدفع!", {
                    position: toast.POSITION.TOP_RIGHT
                  });
                  return false
            }

            var endDate = new Date(startDate)
            var END_DATE = endDate.setDate(endDate.getDate() + 6)

            var formData = new FormData
            formData.append('delivery_time_id',deliveryTime)
            formData.append('end_date','')
            formData.append('package_for',subPackageID)
            formData.append('package_amount',finalPrice)
            formData.append('promo_code_discount',0.0)
            formData.append('delivery_address_id',UserAddress?.address_id)
            formData.append('package_id',packageID)
            formData.append('consultant_fees',0)
            formData.append('sub_package_id',sub_package_id)
            formData.append('payment_type',paymentType)
            formData.append('delivery_method_id',deliveryMethod)
            formData.append('total_amount',finalPrice)
            formData.append('user_id',localStorage.getItem('user_id'))
            formData.append('app_mode','prod')

            if(WalletSpend!==null){
                formData.append('wallet_id',UserWallet.wallet_id)
                formData.append('wallet_amount',parseInt(UserWallet.wallet_amount)-WalletAmount)
            }else{}

            formData.append('unique_code',endDate.getTime()*32)
            // formData.append('friday_delivery',isFriday)
            formData.append('start_date',startDate)
            // formData.append('dislike_ingredients',dislikeItems.toString())
            var DATA = {
                payment:paymentType,
                totalAmount:finalPrice,
            }
            dispatch(purchasePackage(formData,DATA))

            const tagManagerArgs = {
                gtmId: 'GTM-TCH5F5W',
                dataLayerName:'CheckoutPage_Muscle-Fuel-KW',
                dataLayer:{
                    id: packageID,
                    price: finalPrice,
                    quantity: 1
                },
            }
            
            TagManager.initialize(tagManagerArgs)
        }
        else{
            // toast.warning("You Are Not Logged In, Do You Want to Sign Up!", {
            //     position: toast.POSITION.TOP_RIGHT
            //   });
            setPOP_UP(true)
           
        }

    }
    console.log('dishValue',dishValue.Meals);
    const SET_ID = (id)=>{
        localStorage.setItem('P_ID',id)
    }

    // console.log(packageID);
    console.log('subPackageID',subPackageID);
  return (
    <>
    {/* <Navbar/> */}
    <section className="innerpages">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="package-box">
                        <div className="select-package-carousel">
                            <h3 className="text-center text-transform-none color-black">{lang=='1'?'Select a Package':'اختر الاشتراك المناسب لهدفك'}</h3>
                            <div className="PackageCards">
                                <Slider {...settings} >
                                    {PackageLIST?PackageLIST.map((pack,index)=>(
                                        <div className="swiper-slide">
                                            <div className="package-card">
                                                <input checked={pack.package_master_id==packageID?true:false} onClick={()=>setPackageID(pack.package_master_id)} type="radio" className="styled-checkbox" id={`package${index+1}`} name="packages" />
                                                <label htmlFor={`package${index+1}`} title={pack.package_master_id==24?`${lang=='1'?'Sumbatik':'سومباتيك'}`:pack.package_master_id==26?`${lang=='1'?'Fit':'ملائم'}`:pack.package_master_id==28?`${lang=='1'?'Bulk':'حجم'}`:null}>
                                                    <div className="package-img">
                                                        <Link to='/menu' onClick={()=>SET_ID(pack.package_master_id)} className="button MENU_BTN">{lang=='1'?'Menu':'المنيو'}</Link>	
                                                        <img src={pack.package_master_id==24?"img/sumbatik.jpg":pack.package_master_id==26?"img/FiT.jpg":pack.package_master_id==28?"img/Bulk.jpg":null} className="img-fluid" alt="Sumbatik Package"/>
                                                        <div className="package-head">
                                                           
                                                            <h2>{pack.package_name}</h2><p>{pack.gram_label}</p>
                                                            <div style={{padding:'0px'}} dangerouslySetInnerHTML={{__html:pack.description}} />
                                                            {/* <div><p>Starting from 150 KWD</p></div> */}
                                                            <div className="read-more-select">{/*<a onClick={()=>setModalReadMore(true)} className="button">Read More</a>*/}<span className="button select-btn">{lang=='1'?'Select':'اختر'}</span></div>
                                                        </div>
                                                    </div>
                                                </label>
                                               
                                            </div>
                                        </div>
                                    )):null}
                                </Slider>
                            </div>
                            {/* <div className="package-button-prev swiper-button-prev"></div> */}
                            {/* <div className="package-button-next swiper-button-next"></div> */}
                        </div>
                        <div className="number-of-days">
                            <h3 className="text-center text-transform-none color-black">{lang=='1'?'Select Number of Day':'حدد عدد الأيام'}</h3>
                            <div className="text-center">
                                <ul className="unstyled days-ul d-inline-block">
                                    {PackageDetails?PackageDetails.sub_package_list.map((subPackage,index)=>(
                                        <li>
                                            <input checked={subPackage.package_for_id==subPackageID?true:false} onClick={()=>setSubPackageID(subPackage.package_for_id)} type="radio" className="styled-checkbox" id={`days${subPackage.days}`} name="day" />
                                            <label htmlFor={`days${subPackage.days}`}>
                                                <h2>{subPackage.days}</h2><p>{lang=='1'?'Days':`${subPackage.days==26?'يوم':subPackage.days==1?'يوم واحد':'أيام'}`}</p>
                                            </label>
                                        </li>
                                    )):null}
                                </ul>
                            </div>	
                        </div>
                        <div className="meal-snacks-soup">
                            <h3 className="text-center text-transform-none color-black mt-2 mb-2">{lang=='1'?'Select Number of Food Dishes':'حدد عدد الوجبات'}</h3>
                            <div className="row">
                                <div className="col-12 p-0">
                                <div className="carousel-dishes">
                                    <div className="dishes-container swiper-container" style={{
                                        display:'flex',
                                        justifyContent:'center'
                                    }} >
                                        <div className="swiper-wrapper row">
                                            {PackageDetails?PackageDetails.max_values_as_per_meal_type.filter(data=>data.meal_type_id!==1).map((value,index)=>(
                                                <div className="swiper-slide col-lg-4 col-md-4 col-sm-12 " style={{marginBottom:'1rem',padding:'0 5px'}}>
                                                    <div className="dish-box meal-dish">
                                                        <h2><span>{lang=='1'?'Choose Number of':'اختر عدد'}</span>{value.meal_type_id==2?`${lang=='1'?'Meals':'الوجبات'}`:value.meal_type_id==3?`${lang=='1'?'Snacks':'السناكات'}`:value.meal_type_id==11?`${lang=='1'?'Soups':'الشوربة'}`:''}</h2>
                                                        <ul className="unstyled dish-ul justify-content-between">
                                                           {new Array(value.max_value+1).fill(value.max_value+1).map((subValue,idx)=>(
                                                                <li>
                                                                    <input checked={index==0&&dishValue.Meals==idx?true:index==1&&dishValue.Snacks==idx?true:index==2&&dishValue.Soups==idx?true:false} onChange={DishValueChange} type="radio" className="styled-checkbox" id={`${value.meal_type_id==2?`${lang=='1'?'Meals':'وجبات'}`:value.meal_type_id==3?`${lang=='1'?'Snacks':'السناكات'}`:value.meal_type_id==11?`${lang=='1'?'Soups':'الشوربة'}`:''}${idx}`}  name={value.meal_type_id==2?`${lang=='1'?'Meals':'وجبات'}`:value.meal_type_id==3?`${lang=='1'?'Snacks':'السناكات'}`:value.meal_type_id==11?`${lang=='1'?'Soups':'الشوربة'}`:''} value={idx}/>
                                                                    <label htmlFor={`${value.meal_type_id==2?`${lang=='1'?'Meals':'الوجبات'}`:value.meal_type_id==3?`${lang=='1'?'Snacks':'السناكات'}`:value.meal_type_id==11?`${lang=='1'?'Soups':'الشوربة'}`:''}${idx}`}><strong>{idx}</strong></label>
                                                                </li>
                                                           ))}
                                                        </ul>	
                                                    </div>
                                                </div>
                                            )):null}
                                        </div>
                                    </div>
                                    {/* <div className="dishes-button-prev swiper-button-prev"></div> */}
                                    {/* <div className="dishes-button-next swiper-button-next"></div> */}
                                </div>
                            </div>		
                                <div className="col-g-12 col-md-12 col-sm-12 starting-column">
                                    <div className="text-center starting-box pt-0 border-top-red max-width780">
                                        <div className="summary-div-box">{lang=='1'?'Food Dishes Summary':`يحتوي اشتراكك على`}</div>
                                        <div className="starting-sub">
                                            <h4><strong className="mealVal">{dishValue?dishValue.Meals?dishValue.Meals:0:0}</strong>{lang=='1'?'Meals':'الشوربة'}  + <strong className="SnakcsVal">{dishValue?dishValue.Snacks?dishValue.Snacks:0:0}</strong>{lang=='1'?'Snacks':'سناك'}  + <strong className="SoupVal">{dishValue?dishValue.Soups?dishValue.Soups:0:0}</strong>{lang=='1'?'Soups':'الشوربة'}</h4>
                                            {startDate?<p>{lang=='1'?'You selected starting date is':'حددت تاريخ البدء هو'} <strong>{startDateS}</strong></p>
                                            :null}
                                        </div>
                                        <div className="starting-sub">
                                            <h4 className="pt-0"><span>{SUBTOTAL=='0.000'?'Combination Not Available!':SUBTOTAL.toFixed(3)} {finalPrice=='0.000'?null:`${lang=='1'?'KWD':'د.ك'}`}</span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>	
                        {/* <h3 className="text-transform-none mt-4 text-left value-added-choice"><span>Value added Choice</span></h3>
                        <div className="value-choice">
                            <div className="value-choice-left">
                                <p>Do you want the Friday delivery?</p>
                                <div className="mb-5"> 
                                    <span onClick={()=>setIsFriday('yes')} style={{background:`${isFriday=='yes'?'#C5251C':'#fff'}`,color:`${isFriday=='yes'?'#fff':'#000'}`}} className="button-line mr-3 ISFRYD">Yes</span> 
                                    <span onClick={()=>setIsFriday('no')} style={{background:`${isFriday=='no'?'#C5251C':'#fff'}`,color:`${isFriday=='no'?'#fff':'#000'}`}} className="button-line ISFRYD">No</span>
                                </div>
                            </div>
                            <div className="value-choice-right">
                                <p><strong>Note : </strong></p>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor</p>
                            </div>	
                        </div> */}
                        <div className="carousel">
                            <div className="week-container menu-container">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide WEEK DATE_STL">
                                        <h3 style={{marginRight:'2rem'}}>{lang=='1'?'Package Start Date':'تاريخ بدء الاشتراك'}</h3><input min={disablePastDate()} name='startDate' onChange={(e)=>setStartDate(e.target.value)} type="date" className="PACK_DATE" />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="swiper-button-prev week-button-prev"></div> */}
                            {/* <div className="swiper-button-next week-button-next"></div> */}
                        </div>
                        <div className="row">
                            
                            <div className="col-g-4 col-md-4 col-sm-6 starting-column">
                                <div className="text-center starting-box">
                                    <h4>{lang=='1'?'Select the delivery time':'حدد وقت التسليم'}</h4>
                                    <ul className="unstyled delivery-time" style={{
                                        display:'flex',
                                        justifyContent:'center'
                                    }}>
                                        {DeliveryTime?DeliveryTime.map((time,index)=>(
                                            <li>
                                                <input onClick={()=>setDeliveryTime(time.timeid)} type="radio" className="styled-checkbox" id={time.timeid} name="delivery" />
                                                <label className="TIME_LABLE" htmlFor={time.timeid}>
                                                    <div>{time.timename}</div>
                                                </label>
                                            </li>
                                        )):null}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-g-8 col-md-8 col-sm-10 starting-column">
                                <div className="text-center starting-box">
                                    <h4>{lang=='1'?'Contact on Delivery':'الاتصال عند التسليم'}</h4>
                                    <ul className="unstyled delivery-time">
                                        {DeliveryMethod?DeliveryMethod.map((method,index)=>(
                                        <li>
                                            <input onChange={()=>setDeliveryMethod(method.methodid)} type="radio" className="styled-checkbox" id={'method'+method.methodid} name="deliverymethod" />
                                            <label htmlFor={'method'+method.methodid}>
                                                <div>{method.methodname}</div>
                                            </label>
                                        </li>
                                        )):null}
                                    </ul>
                                </div>
                            </div>
                            {/* <div className="col-g-4 col-md-4 col-sm-6 starting-column">
                                <div className="text-center starting-box">
                                    <h4>Dislikes</h4>
                                    <div className="form-group">
                                        <a className="button" onClick={()=> setModalDislikes(true)}>Choose Dislikes Items</a>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                       {USER_ID? <div className="row">
                            <div  className="col-g-6 col-md-6 col-sm-6 starting-column">
                                <div className="CPN_DIV">
                                    <h3>{lang=='1'?'Coupon Code':'رمز الكوبون'}</h3>
                                    <h4><span>{disCountTEXT}</span></h4>
                                    {Applied && Coupon!== false ?null:<div>
                                    <input onChange={(e)=>setCouponCode(e.target.value)} value={CouponCode} type="text" placeholder={lang=='1'?"Enter Coupon Here":'أدخل الكوبون هنا'}/>
                                    <button onClick={ApplyCoupon} className="button">{lang=='1'?'Apply':'يتقدم'}</button>
                                   </div>}
                                   
                                 
                                </div>
                            </div>
                            <div  className="col-g-6 col-md-6 col-sm-6 starting-column">
                                <div className="WALLET_DIV">
                                    <h3>{lang=='1'?'Wallet Balance':'رصيد المحفظة'}</h3>
                                    <span>
                                        <span class="material-icons-sharp">account_balance_wallet</span>
                                        {UserWallet?parseInt(WalletAmount).toFixed(3):'0.000'} {`${lang=='1'?'KWD':'د.ك'}`}
                                    </span>
                                    <div>
                                        <input min="0" onChange={(e)=>setWalletSpend(e.target.value)} value={WalletSpend} type="number" placeholder={lang=='1'?"Enter Amount":"أدخل المبلغ"}/>
                                        <button onClick={OnSpend} className="button">{lang=='1'?'Spend':'أنفق'}</button>
                                   </div>
                                </div>
                            </div>
                        </div>:null}
                        <div className="row">
                            <div className="col-g-6 col-md-6 col-sm-6 starting-column">
                                <div className="address-sub-div delivery-address" style={{height:'100%'}}>

                                    <div className="POP_UP" style={{display:POP_UP?'flex':'none'}}>
                                        <h4>{lang=='1'?'You Are Not Logged In, Do You Want To Sign UP':'أنت لم تسجل الدخول ، هل تريد التسجيل'}</h4>
                                        <div>
                                            <Link to='/sign-up' className="button">{lang=='1'?'Yes':'نعم'}</Link>
                                            <Link onClick={()=>setPOP_UP(false)} to='#' className="button">{lang=='1'?'No':'رقم'}</Link>
                                        </div>
                                    </div>
                                    
                                    <h3 className="text-left addModelHead">{lang=='1'?'Delivery Address':'عنوان التسليم'} <a onClick={()=>{
                                        EditAddress()
                                        setModalAddress(USER_ID?true:false)
                                    }} className="button change-btn">{UserAddress?`${lang=='1'?'Change':'يتغيرون'}`:`${lang=='1'?'Add':'إضافة'}`}</a></h3>
                                    <div className="profiledata">
                                       {UserAddress? <div className=" ">
                                            <div className="form-group">
                                                <label>{lang=='1'?'Type':'يكتب'}</label><p>{UserAddress?UserAddress?.address_type:null}</p>
                                            </div>
                                            <div className="form-group">
                                                <label>{lang=='1'?'Area':'منطقة'}</label><p>{UserAddress?UserAddress?.area?.area_name:null}</p>
                                            </div>
                                            <div className="form-group">
                                                <label>{lang=='1'?'Block':'حاجز'}</label><p>{UserAddress?UserAddress?.block:null}</p>
                                            </div>
                                            <div className="form-group">
                                                <label>{lang=='1'?'Street':'شارع'} </label><p>{UserAddress?UserAddress?.street:null}</p>
                                            </div>
                                            <div className="form-group">
                                                <label>{lang=='1'?'Judda':'جودا'}</label><p>{UserAddress?UserAddress?.avenue:null}</p>
                                            </div>
                                            <div className="form-group">
                                                <label>{lang=='1'?'Floor No':'رقم الطابق'}</label><p>{UserAddress?UserAddress?.floor_no:null}</p>
                                            </div>
                                            <div className="form-group">
                                                <span style={{color:'#34384e'}}>{lang=='1'?'Flat No':'شقة لا'}</span><p>{UserAddress?UserAddress?.flat_no:'null'}</p>
                                            </div>
                                            <div className="form-group">
                                                <label>{lang=='1'?'House No':'رقم المنزل'}</label><p>{UserAddress?UserAddress?.house_number:null}</p>
                                            </div>
                                            <div className="form-group">
                                                <label>{lang=='1'?'Directions':'الاتجاهات'}</label><p>{UserAddress?UserAddress?.directions:null}</p>
                                            </div>
                                        </div>:null}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 starting-column">
                                <div className="starting-box">
                                    <h4 className="text-center">{lang=='1'?'Payment Method':'طريقة الدفع او السداد'}</h4>
                                    <p className="text-center">{lang=='1'?'The Fridays will be the off days':'يمكن إضافة ملاحظة عند كتابة العنوان وتنشال من صفحة الدفع'}</p>
                                    <div className="payment-method" style={{marginBottom:'1rem'}}>
                                        <div className="payment-detail">
                                            <ul className="unstyled">
                                                <li><input onClick={()=>setPaymentType('knet')} type="radio" className="styled-checkbox" id="knet" name="payment" /><label htmlFor="knet" title="K-Net"><img src="img/k-net.png" alt="k net"/></label><div>K-NET</div></li>
                                                <li><input onClick={()=>setPaymentType('visa')} type="radio" className="styled-checkbox" id="visa" name="payment" /><label htmlFor="visa" title="Credit Card"><img src="img/creditcard.png" alt="Credit Card"/></label><div>Credit Card</div></li>
                                                {/* <li><input onClick={()=>setPaymentType('cash')} type="radio" className="styled-checkbox" id="cash" name="payment" /><label htmlFor="cash" title="Cash on Delivery"><img src="img/cash.png" alt="Cash on Delivery"/></label><div>Cash on Delivery</div></li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    {console.log("SUBTOTAL", SUBTOTAL)}
                                    <p style={{fontSize:'1.3rem',fontWeight:'500'}} className="text-center total-amount">{lang=='1'?'':''}SubTotal: <b>{SUBTOTAL.toFixed(3)}</b> {`${lang=='1'?'KWD':'د.ك'}`}</p>
                                    <p className="text-center total-amount">{lang=='1'?'':''}Wallet Spend: <b>{UserWallet?(parseInt(UserWallet.wallet_amount)-WalletAmount).toFixed(3):'0.000'}</b> {`${lang=='1'?'KWD':'د.ك'}`}</p>
                                    <p className="text-center total-amount">{lang=='1'?'':''}Discount: <b>{DISCOUNT.toFixed(3)}</b> {`${lang=='1'?'KWD':'د.ك'}`}</p>
                                    <p style={{fontSize:'1.6rem',fontWeight:'500'}} className="text-center total-amount">{lang=='1'?'':''}Total: <strong>{finalPrice?finalPrice.toFixed(3):0.000}</strong> {`${lang=='1'?'KWD':'د.ك'}`}</p>
                                    <div className="text-center"><button type="submit" onClick={PayNow} className="button">{lang=='1'?'Pay Now':'ادفع الآن'}</button></div>
                                </div>	
                            </div>
                        </div>	
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer/>

        <div style={{
            display: modalReadMore?'block':'none',
            position:'absolute',
            bottom: '0rem',
            left:'0',
            right:'0',
            margin: '0 auto',
            zIndex:'100',
            background: '#fff',
            width:'40%',
            padding: "2rem",
            borderRadius:'7px',
            boxShadow:'3px 3px 5px grey',
        }}>
            <span style={{
                position:'absolute',
                top:'-10px',
                right:'-10px',
                boxShadow:'3px 3px 5px grey',
                borderRadius:'50%',
                background: '#fff',
                width: '20px',
                height: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
            }} onClick={()=>setModalReadMore(false)}>x</span>
           {ReadMore?(
               <div dangerouslySetInnerHTML={{ __html: ReadMore.description }} />
           ):null}
        </div>

        {/* <div style={{
            display: modalDislikes?'block':'none',
            position:'absolute',
            bottom: '-50rem',
            left:'0',
            right:'0',
            margin: '0 auto',
            zIndex:'100',
            background: '#fff',
            width:'70%',
            height:'70%',
            overflowY:'scroll',
            padding: "2rem",
            borderRadius:'7px',
            boxShadow:'3px 3px 5px grey',
        }}>
            
            <h2 className="anim1 pt-0">Choose Dislikes Items</h2>
            <div className="row">
                <div className="col-12 anim2">
                    <ul className="unstyled checkbox dislikes-items">
                        {DislikeItems?DislikeItems.map((item,index)=>(
                            <li>
                                <input onClick={()=>DislikeChange(item.main_ingredients_id)} type="checkbox" className="styled-checkbox" id={`item${index+1}`} name="dislikes" />
                                <label htmlFor={`item${index+1}`}>
                                    <div>{item.ingredients_name}</div>
                                </label>
                            </li>
                        )):null}
                       
                    </ul>
                </div>
                <div className="col-12 anim3 pt-2"><button onClick={()=> setModalDislikes(false)} className="button" type="submit">Submit</button></div>
            </div>
        </div> */}
        		
        <div className="ModelAddress" style={{
            display: modalAddress?'block':'none',
        }}>
             <span style={{
                position:'absolute',
                top:'-10px',
                right:'-10px',
                boxShadow:'3px 3px 5px grey',
                borderRadius:'50%',
                background: '#fff',
                width: '20px',
                height: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
            }} onClick={()=>setModalAddress(false)}>x</span>
            <h2 className="anim1">{UserAddress?`${lang=='1'?'Change Your Address':'تغيير العنوان الخاص بك'}`:`${lang=='1'?'Add New Address':'أضف عنوان جديد'}`}</h2>
            <form onSubmit={AddressSubmit} className="row">
                <div className="col-12 anim2">
                    <div className="form-group">
                        <ul className="unstyled">
                            <li className="white">
                                <input required checked={address.address_type=='house'?true:false} value='house' name='address_type' onChange={AddressChange} className="styled-checkbox" id="a_house" type="radio" />
                                <label  htmlFor="a_house"><span>{lang=='1'?'House':'منزل'}</span></label>
                            </li>
                            <li className="black">
                                <input required checked={address.address_type=='building'?true:false} value='building' name='address_type' onChange={AddressChange} className="styled-checkbox" id="a_aparment" type="radio" />
                                <label htmlFor="a_aparment"><span>{lang=='1'?'Building':'مبنى'}</span></label>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 anim3">
                    <div className="form-group"><div className="inputbox">
                        <select required value={address.area_id} name='area_id' onChange={AddressChange} className="form-control">
                            <option>-- {lang=='1'?'Area':'منطقة'} --</option>
                            {AreaList?AreaList.map((area,index)=>(
                                <option value={area.main_area_id}>{area.area_name}</option>
                            )):null}
                        </select></div></div>
                </div>	
                <div className="col-lg-6 col-md-6 col-sm-6 anim4">
                    <div className="form-group">
                        <div className="inputbox"><input required value={address.block} name='block' onChange={AddressChange} type="text"  placeholder={lang=='1'?"Block":"حاجز"} className="form-control"/></div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 anim4">
                    <div className="form-group">
                        <div className="inputbox"><input required value={address.street} name='street' onChange={AddressChange} type="text"  placeholder={lang=='1'?"Street":"شارع"} className="form-control"/></div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 anim5">	
                    <div className="form-group">
                        <div className="inputbox"><input required value={address.avenue} name='avenue' onChange={AddressChange} type="text"  placeholder={lang=='1'?"Avenue / Judda":"شارع / جددة"} className="form-control"/></div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 anim5">
                    <div className="form-group">
                        <div className="inputbox"><input required value={address.flat_no} name='flat_no' onChange={AddressChange} type="text"  placeholder={lang=='1'?"Flat No":"شقة لا"} className="form-control"/></div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 anim6">	
                    <div className="form-group">
                        <div className="inputbox"><input required value={address.floor_no} name='floor_no' onChange={AddressChange} type="text"  placeholder={lang=='1'?"Floor No":"الطابق لا"} className="form-control"/></div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 anim6">
                    <div className="form-group">
                        <div className="inputbox"><input required value={address.house_number} name='house_number' onChange={AddressChange} type="text"  placeholder={lang=='1'?"House No":"رقم المنزل"} className="form-control"/></div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 anim6">
                    <div className="form-group">
                        <div className="inputbox"><input required value={address.directions} name='directions' onChange={AddressChange} type="text"  placeholder={lang=='1'?"Directions":"الاتجاهات"} className="form-control"/></div>
                    </div>
                </div>
                <div className="col-12 anim7"><button className="button" type="submit" title="Close">{lang=='1'?'Submit':'يُقدِّم'}</button></div>
            </form>
        </div>
    </>
  );
};

export default Package;
