import React, { useEffect, useState } from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import { FaChevronLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import Input from '../FormInputs/Input'
import { useDispatch } from 'react-redux'
import { toast } from "react-toastify";
import { onRegister } from '../../../Actions/homeActions'
import { MdEmail } from 'react-icons/md'
import { FaUserAlt } from 'react-icons/fa'
import { BsFillKeyFill } from 'react-icons/bs'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { ImMobile2 } from 'react-icons/im'


const SignUpNew = () => {
  const [register, setRegister] = useState({})
  const [lang] = useState(localStorage.getItem("langauge_id"))
  const [currentURLLocation, setCurrentURLLocation] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)


  const dispatch = useDispatch()

  const onSubmit = (e) => {
    e.preventDefault()
    if (!register.first_name) {
      toast.warning(lang == '1' ? "Enter First Name!" : "أدخل الاسم الأول!", {
        position: toast.POSITION.TOP_RIGHT
      });
      return false
    } else if (!register.last_name) {
      toast.warning(lang == '1' ? "Enter Last Name!" : "إدخال اسم آخر!", {
        position: toast.POSITION.TOP_RIGHT
      });
      return false
    } else if (!register.email) {
      toast.warning(lang == '1' ? "Enter Your Email!" : "أدخل بريدك الإلكتروني!", {
        position: toast.POSITION.TOP_RIGHT
      });
      return false
    } else if (!register.mobile_no) {
      toast.warning(lang == '1' ? "Enter Your Mobile No.!" : "أدخل رقم هاتفك المحمول!", {
        position: toast.POSITION.TOP_RIGHT
      });
      return false
    } else if (register.password !== register.CPassword) {
      toast.warning(lang == '1' ? "Password Dose Not Match!" : "جرعة كلمة المرور غير متطابقة!", {
        position: toast.POSITION.TOP_RIGHT
      });
      return false
    }
    var formData = new FormData()
    formData.append('first_name', register.first_name)
    formData.append('last_name', register.last_name)
    formData.append('email', register.email)
    formData.append('mobile_no', register.mobile_no)
    formData.append('referral_code', '')
    formData.append('password', register.password)
    formData.append('device_name', 'android')
    formData.append('device_token', 'frwNMKysQEm')
    formData.append('device_id', 352631555654243)

    dispatch(onRegister(formData))
  }

  useEffect(() => {
    setCurrentURLLocation(window.location.href.split('/')?.[4])
  }, [])

  return (
    <div>
      <div className="innerpages">
        <div className="container">
          <div className='account-container'>
            <nav>
              {currentURLLocation !== "sign-up" && <Link to='/account'>
                <FaChevronLeft style={{ fontSize: '23px' }} />
              </Link>}
              <h4>Sign up</h4>
            </nav>
            <p style={{ fontSize: '1rem' }}>Join us today : Create your account</p>
            <div className='Form_input'>
              <div className="inputField">
                <FaUserAlt />
                <input onChange={e => setRegister({ ...register, [e.target.name]: e.target.value })} name="first_name" type='text' placeholder="First name" />
              </div>
              <div className="inputField">
                <FaUserAlt />
                <input onChange={e => setRegister({ ...register, [e.target.name]: e.target.value })} name="last_name" type='text' placeholder="Last name" />
              </div>
              <div className="inputField">
                <MdEmail />
                <input onChange={e => setRegister({ ...register, [e.target.name]: e.target.value })} name="email" type='email' placeholder="Email"  />
              </div>
              <div className="inputField">
                <ImMobile2 />
                <input onChange={e => setRegister({ ...register, [e.target.name]: e.target.value })} name="mobile_no" type='number' placeholder="Mobile Number"  />
              </div>

              <div className="inputField">
                <BsFillKeyFill />
                <input onChange={e => setRegister({ ...register, [e.target.name]: e.target.value })} name="password" type={showPassword?"text":"password"} placeholder="Password" />
                {showPassword?<AiOutlineEye onClick={()=>setShowPassword(!showPassword)} />:<AiOutlineEyeInvisible onClick={()=>setShowPassword(!showPassword)}/>}
              </div>

              <div className="inputField">
                <BsFillKeyFill />
                <input onChange={e => setRegister({ ...register, [e.target.name]: e.target.value })} name="CPassword" type={showConfirmPassword?"text":"password"} placeholder="Confirm Password"  />
                {showConfirmPassword?<AiOutlineEye onClick={()=>setShowConfirmPassword(!showConfirmPassword)} />:<AiOutlineEyeInvisible onClick={()=>setShowConfirmPassword(!showConfirmPassword)}/>}
              </div>

              <button className="btn-main" onClick={onSubmit}>{lang != 1 ? "يكمل" : "Continue"}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUpNew