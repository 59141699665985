import React from 'react'
import Select from 'react-select';


const SelectInput = ({ options, handleChange, value, placeholder }) => {
  console.log('options',options);
  console.log('value',value);
  return (
    <Select options={options} onChange={handleChange} value={value} placeholder={placeholder}/>
  )
}

export default SelectInput