import React from 'react'
import { BallTriangle } from 'react-loader-spinner'

const Loader = () => {
  
  return (
    <BallTriangle
        height={100}
        width={100}
        radius={5}
        color="#C5251C"
        ariaLabel="ball-triangle-loading"
        wrapperClass={{}}
        wrapperStyle=""
        visible={true}
        />
  )
}

export default Loader