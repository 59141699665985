import React, { useRef } from 'react'

const RangeSlider = ({ onChange , min, max, value }) => {
    
    const minValue = min;
    const maxValue = max;
  
    const handleDragStart = () => {
      document.addEventListener('mousemove', handleDragMove);
      document.addEventListener('mouseup', handleDragEnd);
      document.addEventListener('touchmove', handleDragMove);
      document.addEventListener('touchend', handleDragEnd);
    };
  
    const handleRef = useRef(null);
    const handleDragMove = (event) => {
        const container = document.getElementById('range-selector');
        const containerWidth = container.clientWidth;
        const containerLeft = container.getBoundingClientRect().left;
        const handleWidth = handleRef.current.clientWidth;
        const mouseX = event.pageX;
    
        const range = maxValue - minValue;
        const percentage = ((mouseX - containerLeft) / containerWidth) * 100;
        const clampedPercentage = Math.min(Math.max(percentage, 0), 100);
    
        const newValue = (clampedPercentage / 100) * range + minValue;
        const handlePosition = ((newValue - minValue) / range) * (containerWidth - handleWidth);
    
        onChange(newValue);
        handleRef.current.style.left = `${handlePosition}px`;
      };
  
    const handleDragEnd = () => {
      document.removeEventListener('mousemove', handleDragMove);
      document.removeEventListener('mouseup', handleDragEnd);
      document.removeEventListener('touchmove', handleDragMove);
      document.removeEventListener('touchend', handleDragEnd);
    };

  return (
    <div id="range-selector" className="range-selector" onMouseDown={handleDragStart} onTouchStart={handleDragStart}>
      <div className="range-selector__bar" style={{ width: `${(value / maxValue) * 100}%` }}></div>
      <div className="range-selector__handle" ref={handleRef} style={{ left: `${(value / maxValue) * 100}%` }}></div>
    </div>
  )
}

export default RangeSlider