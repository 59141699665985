import React, { useEffect } from 'react'
import { AiOutlineClose } from 'react-icons/ai';

const CustomModal = props => {
    
    useEffect(() => {
        document.addEventListener('keydown', getKeyEvent, true)
    },[])
    
    const getKeyEvent = (e) => {
        if(e.key === 'Escape'){
            props.onClose(false)
        }
    }
  return (
    <div onClick={() =>props.onClose(false)} className={`custom-modal ${props.status ? 'mount-modal' : 'unmount-modal'}`}>
        <div className="modal-container" onClick={(e) => e.stopPropagation()}>
            <nav>
                <h5>{props.heading}</h5>
                <AiOutlineClose onClick={() =>props.onClose(false)} />
            </nav>
            <div className="body">
                {props.children}
            </div>
            <footer>
                <button className='btn' onClick={() => props.onClose(false)}>Close</button>
                <button className='btn-main'>Send</button>
            </footer>
        </div>
    </div>
  )
}

export default CustomModal