import {
  SET_REGISTER,
  SET_PACKAGE_LIST,
  SET_PACKAGE_DETAILS,
  SET_DELIVERY_TIME,
  SET_DELIVERY_METHOD,
  SET_ADDRESS,
  SET_AREA_LIST,
  SET_REFRESH,
  SET_DISLIKE_ITEMS,
  SET_READ_MORE,
  SET_MEAL_LIST,
  SET_SNACK_LIST,
  SET_SOUP_LIST,
  SET_COUPON,
  SET_WALLET,
  SET_BREAKFAST_LIST,
  SET_PRIVACY_POLICY,
  SET_TERMS_CONDITIONS,
} from "./types";

import Constant from "../Constant";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import axios from "axios";

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("es_token")}`,
    "Content-Type": "multipart/form-data",
  },
};

export const onRegister = (data) => (dispatch) => {
  dispatch({
    type: SET_REFRESH,
    payload: true
  })
  axios
    .post(Constant.getAPI() + `/userregistration`, data, config)
    .then((res) => {
      if (res.data.data) {

        toast.success("Registered Successfully!", {
          position: toast.POSITION.TOP_RIGHT
        });

        localStorage.setItem('user_id', res.data.data.user_id)
        localStorage.setItem('first_name', res.data.data.first_name)
        localStorage.setItem('last_name', res.data.data.last_name)
        localStorage.setItem('email', res.data.data.email)
        localStorage.setItem('mobile_no', res.data.data.mobile_no)
        localStorage.setItem('isLoggedIn', true)

        dispatch({
          type: SET_REFRESH,
          payload: false
        })

        if (localStorage.getItem('middle_login') == 'yes') {
          var formData = new FormData
          formData.append('user_id', localStorage.getItem('user_id'))
          var DATA = formData

          axios
            .post(Constant.getAPI() + `/getuserprofile`, DATA, config)
            .then((res) => {

              if (res.data.data.address == null) {
                console.log('middle_login', res.data.data.address);
                window.location.href = '#/checkout/add-address'
              } else {
                window.location.href = '#/checkout'
              }
            })
            .catch((err) => {
              console.log(err.message);
            });
        } else {
          window.location.href = '#/'
        }

        // window.location.href = '#/'
        // window.location.reload()
      } else if (res.data.error_msg == 'Referral code not found') {
        toast.success('Referral code is Wrong or Expired', {
          position: toast.POSITION.TOP_RIGHT
        });

        dispatch({
          type: SET_REFRESH,
          payload: false
        })
      }
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const onLogin = (data) => (dispatch) => {
  dispatch({
    type: SET_REFRESH,
    payload: true
  })
  axios
    .post(Constant.getAPI() + `/userlogin`, data, config)
    .then((res) => {
      if (res.data.data) {
        console.log("userDta", res.data);
        toast.success("Logged In Successfully!", {
          position: toast.POSITION.TOP_RIGHT
        });

        localStorage.setItem('user_id', res.data.data.user_id)
        localStorage.setItem('first_name', res.data.data.first_name)
        localStorage.setItem('last_name', res.data.data.last_name)
        localStorage.setItem('email', res.data.data.email)
        localStorage.setItem('mobile_no', res.data.data.mobile_no)
        localStorage.setItem('isLoggedIn', true)

        dispatch({
          type: SET_REFRESH,
          payload: false
        })

        if (localStorage.getItem('middle_login') == 'yes') {
          var formData = new FormData
          formData.append('user_id', localStorage.getItem('user_id'))
          var DATA = formData

          axios
            .post(Constant.getAPI() + `/getuserprofile`, DATA, config)
            .then((res) => {

              if (res.data.data.address == null) {
                console.log('middle_login', res.data.data.address);
                window.location.href = '#/checkout/add-address'
              } else {
                window.location.href = '#/checkout'
              }
            })
            .catch((err) => {
              console.log(err.message);
            });
        } else {
          window.location.href = '#/'
        }

        // window.location.reload()
      } else {
        dispatch({
          type: SET_REFRESH,
          payload: false
        })
        toast.success("User Not Exist!", {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const onLogOut = (data) => (dispatch) => {
  localStorage.clear()

  toast.success("Logged Out Successfully!", {
    position: toast.POSITION.TOP_RIGHT
  });
  localStorage.setItem('langauge_id', 1);
  window.location.href = '#/'
};

export const getlngchange = () => dispatch => {
  const lngno = localStorage.getItem('langauge_id');
  if (lngno === '1') {
    console.log('this is the lng', lngno);
    localStorage.setItem('langauge_id', 2);
  } else {
    localStorage.setItem('langauge_id', 1);
    console.log('this is the lng', lngno);
  }
  window.location.reload();
};

// export const getPackageList = (data) => (dispatch)=>{

//   axios
//     .post('http://testv0.musclefuel-kw.com/testv0/ws/packagelistV2', data, config)
//     .then((res) => {
//         console.log("787s8ad7d",res.data.data);
//           if(res.data){
//             dispatch({
//               type:SET_PACKAGE_LIST,
//               payload:res.data.data
//             })

//           }
//     })
//     .catch((err) => {
//       console.log(err.message);
//     });
// };

export const getPackageList = (data) => (dispatch) => {

  axios
    .post(Constant.getAPI() + `/packagetypelist`, data, config)
    .then((res) => {
      console.log("787s8ad7d", res.data.data);
      if (res.data) {
        dispatch({
          type: SET_PACKAGE_LIST,
          payload: res.data.data
        })

      }
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const getPackageDetails = (data) => (dispatch) => {

  axios
    .post(Constant.getAPI() + `/packagelistV3`, data, config)
    .then((res) => {

      if (res.data) {
        // console.log('res.data',res.data.data);
        dispatch({
          type: SET_PACKAGE_DETAILS,
          payload: res?.data?.data?.[0]
        })

      }
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const getDeliveryTime = (data) => (dispatch) => {

  axios
    .post(Constant.getAPI() + `/DeliveryTime`, data, config)
    .then((res) => {

      if (res.data) {
        console.log('res.data', res.data.data);
        dispatch({
          type: SET_DELIVERY_TIME,
          payload: res.data.data
        })

      }
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const getDeliveryMethod = (data) => (dispatch) => {

  axios
    .post(Constant.getAPI() + `/DeliveryMethod`, data, config)
    .then((res) => {

      if (res.data) {
        console.log('res.data', res.data.data);
        dispatch({
          type: SET_DELIVERY_METHOD,
          payload: res.data.data
        })

      }
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const getDislikeItems = (data) => (dispatch) => {
  axios
    .post(Constant.getAPI() + `/ingredientlist`, data, config)
    .then((res) => {

      if (res.data) {
        dispatch({
          type: SET_DISLIKE_ITEMS,
          payload: res.data.data
        })

      }
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const getReadMore = (data) => (dispatch) => {
  axios
    .post(Constant.getAPI() + `/generalnote`, data, config)
    .then((res) => {

      if (res.data) {
        dispatch({
          type: SET_READ_MORE,
          payload: res.data.data
        })

      }
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const getUserAddress = (data) => (dispatch) => {

  var formData = new FormData
  formData.append('user_id', localStorage.getItem('user_id'))
  var DATA = formData

  axios
    .post(Constant.getAPI() + `/getuserprofile`, DATA, config)
    .then((res) => {

      if (res.data) {
        console.log('res.data', res.data.data.address);
        dispatch({
          type: SET_ADDRESS,
          payload: res.data.data.address
        })

      }
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const getAreaList = (data) => (dispatch) => {

  var formData = new FormData
  formData.append('language_id', 1)

  var DATA = formData

  axios
    .post(Constant.getAPI() + `/arealist`, DATA, config)
    .then((res) => {

      if (res.data) {
        var areaList = []
        var areas = res.data.data.map((area) => {
          areaList = areaList.concat(area.areas)
        })
        dispatch({
          type: SET_AREA_LIST,
          payload: areaList
        })
      }
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const addAddress = (comp_place, data) => (dispatch) => {
  // dispatch({
  //   type:SET_REFRESH,
  //   payload:true
  // })  
  toast.warning("Please Wait While Address is Updating", {
    position: toast.POSITION.TOP_RIGHT
  });
  axios
    .post(Constant.getAPI() + `/addaddress`, data, config)
    .then((res) => {

      console.log("8a9d89a8d", res);
      if (res.data.data) {
        toast.success("Address Updated", {
          position: toast.POSITION.TOP_RIGHT
        });
        // dispatch({
        //   type:SET_REFRESH,
        //   payload:false
        // })  
        dispatch(getUserAddress())
        dispatch(getAreaList())
        if(comp_place=='main_address'){
          window.location.href = "#/checkout"
        }else{
          window.location.href = "#/checkout/add-address"
        }
        
      }
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const purchasePackage = (data, payData) => (dispatch) => {
  console.log('payData', data, payData);

  dispatch({
    type: SET_REFRESH,
    payload: true
  })

  axios
    .post(Constant.getAPI() + `/purchasepackage`, data, config)
    .then((res) => {

      if (res.data.data) {
        localStorage.removeItem('pre-selected-package')
        dispatch({
          type: SET_REFRESH,
          payload: false
        })

        // console.log("payData", data, res.data);

        if (payData.walletAmount !== null) {
          if (payData.totalAmount > 0) {
            toast.success("Proceed To Payment", {
              position: toast.POSITION.TOP_RIGHT
            });


            window.open(`${Constant.getPaymentAPI()}payment/indexv1.php?custname=${localStorage.getItem('first_name') + localStorage.getItem('last_name')
              }&custemail=${localStorage.getItem('email')}&phone=${localStorage.getItem('mobile_no')}&totAmount=${payData.totalAmount
              }&productnames=testProduct&order_id_app=${res.data.data.purchase_id}&user_id=${localStorage.getItem('user_id')
              }`, "_blank")


          } else {
            toast.success("Package Puchased Successfully", {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        } else {
          toast.success("Proceed To Payment", {
            position: toast.POSITION.TOP_RIGHT
          });

          console.log('Payment URL', `${Constant.getPaymentAPI()}payment/indexv1.php?custname=${localStorage.getItem('first_name') + localStorage.getItem('last_name')
        }&custemail=${localStorage.getItem('email')}&phone=${localStorage.getItem('mobile_no')}&totAmount=${payData.totalAmount
        }&productnames=testProduct&order_id_app=${res.data.data.purchase_id}&user_id=${localStorage.getItem('user_id')
        }`);

          window.open(`${Constant.getPaymentAPI()}payment/indexv1.php?custname=${localStorage.getItem('first_name') + localStorage.getItem('last_name')
            }&custemail=${localStorage.getItem('email')}&phone=${localStorage.getItem('mobile_no')}&totAmount=${payData.totalAmount
            }&productnames=testProduct&order_id_app=${res.data.data.purchase_id}&user_id=${localStorage.getItem('user_id')
            }`, "_blank")

        }



        // window.location.href = '#/ThankYou'
      } else {
        dispatch({
          type: SET_REFRESH,
          payload: false
        })
        toast.error(`You Already Have a Package`, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const getMealList = (data) => (dispatch) => {

  axios
    .post(Constant.getAPI() + `/meallist`, data, config)
    .then((res) => {

      if (res.data) {
        dispatch({
          type: SET_MEAL_LIST,
          payload: res.data.data,
        })
      }
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const getBreakfastList = (data) => (dispatch) => {

  axios
    .post(Constant.getAPI() + `/meallist`, data, config)
    .then((res) => {

      if (res.data) {
        dispatch({
          type: SET_BREAKFAST_LIST,
          payload: res.data.data,
        })
      }
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const getSnackList = (data) => (dispatch) => {

  axios
    .post(Constant.getAPI() + `/meallist`, data, config)
    .then((res) => {

      if (res.data) {
        dispatch({
          type: SET_SNACK_LIST,
          payload: res.data.data,
        })
      }
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const getSoupList = (data) => (dispatch) => {

  axios
    .post(Constant.getAPI() + `/meallist`, data, config)
    .then((res) => {

      if (res.data) {
        dispatch({
          type: SET_SOUP_LIST,
          payload: res.data.data,
        })
      }
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const getCoupon = (data) => (dispatch) => {

  axios
    .post(Constant.getAPI() + `/verifyCoupon`, data, config)
    .then((res) => {

      if (res.data) {
        dispatch({
          type: SET_COUPON,
          payload: res.data.data,
        })
      } else {
        dispatch({
          type: SET_COUPON,
          payload: 'No DATA Available',
        })
      }
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const getUserWallet = (data) => (dispatch) => {

  var formData = new FormData
  formData.append('user_id', localStorage.getItem('user_id'))
  var DATA = formData

  axios
    .post(Constant.getAPI() + `/getuserprofile`, DATA, config)
    .then((res) => {

      if (res.data) {
        // console.log('res.data',res.data.data.wallet_array);
        dispatch({
          type: SET_WALLET,
          payload: res.data.data.wallet_array
        })

      }
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const FetchPrivacyPolicy = (data) => (dispatch) => {

  var formData = new FormData
  formData.append('user_id', localStorage.getItem('user_id'))
  var DATA = formData

  axios
    .post(Constant.getAPI() + `/privacypolicy/%7B"lang_id":${data.land_Id}%7D`, config)
    .then((res) => {

      if (res.data) {
        // console.log('res.data',res.data.data.wallet_array);
        dispatch({
          type: SET_PRIVACY_POLICY,
          payload: res.data.data
        })

      }
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const FetchTermsAndConditions = (data) => (dispatch) => {

  var formData = new FormData
  formData.append('user_id', localStorage.getItem('user_id'))
  var DATA = formData

  axios
    .post(Constant.getAPI() + `/termsandcondition/%7B"lang_id":${data.land_Id}%7D`, config)
    .then((res) => {

      if (res.data) {
        // console.log('res.data',res.data.data.wallet_array);
        dispatch({
          type: SET_TERMS_CONDITIONS,
          payload: res.data.data
        })

      }
    })
    .catch((err) => {
      console.log(err.message);
    });
};
