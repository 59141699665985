import {React, useState} from 'react'
import { Link } from 'react-router-dom'


const MainBanner = () => {
    const [lang] = useState(localStorage.getItem("langauge_id"))
  return (
    <section class="section banner-main H_SEC_Banner fp-section active fp-table fp-completely" id="section0"
        data-fp-styles={{opacity: '1'}}
        data-anchor="Homepage">
        <div class="fp-tableCell" >
            <div class={lang=='1'?"page-div welcomeContainer":"page-div welcomeContainer FlipBanner"}>
                <div class="page-sub">
                    <div class="container">
                        <div class={lang=='1'?"row":"row Reversing"}>
                            <div class="BannerText">
                                <h2>{lang=='1'?'Healthy Eating,':'الأكل الصحي'}<br /><span>{lang=='1'?'Simplified.':'صار اسهل'}</span></h2>
                                <p>{lang=='1'?<>Get customized, pre-cooked meals developed <br/> by a nutrition expert and delivered daily</>:`احصل على أكل صحي مخصص لهدفك 
ومحسوب السعرات الحرارية ومطبوخ تحت إشراف أخصائيين التغذية لدينا`}</p>
                                <div class="banner-btns">
                                    <Link to="/package" class="button">{lang=='1'?'Subscribe Today':'اشترك الآن'}</Link>
                                    <Link to="/calculate-calorie" class="button">{lang=='1'?'How many Calories do you need?':'كم عدد السعرات الحرارية التي تحتاجها؟'}</Link>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">

                            </div>
                        </div>
                    </div>
                </div>
                <div class="banner-buttons">
                    <div class="container d-flex justo-content-between justify-content-between">
                        <a href="javascript:void(0);">{lang=='1'?'ADJUST CALORIES ON EACH MEAL':'فصل سعراتك  لكل وجبة'}</a>
                        <a href="javascript:void(0);">{lang=='1'?'BASED ON HEALTH GOALS':'اشتراك حسب هدفك'}</a>
                        <a href="javascript:void(0);">{lang=='1'?'HUNDREDS OF MEAL OPTIONS':'المئات من الوجبات'}</a>
			        </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default MainBanner