import {React, useState} from 'react'
import Footer from './Footer'
import Navbar from './Navbar'

const ThankYou = () => {
    const [lang] = useState(localStorage.getItem("langauge_id"))
    const [StartDATE] = useState(localStorage.getItem('startDate'))
  return (
    <>
        {/* <Navbar/> */}
            <section class="innerpages">
                <div className="container">
                <div class="row">
                    <div class="col-12">
                        <h1 class="text-center">{lang=='1'?'Thank You':'شكرا لك'}</h1>
                    </div>
                    <div class="col-g-12 col-md-12 col-sm-12 starting-column">
                        <div class="text-center starting-box pt-0  max-width780">
                            <div class="starting-sub pt-4">
                                <p><strong>{lang=='1'?'Your payment has been successful.':'تم الدفع الخاص بك بنجاح.'}</strong></p>
                                <p>{lang=='1'?'Please check your email for reciept':'يرجى التحقق من بريدك الإلكتروني للاستلام'}.</p>
                                <p>{lang=='1'?'You selected starting date is':'حددت تاريخ البدء هو'} <strong>{StartDATE?StartDATE:`${lang=='1'?'No Date Selected':"لم يتم تحديد التاريخ"}`}</strong></p>
                                <p>{lang=='1'?<>Now you can manage your meals on app and <br/>you can download the app clicking on following link.</>:<>الآن يمكنك إدارة وجباتك على التطبيق و <br/> يمكنك تنزيل التطبيق بالنقر على الرابط التالي.</>} </p>
                                <div class="application">
                                    <a href="javascript:void(0);"><img src="img/apple-store.png" alt="apple store"/></a>
                                    <a href="javascript:void(0);"><img src="img/google-play.png" alt="google play"/></a>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                </div>
            </section>
        <Footer/>
    </>
  )
}

export default ThankYou