import React, { useEffect, useState } from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import { FaChevronLeft } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import MainMapBody from './MainMapBody'
import { useDispatch, useSelector } from 'react-redux'
import { addAddress, getUserAddress } from '../../../Actions/homeActions'

const UpdateGoogleMap = () => {

  const [selectedAddress, setSelectedAddress] = useState()
  const [cordinates, setCordinates] = useState()
  const [buttonToogle, setButtonToogle] = useState(false)
  const [userLocation, setUserLocation] = useState({})

  useEffect(() => {
    if (selectedAddress !== undefined && cordinates !== undefined) {
      setButtonToogle(true)
    }
  }, [selectedAddress, cordinates])

  const navigate = useNavigate()
  const [lang] = useState(localStorage.getItem("langauge_id"))

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUserAddress())
  }, [])

  const UserAddres = useSelector(state => state.home.UserAddress);

  useEffect(() => {
    if (UserAddres !== null && UserAddres !== false) {
      setCordinates({
        lat:Number(UserAddres?.lat),
        lng:Number(UserAddres?.lang)
      })
      setButtonToogle(true)
    }
  }, [UserAddres])


  const onContinueHandler = () => {

    var formData = new FormData
    formData.append('user_id', localStorage.getItem('user_id'))
    formData.append('lang_id', lang)
    formData.append('address_type', 'house')
    formData.append('directions', selectedAddress?.split(',')?.[1])
    formData.append('street', selectedAddress?.split(',')?.[selectedAddress?.split?.length - 1])
    formData.append('block', selectedAddress?.split(',')?.[0])
    formData.append('floor_no', null)
    formData.append('flat_no', null)
    formData.append('avenue', selectedAddress?.split(',')?.[selectedAddress?.split?.length - 2])
    formData.append('house_number', null)
    formData.append('office_number', null)
    formData.append('company_name', null)
    formData.append('appartment_number', null)
    formData.append('mobile_number', null)
    formData.append('area_id', null)
    formData.append('lat', cordinates?.lat)
    formData.append('lang', cordinates?.lng)

    dispatch(addAddress('google_map', formData))
    // navigate('/checkout')
  }

  console.log('buttonToogle', buttonToogle);
  console.log('cordinates', cordinates);


  return (
    <div className='innerpages new-packages'>
      <div className="container">
        <nav style={{ display: 'flex', gap: '.5rem' }}>
          <Link to='/checkout'>
            <FaChevronLeft style={{ fontSize: '23px' }} />
          </Link>
          <h4 style={{ color: '#363636', textTransform: 'capitalize' }}>Delivery Address</h4>
        </nav>
        <div className="map-container">
          <MainMapBody setSelectedAddress={setSelectedAddress} setCordinates={setCordinates} cordinates={cordinates} selectedAddress={selectedAddress} />
        </div>
        <footer>
          <button style={{ marginTop: '20px' }} className={`btn-main ${!buttonToogle ? 'inactive' : ''}`} onClick={buttonToogle ? onContinueHandler : null}>{lang != 1 ? "يكمل" : "Continue"}</button>
        </footer>
      </div>
    </div>
  )
}

export default UpdateGoogleMap