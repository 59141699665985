import React, { useEffect, useRef, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { MdLocationPin } from 'react-icons/md';
import axios from 'axios';
import { TfiDirectionAlt } from 'react-icons/tfi';
import { FaMapPin } from 'react-icons/fa';

const MainMapBody = ({ setSelectedAddress, setCordinates, cordinates,selectedAddress }) => {
  const [defaultProps, setDefaultProps] = useState({
    center: {
      lat: 29.381175,
      lng: 47.990644,
    },
    zoom: 12,
  })

  const mapOptions = {
    mapTypeControl: true,
  };

  const [userLocation, setUserLocation] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const mapRef = useRef(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error('Error getting user location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }, []);

  const handleMapClick = async ({ lat, lng }) => {
    setCordinates({ lat, lng });
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBlsIdt9s88g3iboy-H5ASh0qGT8IGDKXU`
      );
      if (response.data.results.length > 0) {
        setSelectedAddress(response.data.results[0].formatted_address);
      }
    } catch (error) {
      console.error('Error fetching address:', error);
    }
  };

  console.log("selectedAddress", selectedAddress);

  const resetMapToDefault = () => {
    if (mapRef.current) {
      const map = mapRef.current;
      const defaultLatLng = new window.google.maps.LatLng(
        defaultProps.center.lat,
        defaultProps.center.lng
      );
      map.panTo(defaultLatLng);
      setSelectedAddress('');
    }
  };

  const handleMarkerClick = ({ lat, lng }) => {
    setSelectedLocation({ lat, lng });
  };

  const handleInfoWindowClose = () => {
    setSelectedLocation(null);
  };

  const [currentLocation, setCurrentLocation] = useState({})

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          setDefaultProps({...defaultProps, zoom: 15})
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ lat: latitude, lng: longitude });
        },
        error => {
          console.error('Error getting current location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };
  
  return (
    <div style={{ height: '65vh', width: '100%' }} className='main-map'>
      {/* <button onClick={resetMapToDefault}>Reset Map</button> */}
      <span className="span-btn" onClick={getCurrentLocation}>
        <FaMapPin />
        <span>Get my Location</span>
      </span>

      <GoogleMapReact
        key={JSON.stringify(currentLocation)}
        bootstrapURLKeys={{ key: 'AIzaSyBlsIdt9s88g3iboy-H5ASh0qGT8IGDKXU' }}
        defaultCenter={Object.keys(currentLocation).length > 0 ? currentLocation : defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={mapOptions}
        onClick={handleMapClick}
        onGoogleApiLoaded={({ map }) => (mapRef.current = map)}
      >
          {/* {cordinates && (
          
        )} */}
        {cordinates && (
          <div
            lat={cordinates.lat}
            lng={cordinates.lng}
            text="My Marker"
            style={{ color: 'red', fontSize: '20px' }}
            onClick={() => handleMarkerClick(cordinates)}
          >
            <MdLocationPin id="nav-icon" />
            <div lat={cordinates.lat} lng={cordinates.lng} className='selected-info'>
            <div>
              {/* <strong>Address:</strong> */}
              <div style={{color:'rgba(25,25,25,0.9)'}}>{selectedAddress}</div>
            </div>
            <div>
              <a
                href={`https://www.google.com/maps?q=${cordinates.lat},${cordinates.lng}`}
                target="_blank"
                rel="noopener noreferrer"
                className='direction'
              >
                <TfiDirectionAlt id='ic'/>
                <span>Directions</span>
                
              </a>
            </div>
          </div>
          </div>
        )}

        {/* InfoWindow */}
      
      </GoogleMapReact>
    </div>
  );
};

export default MainMapBody;
