module.exports = {
    getAPI: function () {
      // return "https://testv0.musclefuel-kw.com/ws"
      return "https://musclefuel-kw.com/musclefuel/ws"
    },

    getPaymentAPI: function () {
            return "https://musclefuel-kw.com/musclefuel/"
          }
  };


