import React, { useEffect, useState } from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import { FaChevronLeft } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'

const KnowActivity = () => {

    const navigate = useNavigate()
    const [userSteps, setUserSteps] = useState('')
    const [BMR, setBMR] = useState(null)

    const userData = JSON.parse(localStorage.getItem('calculate-calories'))
    const [lang] = useState(localStorage.getItem("langauge_id"))

    console.log("activityuserData", userData);

    function calculateAge(dateString) {
        const today = new Date();
        const birthDate = new Date(dateString);

        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();

        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    }

    const calculateBMR = () => {
        let weight = userData?.userWeight;
        let height = userData?.userHeight;
        let age = calculateAge(userData?.userBirthday);
        let result = null

        if (userData?.userGender === "male") {
            result = 10 * weight + 6.25 * height - 5 * age + 5
            return setBMR(result.toFixed(2))
        } else if (userData?.userGender === "female") {
            result = 10 * weight + 6.25 * height - 5 * age - 161
            return setBMR(result.toFixed(2))
        }
    }

    useEffect(() => {
        if (BMR === null) {
            calculateBMR()
        }
    }, [])

    const onContinueClickHandler = () => {
        // getTotalCalories()
        if (userSteps !== '' && BMR !== null) {
            navigate('/calculate-calorie/know-goal')
            const payload = { ...userData, userSteps, BMR }
            localStorage.setItem('calculate-calories', JSON.stringify(payload))
        }
    }

    return (
        <section className="innerpages">
            <div className="container">
                <div className='account-container'>
                    <nav className={`${lang != 1 ? 'arabic' : ''}`}>
                        <Link to='/calculate-calorie/know-weight'>
                            <FaChevronLeft style={{ fontSize: '23px' }} />

                        </Link>
                        <h4>{lang != 1 ? "كيف هو مستوى نشاطك؟" : "What is your activity level?"}</h4>
                    </nav>
                </div>
                <div className="form-justify">
                    <section className="section-count" style={{marginBottom:'30px'}}>
                        <div className="flex-row">

                            <div className={`package-card-new ${userSteps === 'notActive' ? 'active' : ''}`} onClick={() => setUserSteps('notActive')}>
                                <div className={`top-head ${lang != 1 ? 'arabic' : ''}`}>
                                    <h2 className='simple'>{lang != 1 ? "أقل من" : "Less than"} 2500 {lang != 1 ? "خطوات" : "Steps"}</h2>
                                </div>
                            </div>

                            <div className={`package-card-new ${userSteps === 'low' ? 'active' : ''}`} onClick={() => setUserSteps('low')}>
                                <div className={`top-head ${lang != 1 ? 'arabic' : ''}`}>
                                    <h2 className='simple'>2500 - 4999 {lang != 1 ? "خطوات" : "Steps"}</h2>
                                </div>
                            </div>

                            <div className={`package-card-new ${userSteps === 'moderate' ? 'active' : ''}`} onClick={() => setUserSteps('moderate')}>
                                <div className={`top-head ${lang != 1 ? 'arabic' : ''}`}>
                                    <h2 className='simple'>5000 - 7499 {lang != 1 ? "خطوات" : "Steps"}</h2>
                                </div>
                            </div>

                            <div className={`package-card-new ${userSteps === 'veryActive' ? 'active' : ''}`} onClick={() => setUserSteps('veryActive')}>
                                <div className={`top-head ${lang != 1 ? 'arabic' : ''}`}>
                                    <h2 className='simple'>7500 - 9999 {lang != 1 ? "خطوات" : "Steps"}</h2>
                                </div>
                            </div>

                            <div className={`package-card-new ${userSteps === 'extraActive' ? 'active' : ''}`} onClick={() => setUserSteps('extraActive')}>
                                <div className={`top-head ${lang != 1 ? 'arabic' : ''}`}>
                                    <h2 className='simple'>{lang != 1 ? "أكثر من" : "More than"} 10,000 {lang != 1 ? "خطوات" : "Steps"}</h2>
                                </div>
                            </div>

                        </div>
                        {/* <h2 className='bmr-tag'>Your BMR is : <span>{BMR !== null ? BMR : 'No Data Yet'}</span></h2> */}
                    </section>
                    <button className="btn-main" onClick={onContinueClickHandler} >{lang != 1 ? "يكمل" : "Continue"}</button>

                </div>
            </div>
        </section>
    )
}

export default KnowActivity