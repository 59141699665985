import {React, useState, useEffect} from "react";
import Navbar from '../common/Navbar'
import Footer from '../common/Footer'
import {getBreakfastList, getMealList, getPackageList, getSnackList, getSoupList } from "../Actions/homeActions";
import { useDispatch, useSelector } from "react-redux";
import { BsFilter } from 'react-icons/bs'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loader from "./Loader/Loader";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const Menu = () => {

    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const [dishImgUrl, setDishImgUrl] = useState('')

    const onSeeMeal=(dish)=>{
        setDishImgUrl(dish?.meal_image?.url)
        onOpenModal()
    }


    const [lang] = useState(localStorage.getItem("langauge_id")||"1")

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        className:'MenuSlider',
        responsive:[
            {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
                }
            },
        ]
    };

    const [search, setSearch] = useState('')

    const dispatch = useDispatch()

    const MealsList = useSelector(state => state.home.MealsList);
    const SnacksList = useSelector(state => state.home.SnacksList);
    const SoupsList = useSelector(state => state.home.SoupsList);
    const BreakfastList = useSelector(state => state.home.BreakfastList);
    const packageList = useSelector(state => state.home.PackageList)

    const [newPackageList, setNewPackageList] = useState([])

    useEffect(() => {
        if(packageList?.length>0){
            let packData = packageList?.map((pack,idx) => {
                return{
                    package_name: lang == '1' ? `${pack?.package_name} ${pack?.gram_label}` : pack?.package_master_id==24?`السمبتيك 100 جم بروتين`: pack?.package_master_id==26?`الفت 150 جم بروتين`:pack?.package_master_id==28?`الضخامة 200 جم بروتين`:'',
                    description: pack?.description,
                    festival_affect: pack?.festival_affect,
                    friday_charge_type: pack?.friday_charge_type,
                    friday_charge_value: pack?.friday_charge_value,
                    gram_label: pack?.gram_label,
                    img_url: pack?.img_url,
                    package_grams: pack?.package_grams,
                    package_master_id: pack?.package_master_id,
                    package_type: pack?.package_type,
                }
            })
            setNewPackageList(packData)
        }
    },[packageList])

    console.log('newPackageList',newPackageList);
    console.log('packageList',packageList);

    const [Day, setDay] = useState('')
    const [Week, setWeek] = useState('1')
    const [tab, setTab] = useState(1)

    useEffect(() => {
        var formData = new FormData
        formData.append('lang_id',lang)
        formData.append('show_festival_package',0)
        dispatch(getPackageList(formData))
    },[])

    const weeks = [
        {value: 'Saturday', name: lang=='1'?'Saturday':'السبت'},
        {value: 'Sunday', name: lang=='1'?'Sunday':'الأحد'},
        {value: 'Monday', name: lang=='1'?'Monday':'الأثنين'},
        {value: 'Tuesday', name: lang=='1'?'Tuesday':'الثلاثاء'},
        {value: 'Wednesday', name: lang=='1'?'Wednesday':'الأربعاء'},
        {value: 'Thursday', name: lang=='1'?'Thursday':'الخميس'},
        // {value: 6, name: 'Friday'},
    ] 

    const [showFilter, setShowFilter] = useState(false)
    const [searchFiltered, setSearchFiltered] = useState([])
    const [FinalDishArr, setFinalDishArr] = useState([])
    const [packageID, setPackageID] = useState(24)
    const [selectedPrtType, setSelectedPrtType] = useState('')

    const onTabChange = (val) => {
        setTab(val)
        setSearchFiltered([])
    }

    const [dishes, setDishes] = useState([])

    useEffect(() => {
        if(tab===1 && BreakfastList){
            setSearchFiltered(BreakfastList)
            setDishes(BreakfastList)
        }        
    },[BreakfastList])
    
    useEffect(() => {
        if(tab===1 && BreakfastList){
            let filtered = BreakfastList?.filter(meal => {
                if(search == ''  ){
                    return meal;
                } else if(meal?.meal_name?.toLowerCase().includes(search?.toLowerCase())){
                    return meal
                }
                })
                setSearchFiltered(filtered)
                setSelectedPrtType('')
        }
        if(tab===2 && MealsList){
            let filtered = MealsList?.filter(meal => {
                if(search == ''  ){
                    return meal;
                } else if(meal?.meal_name?.toLowerCase().includes(search?.toLowerCase())){
                    return meal
                }
                })
                setSearchFiltered(filtered)
                setSelectedPrtType('')
        }
        if(tab===3 && SnacksList){
            let filtered = SnacksList?.filter(meal => {
                if(search == ''  ){
                    return meal;
                } else if(meal?.meal_name?.toLowerCase().includes(search?.toLowerCase())){
                    return meal
                }
                })
                setSearchFiltered(filtered)
                setSelectedPrtType('')
        }
        if(tab===4 && SoupsList){
            let filtered = SoupsList?.filter(meal => {
                if(search == ''  ){
                    return meal;
                } else if(meal?.meal_name?.toLowerCase().includes(search?.toLowerCase())){
                    return meal
                }
                })
                setSearchFiltered(filtered)
                setSelectedPrtType('')
        }
       
    },[search, tab,BreakfastList,MealsList,SnacksList,SoupsList])
    

    const onPackageChange = (ev) =>{
        setPackageID(ev.target.value)
        if(tab ===1){
            OnBreakfast()
        } else if (tab === 2){
            OnMeals()
        } else if (tab === 3){
            OnSnacks()
        } else if (tab === 4){
            OnSoups()
        }
    }

    useEffect(() => {
        OnBreakfast()
        OnMeals()
        OnSnacks()
        OnSoups()
    }, [Day,Week,packageID])

    const onDayChnage=(e)=>{
        setDay(e.target.value)
    }

    const OnBreakfast = () =>{
        var formData = new FormData()
    
        formData.append('lang_id',lang)
        formData.append('package_id',packageID)
        formData.append('mealtype_id',1)
        // formData.append('days_master_id',Day)
        // formData.append('week_id',Week)

     dispatch(getBreakfastList(formData))
    }

    const OnMeals = () =>{
        var formData = new FormData()
    
        formData.append('lang_id',lang)
        formData.append('package_id',packageID)
        formData.append('mealtype_id',2)
        // formData.append('days_master_id',Day)
        // formData.append('week_id',Week)

     dispatch(getMealList(formData))
    }

    const OnSnacks = () =>{
        var formData = new FormData()
    
        formData.append('lang_id',lang)
        formData.append('package_id',packageID)
        formData.append('mealtype_id',3)
        // formData.append('days_master_id',Day)
        // formData.append('week_id',Week)

     dispatch(getSnackList(formData))
    }

    const OnSoups = () =>{
        var formData = new FormData()
    
        formData.append('lang_id',lang)
        formData.append('package_id',packageID)
        formData.append('mealtype_id',11)
        // formData.append('days_master_id',Day)
        // formData.append('week_id',Week)

     dispatch(getSoupList(formData))
    }

    const clearSearch = () => {
        setSearch('')
    }
    
    const clearDays = () => {
            setDay('')
    }
    
    const getCaloriesValue = (carb) => {
        var divide_p = packageID==24?100:packageID==26?150:packageID==28?200:100
        var divide_C = packageID==24?200:packageID==26?200:packageID==28?200:200

        const portineCal_multiplier = divide_p/Number(carb?.nutrition_list_array[0]?.nutrition_value);
        const carbsCal_multiplier = divide_C/Number(carb?.nutrition_list_array[1]?.nutrition_value);

        var from = Number(carb.nutrition_list_array[0]?.nutrition_details?.calory)+Number(carb.nutrition_list_array[1]?.nutrition_details?.calory)
        var to = (Number(carb.nutrition_list_array[0]?.nutrition_details?.calory)*portineCal_multiplier) + (Number(carb.nutrition_list_array[1]?.nutrition_details?.calory)*carbsCal_multiplier)

        var text = lang=='1'?`Customizable from ${from} to ${to} Kcal`:`
        تتحكم بسعراتها  من
        ${from} إلى ${to}سعرة حرارية`
        return text
    }

    const getDaysSequence=(DayArray)=>{
        var text = ``

        var Days = DayArray?.map((obj)=>obj?.name)
        var Final_Unique_arr = [...new Set(Days)]

        if(Final_Unique_arr.length==1){
            text = ` ${Final_Unique_arr[0]}`
        }else if(Final_Unique_arr.length==2){
            text = ` ${Final_Unique_arr[0]} & ${Final_Unique_arr[1]}`
        }else if(Final_Unique_arr.length>2){
            text = Final_Unique_arr.map((day,idx)=>{
                return ` ${Final_Unique_arr.length-1==idx?'&':idx==0?'':','} ${day}`
            })
        }

        return text
    }


    const [protineType, setProtineType] = useState([])

    useEffect(() => {
        if(tab===1){
            setDishes(BreakfastList)
        } else if(tab===2){
            setDishes(MealsList)
        } else if(tab===3){
            setDishes(SnacksList)
        } else if(tab===4){
            setDishes(SoupsList)
        }
        const types = dishes?.map((dt => { 
            return { 
                protineType: dt?.product_tags?.products_tag_title,
                iconImage: dt?.product_tags?.products_tag_icon?.url,
                protineId: Number(dt?.product_tags?.products_tag_main_id) }
        }))
        const key = 'protineType'
        setProtineType([...new Map(types?.map(item => [item[key], item])).values()])
    },[searchFiltered,tab])

    const onProtineTypeChange = (e) => {
        setSelectedPrtType(e.target.value)
    }

    useEffect(() => {
        if(searchFiltered.length>0){
            if(selectedPrtType!==''){
                var dishes = searchFiltered?.filter(dt => dt?.product_tags?.products_tag_main_id === selectedPrtType)
                setFinalDishArr(dishes)
            }else{
                setFinalDishArr(searchFiltered)
            }

            if(Day!==''){
                var dishes = searchFiltered?.filter(dt => dt?.meal_availabilty?.map(opt=>opt?.name).includes(Day))
                setFinalDishArr(dishes)
            }else{
                setFinalDishArr(searchFiltered)
            }

            if(selectedPrtType&&Day){
                var dishes = searchFiltered?.filter(dt => dt?.meal_availabilty?.map(opt=>opt?.name).includes(Day)&&dt?.product_tags?.products_tag_main_id === selectedPrtType)
                setFinalDishArr(dishes)
            }
        }
    },[searchFiltered, selectedPrtType,Day])


    const clearProtine = () => {
        setSelectedPrtType('') 
    }

    const getMealType = (value) => {
        if(value === 'Veg'){
            return lang === '1'? 'Veg' : 'نباتية '
        } 
        if(value === 'Chicken'){
            return lang === '1'? 'Chicken' : 'دجاج '
        } 
        if(value === 'Beef'){
            return lang === '1'? 'Beef' : 'لحم '
        } 
        if(value === 'Seafood' ){
            return lang === '1'? 'Seafood' : 'سي فود '
        } 
        if(value === 'fish'){
            return lang === '1'? 'Fish' : 'سمكة'
        } 
        if(value === 'Mutton'){
            return lang === '1'? 'Mutton' : 'لحم الضأن'
        } 
    }
    console.log('FinalDishArr',FinalDishArr);
  return (
    <>
     {/* <Navbar/> */}
       <section className="innerpages">
            <div class="container">
                <div class="row">
                    {/* <!--<div class="col-12">
                        <h1 class="text-center">Sumbatik</h1>
                    </div>--> */}
                    <div class="col-lg-12 col-md-12 col-sm-12" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <h4 className="menu_main_heading">{lang === '1' ? 'Healthy Eating Has Never Been Easier with Our 300 Meal Menu'
                                        : 'انسى الملل مع 300 صنف صحي يلبي جميع الأذواق'}</h4>
                        <div className="row-2" style={{display:'fl', margin: '2.4rem 0', flexDirection: lang !== '1' && 'row-reverse', justifyContent:'space-between', alignItems: 'center'}}>
                            <div className="flex">
                                <select onChange={onPackageChange} style={{textAlign: lang === '1' ? 'left' : 'right'}}>
                                    {/* <option value=""> {lang=='1'? 'Package Type' : 'نوع الاشتراك '}</option> */}
                                    {
                                        newPackageList?.map((pack, indx) => <option style={{textAlign: lang === '1' ? 'left' : 'right'}} key={indx} value={pack?.package_master_id}>{pack?.package_name}</option>)
                                    }
                                </select>
                                <div className="drop_btn" onClick={() => setShowFilter(!showFilter)} > 
                                    <BsFilter id="filter_icon"/> {lang=='1'? 'Filter' : 'فلتر'}
                                </div>
                                
                            </div>
                            <div style={{display:'flex',justifyContent:'center',flexDirection: lang !== '1' && 'row-reverse', gap: '0.7rem'}}>
                                <div className={tab === 1 ? 'button' : 'inactive'} onClick={()=>onTabChange(1)}>{lang=='1'?'Breakfast':'الإفطار '}</div>
                                <div className={tab === 2 ? 'button' : 'inactive'} onClick={()=>onTabChange(2)}>{lang=='1'?'Main Meals':' الوجبات الرئيسية '}</div>
                                <div className={tab === 3 ? 'button' : 'inactive'} onClick={()=>onTabChange(3)}>{lang=='1'?'Snacks':'سناكات'}</div>
                                <div className={tab === 4 ? 'button' : 'inactive'} onClick={()=>onTabChange(4)}>{lang=='1'?'Soups':'شوربات'}</div>
                            </div>
                            <div className="drop_btn hide" onClick={() => setShowFilter(!showFilter)} > 
                                <BsFilter id="filter_icon"/> {lang=='1'? 'Filter' : 'فلتر'}
                            </div>
                        </div>
                        {showFilter && <div className="row filter">
                            <div>
                                <nav>
                                    <span>{lang=='1'? 'Search Name' : 'بحث'}</span>
                                    <span className="red_heading" onClick={clearSearch}>{lang=='1'? 'Clear' : 'إزالة'}</span>
                                </nav>
                                <input className={`${lang=='1'? 'english' : 'arabic'}`} type="text" placeholder={lang=='1'? 'Search any Dish here..' : 'ابحث عن اي وجبة هنا'} value={search} onChange={(e) => setSearch(e.target.value)}/>
                            </div>
                            <div>
                                <nav>
                                    <span>{lang=='1'? 'Days' : 'الأيام '}</span>
                                    <span className="red_heading" onClick={clearDays}>{lang=='1'? 'Clear' : 'إزالة'}</span>
                                </nav>
                                <select className={`${lang=='1'? 'english' : 'arabic'}`} onChange={onDayChnage} name="weekday" value={Day}>
                                    <option value=''>{lang=='1'? 'Select Day' : ' اختر اليوم '}</option>
                                    {
                                        weeks?.map((week, indx) => 
                                            <option key={indx} value={week.value}>{week.name}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div>
                                <nav>
                                    <span>{lang=='1'? 'Protien Type' :'نوع مصدر البروتين'}</span>
                                    <span className="red_heading" onClick={clearProtine}>{lang=='1'? 'Clear' : 'إزالة'}</span>
                                </nav>
                                <select onChange={onProtineTypeChange} value={selectedPrtType}>
                                    <option value="">- {lang=='1'?'Select':'اختر مصدر البروتين '} -</option>
                                    {
                                        protineType?.map((name, indx) => name?.protineType!==undefined && <option key={indx} value={name?.protineId} style={{backgroundImage:name?.iconImage}}>{name?.protineType}</option>)
                                    }
                                </select>
                            </div>
                        </div> }
                        <div className="parent_center">
                            {FinalDishArr?.length ? 
                                <div className="cards_container">
                                    {FinalDishArr?.map((card, indx) => 
                                        <div className="menu_card" key={indx}>
                                            <div className="img" onClick={()=>onSeeMeal(card)}>
                                                <img src={card?.meal_image?.url||`/img/muscle-fuel.svg`} alt=""/>
                                            </div>
                                            <div className="container-card">
                                                <span className="red_heading">{getMealType(card?.product_tags?.products_tag_title)}</span>
                                                <h2>{card?.meal_name}</h2>
                                                {card?.display_prot_carbs ? <p className="card_mid">
                                                    {getCaloriesValue(card)}
                                                </p> :<p className="card_mid">
                                                {lang ==='1' ? `Total Calories ${card?.default_nutrition_list_array?.calory} Kcal` : <div className="specialInfo"><p>سعرات حراريه</p> <p>{card?.default_nutrition_list_array?.calory}</p></div>}
                                                </p>}
                                                <h6>{lang ==='1' ? "Every" : "كل"}
                                                {getDaysSequence(card?.meal_availabilty)} 
                                                </h6>
                                            </div>
                                        </div>
                                    )}
                                </div> :
                            <Loader /> }
                        </div>

                        <Modal open={open} onClose={onCloseModal} center>
                            <div className="dishImage">
                                <img  src={dishImgUrl} alt="" />
                            </div>
                        </Modal>
                                {/* <div className="menu_card">
                                    <div className="img"></div>
                                    <div className="container-card">
                                        <span className="red_heading">Beej</span>
                                        <p>Beef Strong</p>
                                        <span className="card_mid">Customizable from 250 to 400 Kcal</span>
                                        <h6>Every Sunday & Thursday</h6>
                                    </div>
                                </div> */}

                        {/* <div class="text-center WEEK_DAYS">
                            <a onClick={()=>setWeek('1')} href="javascript:void(0);" className={Week=='1'?"Week_active":''}>Week 1</a>
                            <a onClick={()=>setWeek('2')} href="javascript:void(0);" className={Week=='2'?"Week_active":''}>Week 2</a>
                        </div> */}
                            {/* <div class="carousel mb-3" style={{display:'flex',justifyContent:'center'}}>
                                <div class="week-container menu-container">
                                <div class="swiper-wrapper" id="swiper-wrapper-8c6687c48deb9a54" aria-live="polite">
                                    <div class="swiper-slide" role="group" aria-label="1 / 6" style={{width: '176.667px', marginRight: '10px'}}>
                                        <input onChange={onDayChnage} type="radio" class="styled-checkbox" id="sat" name="weekday" checked={Day=='6'?true:false}  value="6"/>
                                        <label for="sat">{lang=='1'?'Sat':'السبت'}</label>
                                    </div>
                                    <div class="swiper-slide" role="group" aria-label="2 / 6"  style={{width: '176.667px', marginRight: '10px'}}>
                                        <input onChange={onDayChnage} type="radio" class="styled-checkbox" id="sun" name="weekday" checked={Day=='7'?true:false} value="7"/>
                                        <label for="sun">{lang=='1'?'Sun':'الأحد'}</label>
                                    </div>								
                                    <div class="swiper-slide" role="group" aria-label="3 / 6"  style={{width: '176.667px', marginRight: '10px'}}>
                                        <input onChange={onDayChnage} type="radio" class="styled-checkbox" id="mon" name="weekday" checked={Day=='1'?true:false} value="1"/>
                                        <label for="mon">{lang=='1'?'Mon':'الأثنين'}</label>
                                    </div>
                                    <div class="swiper-slide" role="group" aria-label="4 / 6"  style={{width: '176.667px', marginRight: '10px'}}>
                                        <input onChange={onDayChnage} type="radio" class="styled-checkbox" id="tue" name="weekday" checked={Day=='2'?true:false} value="2"/>
                                        <label for="tue">{lang=='1'?'Tue':'الثلاثاء'}</label>
                                    </div>								
                                    <div class="swiper-slide" role="group" aria-label="5 / 6"  style={{width: '176.667px', marginRight: '10px'}}>
                                        <input onChange={onDayChnage} type="radio" class="styled-checkbox" id="wed" name="weekday" checked={Day=='3'?true:false} value="3"/>
                                        <label for="wed">{lang=='1'?'Wed':'الأربعاء'}</label>
                                    </div>
                                    <div class="swiper-slide" role="group" aria-label="6 / 6"  style={{width: '176.667px', marginRight: '10px'}}>
                                        <input onChange={onDayChnage} type="radio" class="styled-checkbox" id="thu" name="weekday" checked={Day=='4'?true:false} value="4"/>
                                        <label for="thu">{lang=='1'?'Thu':'الخميس'}</label>
                                    </div>
                                </div>
                                </div>
                            </div> */}
                            {/* <div class="row"> */}

                                {/* <div class="col-lg-12 col-md-12 col-sm-12 dish-title TITLE_DISH">
                                    <h1 className={tab===1?'h1Color':''} onClick={()=>setTab(1)}>{lang=='1'?'BREAKFAST':'وجبة افطار'}</h1>
                                    <h1 className={tab===2?'h1Color':''} onClick={()=>setTab(2)}>{lang=='1'?'MEALS':'وجبات'}</h1>
                                    <h1 className={tab===3?'h1Color':''}  onClick={()=>setTab(3)}>{lang=='1'?'SNACKS':'سناكات'}</h1>
                                    <h1 className={tab===4?'h1Color':''} onClick={()=>setTab(4)}>{lang=='1'?'SOUPS':'شوربات'}</h1>
                                </div> */}

                                {/* {BreakfastList&&tab==1?BreakfastList.map((meal,index)=>(
                                    <div class="col-lg-3 col-md-3 col-sm-3 dish-column">
                                        <div class="menu-dish-box MEAL_CARD">
                                            <div className="image">
                                                <img src={meal?.meal_image?.url} alt="" />
                                            </div>
                                            <div className="mealInfo">
                                                <h5>{meal?.meal_name}</h5>
                                            </div>
                                        </div>	
                                    </div>
                                )):null} */}

                                {/* <div class="col-lg-12 col-md-12 col-sm-12 dish-title"><h1>MEALS</h1></div> */}
                                {/* {MealsList&&tab==2?MealsList.map((meal,index)=>(
                                    <div class="col-lg-3 col-md-3 col-sm-3 dish-column">
                                        <div class="menu-dish-box MEAL_CARD">
                                            <div className="image">
                                                <img src={meal.meal_image.url} alt="" />
                                            </div>
                                            <div className="mealInfo">
                                                <h5>{meal.meal_name}</h5>
                                            </div>
                                        </div>	
                                    </div>
                                )):null} */}
                                
                                
                                {/* <div class="col-lg-12 col-md-12 col-sm-12 dish-title"><h1>SNACKS</h1></div> */}
                                    {/* {SnacksList&&tab==3?SnacksList.map((soup,index)=>(
                                        <div class="col-lg-3 col-md-3 col-sm-3 dish-column">
                                            <div class="menu-dish-box MEAL_CARD">
                                                <div className="image">
                                                    <img src={soup.meal_image.url} alt="" />
                                                </div>
                                                <div className="mealInfo">
                                                    <h5>{soup.meal_name}</h5>
                                                </div>
                                            </div>	
                                        </div>
                                    )):null} */}
                             
                                
                                {/* <div class="col-lg-12 col-md-12 col-sm-12 dish-title"><h1>SOUPS</h1></div> */}
                                    {/* {SoupsList&&tab==4?SoupsList.map((soup,index)=>(
                                        <div class="col-lg-3 col-md-3 col-sm-3 dish-column">
                                            <div class="menu-dish-box MEAL_CARD">
                                                <div className="image">
                                                    <img src={soup.meal_image.url} alt="" />
                                                </div>
                                                <div className="mealInfo">
                                                    <h5>{soup.meal_name}</h5>
                                                </div>
                                            </div>	
                                        </div>
                                    )):null} */}
                                    
                        {/* </div> */}
                    </div>
                </div>
            </div>
       </section>
        <Footer/>
        <div id="lunchDishOne" class="popup-hidden modelbox dish-popup animated-modal">
            <h2 class="anim1 pt-0">Meal Title One</h2>
            <div class="package-dtl">
                <h3 class="pt-0 anim2">Ingredients</h3>
                <ul class="ingredients-div anim3">
                    <li>garlic</li>
                    <li>onion</li>			
                    <li>tomato</li>
                    <li>bell pepper</li>
                    <li>carrot</li>
                    <li>scallion</li>
                    <li>potato</li>
                    <li>ginger</li>
                    <li>red onion</li>
                    <li>celery</li>
                </ul>
            </div>
        </div>
        <div id="lunchDishTwo" class="popup-hidden modelbox dish-popup animated-modal">
            <h2 class="anim1 pt-0">Meal Title Two</h2>
            <div class="package-dtl">
                <h3 class="pt-0 anim2">Ingredients</h3>
                <ul class="ingredients-div anim3">
                    <li>garlic</li>
                    <li>onion</li>			
                    <li>tomato</li>
                    <li>bell pepper</li>
                    <li>carrot</li>
                    <li>scallion</li>
                    <li>potato</li>
                    <li>ginger</li>
                    <li>red onion</li>
                    <li>celery</li>
                </ul>
            </div>
        </div>	
    </>
  );
};

export default Menu;
