import { GoogleMapsProvider, useGoogleMap } from '@ubilabs/google-maps-react-hooks'
import React, { useState } from 'react'
import { GrEdit } from 'react-icons/gr'
import { MdLocationPin } from 'react-icons/md';
import GoogleMapReact from 'google-map-react';

import { useNavigate } from 'react-router-dom';

console.log("asdad", process.env.NEXT_PUBLIC_MAP_API_KEY);

const GoogleMap = () => {

    const [mapContainer, setMapContainer] = useState(null)

    const mapOptions = {
        zoom:12,
        center:{
            let: 43.68,
            lng: -79.43
        }
    }

    const navigate = useNavigate()

    const navigateToMap = () => {
      navigate('update-map')
    }

    const handleMapClick = () => {

    }
    const defaultProps = {
      center: {
        lat: 29.381175,
        lng: 47.990644,
      },
      zoom: 12,
    };
  return (
    <div className='google-map-layout'>
      <div className="wrap" style={{height:'10rem'}}>
        
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyBlsIdt9s88g3iboy-H5ASh0qGT8IGDKXU' }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        // options={mapOptions}
        onClick={handleMapClick}
        // onGoogleApiLoaded={({ map }) => (mapRef.current = map)}
      >
          {/* {cordinates && (
          
        )} */}
          <MdLocationPin id="nav-icon" />
        {/* {cordinates && (
          <div
            lat={cordinates.lat}
            lng={cordinates.lng}
            text="My Marker"
            style={{ color: 'red', fontSize: '20px' }}
            onClick={() => handleMarkerClick(cordinates)}
            >
            <MdLocationPin id="nav-icon" />
            <div lat={cordinates.lat} lng={cordinates.lng} className='selected-info'>
            <div>
            </div>
          </div>
          </div>
        )} */}

        {/* InfoWindow */}
      
      </GoogleMapReact>
      
      </div>
      {/* <GoogleMapsProvider 
          googleMapsAPIKey={process.env.NEXT_PUBLIC_MAP_API_KEY}
          mapOptions={mapOptions}
          mapContainer={mapContainer}
          >
              <div ref={node => setMapContainer(node)} style={{height:"20vh"}}></div>
      </GoogleMapsProvider> */}
      <footer className="map-address-footer">
        <div className="add">
          {/* <h4>Ahmadi (East Ahmadi)</h4>
          <span>Block 5, 252 Street, House number 23</span>
          <span>Mobile number : +965 98642918</span> */}
        </div>
        <GrEdit id='edit-icon' onClick={navigateToMap}/>
      </footer>
    </div>
  )
}

export default GoogleMap