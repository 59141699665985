import React, { useEffect, useState } from 'react'
import SelectInput from '../../FormInputs/Select'
import Input from '../../FormInputs/Input'
import { useSelector } from 'react-redux';

const ApartmentAddress = ({ onClick , userAddress, AreaList, setAddress, setCityData, cityData, activeToogle }) => {

  const UserAddres = useSelector(state => state.home.UserAddress);
  const [lang] = useState(localStorage.getItem("langauge_id"))

  // useEffect(() => {
  //   setAddress({})
  // },[])

  // console.log("a8d9a8d", UserAddres);

  useEffect(() => {
    if(UserAddres!==null && UserAddres!==false && UserAddres?.address_type === 'building'){
      setAddress(UserAddres)
      setCityData({value:UserAddres?.area?.area_id,label:UserAddres?.area?.area_name})
    }else{
      setAddress({
        block:'', 
        street:'', 
        avenue:'', 
        flat_no:null, 
        floor_no:null, 
        mobile_number:null, 
        directions:'', 
      })
      setCityData('')
    }
  },[UserAddres])


  const onChangeHandler = (option) => {
    // console.log("option", option);
    setCityData(option)
  }

  return (
    <form action="post">
      <SelectInput  placeholder="Select Area" options={AreaList} handleChange={onChangeHandler} value={cityData}/>
      <Input onChange={e => setAddress({...userAddress, [e.target.name]:e.target.value})} name="block" value={userAddress?.block} placeholder="* Block"/>
      <Input onChange={e => setAddress({...userAddress, [e.target.name]:e.target.value})} name="street" value={userAddress?.street} placeholder="Street"/>
      <Input onChange={e => setAddress({...userAddress, [e.target.name]:e.target.value})} name="avenue" value={userAddress?.avenue} placeholder="Avenue"/>
      <Input type="number" showIcon={false} onChange={e => setAddress({...userAddress, [e.target.name]:e.target.value})} name="flat_no" value={userAddress?.flat_no} placeholder="* Apartment Number"/>
      <Input type="number" showIcon={false} onChange={e => setAddress({...userAddress, [e.target.name]:e.target.value})} name="floor_no" value={userAddress?.floor_no} placeholder="Floor Number"/>
      {/* <Input type="number" showIcon={false} onChange={e => setAddress({...userAddress, [e.target.name]:e.target.value})} name="mobile_number" value={userAddress?.mobile_number} placeholder="* Mobile Number"/> */}
      <Input onChange={e => setAddress({...userAddress, [e.target.name]:e.target.value})} name="directions" value={userAddress?.directions} placeholder="Add a note"/>
      <button className="btn-main" onClick={onClick}>{lang !=1 ? "يكمل" : "Continue"}</button>
  </form>
  )
}

export default ApartmentAddress