import moment from 'moment';
import React, { useState } from 'react'

const OrderDetail = ({ orderDetail, finalePrice, actualPrice, combination }) => {

    const [lang] = useState(localStorage.getItem("langauge_id"))

    const getDishType = (item) => {
        if(item?.mealID === 2){
            return lang=='1'?'Meals':'الوجبات'
        } 
        else if(item?.mealID === 3){
            return lang=='1'?'Snacks':'السناكات'
        } 
        else if(item?.mealID === 11){
            return lang=='1'?'Soups':'الشوربة'
        } 
    }

    console.log('combination',combination);

  return (
    <div className='order-detail-container'>
        <h4 style={{fontSize:'20px', textTransform:'capitalize'}}>Your order details</h4>
        <div className="img-cntr">
            <div>
                <img src="/images/salad.jpg" alt="" />
            </div>
        </div>
        <div className="order-dt-bar">
            <div>
                <span>Subscription type</span>
            </div>
            <div>
                <span>{orderDetail?.plan}</span>
            </div>
        </div>
        <div className="order-dt-bar">
            <div>
                <span>Subscription period</span>
            </div>
            <div>
                <span>{orderDetail?.subscriptionDays}</span>
            </div>
        </div>
        {
            orderDetail?.menuItemsSize?.map((dt, ind) => 
                <div className="order-dt-bar">
                    <div>
                        <span>Number of {getDishType(dt)}</span>
                    </div>
                    <div>
                        <span>{dt?.size} {getDishType(dt)}</span>
                    </div>
                </div>
            )
        }

        <div className="order-dt-bar">
            <div>
                <span>Calories</span>
            </div>
            <div>
                <span>{combination?.[0]?.min_cal} - {combination?.[0]?.max_cal} kCal</span>
            </div>
        </div>


        {/* SAMPLE PACJAGE CARD NEEED TO BE REMOVED AFTER ALL API INTERGATION */}

        {/* <div className="order-dt-bar">
            <div>
                <span>Number of snacks</span>
            </div>
            <div>
                <span>2 snacks</span>
            </div>
        </div>
        <div className="order-dt-bar">
            <div>
                <span>Number of soups</span>
            </div>
            <div>
                <span>2 soups</span>
            </div>
        </div> */}

        {/* {
            combination?.[0]?.sub_package_combo?.map((data, ind) => 
                <div className='order-dt-bar' key={ind}>
                    <div><span>{data?.product_category_name}</span></div>
                    <div><span>{data?.meal_value}</span></div>
                </div>)
        } */}

        <div className="order-dt-bar">
            <div>
                <span>Subscription date</span>
            </div>
            <div>
                <span>{orderDetail?.subscriptionDate?.startDate !== '' && moment(orderDetail?.subscriptionDate?.startDate).format("LL")}</span>
            </div>
        </div>
        <div className="order-dt-bar">
            <div>
                <span>Delivery period</span>
            </div>
            <div>
                <span>{orderDetail?.deliveryTimeFrame}</span>
            </div>
        </div>
        <div className="order-dt-bar">
            <div>
                <span>Communication method</span>
            </div>
            <div>
                <span>{orderDetail?.deliveryCommunication}</span>
            </div>
        </div>
        {/* <div className="order-dt-bar">
            <div>
                <span>Total calories</span>
            </div>
            <div>
                <span>1200 - 1500</span>
            </div>
        </div> */}
        <div className="order-dt-bar">
            <div>
                <span>Subscription price</span>
            </div>
            <div>
                <span>{Number(actualPrice).toFixed(2)} KD</span>
            </div>
        </div>
        <div className="order-dt-bar">
            <div>
                <span><b>Subscription price after discount</b></span>
            </div>
            <div>
                <span><b>{Number(finalePrice).toFixed(2)} KD</b></span>
            </div>
        </div>
    </div>
  )
}

export default OrderDetail