import { React, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { onLogOut, getlngchange } from "../Actions/homeActions";
import { useDispatch } from "react-redux";

const Navbar = () => {
    const [lang] = useState(localStorage.getItem("langauge_id"))
    let location = useLocation()
    const [userData, setUserData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        user_id: '',
    })
    const [logOutBTN, setlogOutBTN] = useState(false)
    const [navActive, setNavActive] = useState(1);
    const [Toggle, setToggle] = useState(false)

    console.log("userData", userData);
    const dispatch = useDispatch()

    const LogOut = () => {
        setlogOutBTN(false)
        dispatch(onLogOut())
    }

    useEffect(() => {
        if (location.pathname.includes('/')) {
            setNavActive(1)
        }
        if (location.pathname.includes('/package') || location.pathname.includes('/PACKAGE')) {
            setNavActive(2)
        }
        if (location.pathname.includes('/calculate-calorie')) {
            setNavActive(3)
        }
        if (location.pathname.includes('/dietician')) {
            setNavActive(4)
        }
        if (location.pathname.includes('/menu')) {
            setNavActive(5)
        }
        if (location.pathname.includes('/sign-in')) {
            setNavActive(6)
        }
        if (location.pathname.includes('/sign-up')) {
            setNavActive(7)
        }
    }, [navActive, location]);


    useEffect(() => {
        if (localStorage.getItem('langauge_id') == undefined) {
            localStorage.setItem('langauge_id', 1)
        }
    }, [])


    useEffect(() => {
        if (localStorage.getItem('first_name') !== undefined
            && localStorage.getItem('last_name') !== undefined
            && localStorage.getItem('email') !== undefined
            && localStorage.getItem('user_id') !== undefined
        ) {
            setUserData({
                first_name: localStorage.getItem('first_name'),
                last_name: localStorage.getItem('last_name'),
                email: localStorage.getItem('email'),
                user_id: localStorage.getItem('user_id'),
            })
        }
    }, [localStorage.getItem('first_name'),
    localStorage.getItem('last_name'),
    localStorage.getItem('email'),
    localStorage.getItem('user_id')])


    const languagehandler = () => {
        dispatch(getlngchange());
    };

    console.log('logOutBTN', logOutBTN);

    return (
        <header>
            <div className="container">
                <div className="row">
                    <div className="col-12 d-flex justify-content-between">
                        <Link to='/' className="muscle-fuel custom_style">
                        <img src="img/muscle-fuel.svg" alt="Muscle Fuel" />
                        <small><b>شركة ماسيل فيول للتجهيزات الغذائية</b></small>
                        </Link>
                        <div className="navigation">
                            <div className={Toggle ? "wsmenucontainer clearfix wsoffcanvasopener" : "wsmenucontainer clearfix"}>
                                <div className="overlapblackbg"></div>
                                <div className="wsmobileheader clearfix">
                                    <a id="wsnavtoggle" className="animated-arrow"><span onClick={() => { setToggle(!Toggle) }}></span></a>
                                </div>
                                <div className="wsmain">
                                    <nav className="wsmenu clearfix">
                                        <ul className="mobile-sub wsmenu-list">
                                            {/* <li><Link to="/" className={navActive==1?"active":null}>{lang=='1'?'Home':'الاشتراكات'}</Link></li> */}
                                            <li className="show-on-mobile">{userData.user_id ? <Link to=''>{userData.first_name} {userData.last_name}</Link> : ''}</li>
                                            <li><Link to="/package" className={navActive == 2 ? "active" : ""}>{lang == '1' ? 'Package' : `الاشتراكات`}</Link></li>
                                            <li><Link to="/calculate-calorie/know-gender" className={navActive == 3 ? "active" : ""}>{lang == '1' ? 'Calculate Calorie' : 'حاسبة السعرات الحرارية'}</Link></li>
                                            {/* <li><Link to="/dietician" className={navActive==4?"active":""}>Dietician</Link></li> */}
                                            <li><Link to="/menu" className={navActive == 5 ? "active" : ""}>{lang == '1' ? 'Menu' : 'المنيو'}</Link></li>
                                            {userData?.user_id&&<li className="show-on-mobile red">
                                                <Link className="button" to='' onClick={LogOut}>{lang == '1' ? "Log Out" : 'تسجيل خروج'}</Link>
                                            </li>}
                                            {!localStorage.getItem('user_id') ? (
                                                <li><Link to="/sign-in" className={navActive == 6 || navActive == 7 ? "active" : ""}>{lang == '1' ? 'Sign in' : 'تسجيل الدخول'}</Link>
                                                    <ul className="wsmenu-submenu">
                                                        <li><Link to="/sign-in" className={navActive == 6 ? "active" : ""}>{lang == '1' ? 'Login' : 'تسجيل الدخول'}</Link></li>
                                                        <li><Link to="/sign-up" className={navActive == 7 ? "active" : ""}>{lang == '1' ? 'Register' : 'تسجيل'}</Link></li>
                                                    </ul>
                                                </li>
                                            ) : null}
                                            <li onClick={languagehandler}><Link to='#'>{lang == '1' ? `العربية‎` : 'English'}</Link></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>

                            {localStorage.getItem('user_id') ? <>
                                <div className="language-div USER_NAME">
                                    <span>
                                        {userData.user_id ? `${userData.first_name} ${userData.last_name}` : 'User Name'}
                                    </span>

                                </div>
                                <span className="subscribe-div USER_PHOTO">
                                    <span class="material-icons-sharp" onClick={() => setlogOutBTN(!logOutBTN)}>account_circle</span>
                                    {logOutBTN ? <p onClick={LogOut} className="LOGOUT">{lang == '1' ? "Log Out" : 'تسجيل خروج'}</p> : null}
                                </span>
                            </> : null}
                            <div className="subscribe-div" >
                                <Link style={{ width: '9rem' }} className="button" to='/package'>
                                    {lang == '1' ? "SUBSCRIBE" : 'اشترك الآن'}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Navbar;
