import { AiOutlineClose } from 'react-icons/ai'

const InfoModal = props => {
  return (
    <>
        {
            props?.show &&
            <div className={`info-modal`} onClick={() => props?.close('')}>
                <div className={`info-modal-container ${props?.show ? 'showModal' : 'hideModal'}`} onClick={e => e.stopPropagation()}>
                    <nav className='modal-nav'>
                        <h4>{props?.heading}</h4>
                        <AiOutlineClose id='close-icon' onClick={() => props?.close('')}/>
                    </nav>
                    <div className="body-container">
                        {props.children}
                    </div>
                </div>
            </div>
        }
    </>
  )
}

export default InfoModal