import React, { useEffect } from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import { FaChevronLeft } from 'react-icons/fa'
import PackageCard from '../PackageCard/PackageCard'
import { Link, useNavigate } from 'react-router-dom'

const Account = () => {

  const navigate = useNavigate()

  const onSignUpClick = () => {
    navigate('/account/signup')
  }

  const onSignInClick = () => {
    navigate('/account/signin')
  }

  useEffect(() => {
    localStorage.setItem('middle_login','yes')
  }, [])


  return (
    <div className="innerpages">
      <div className="container">
        <div className='account-container'>
          <nav>
            <Link to="/package">
              <FaChevronLeft style={{ fontSize: '23px' }} />
            </Link>
            <h4>Account</h4>
          </nav>
          <p style={{ fontSize: '1.1rem' }}>Join us today or access your account</p>
          <div className="flex-row account">
            <PackageCard onClickHandler={onSignUpClick} forAccountPage={true} simpleHeading={true} heading="Sign up and create your account" />
            <PackageCard onClickHandler={onSignInClick} forAccountPage={true} simpleHeading={true} heading="Sign in to your account" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Account