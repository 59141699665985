import React, { useState, useEffect } from 'react';

const CountingLoader = ({ setIsLoading }) => {


    const [progress, setProgress] = useState(0);

    useEffect(() => {
        startLoader()
    }, [])


    const startLoader = () => {
        setIsLoading(true);
        let w = 0;

        const intervalId = setInterval(() => {
            w += 1;
            setProgress(w);
            if (w === 100) {
                clearInterval(intervalId);
                w = 0;
                setIsLoading(false);
            }
        }, 50);
    };


    return (
        <div className='Calori_Loader'>
            <div className='spin_loader'>
                <div class="spinner">
                    <div class="bubble"></div>
                    <div class="bubble"></div>
                    <div class="bubble"></div>
                    <div class="bubble"></div>
                    <div class="bubble"></div>
                    <div class="bubble"></div>
                    <div class="bubble"></div>
                    <div class="bubble"></div>
                </div>
                <div className="middleText">
                    {progress}%
                </div>
            </div>
            <h5>Calculating Your Calories</h5>
            <p>We are now calculating your calories to suggest the suitable package for your goal</p>
        </div>
    )
}

export default CountingLoader